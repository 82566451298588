/* eslint-disable prettier/prettier */
import React from 'react';
import {StyleSheet, View, Text} from 'react-native';
import F2Chart from '../F2Chart';
import {theme} from '../../styles/theme';
import {Clock} from '../icons/clock';

const styles = StyleSheet.create({
  chart: {
    marginTop: 16,
    minHeight: '40%',
  },
  chartTitleContainer: {
    paddingHorizontal: 16,
    marginBottom: -24,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  headerLeft: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerRight: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerRightText: {
    fontFamily: 'TitilliumWeb-Bold',
    fontSize: 14,
    color: '#FFF',
    marginLeft: 4,
  },
  chartTitle: {
    textTransform: 'uppercase',
    fontFamily: 'TitilliumWeb-bold',
    fontSize: 12,
    color: theme.palette.white,
    marginRight: 12,
  },
  chartInfo: {
    fontFamily: 'Spezia-Regular',
    fontSize: 12,
    color: theme.palette.white,
  },
});



export const HeartRateChart = ({
  title = 'Heart rate',
  subText,
  duration,
  chartConfig,
  onError,
}) => {
  const handleMessage = ({ nativeEvent: { data }}) => {
    if (data === 'ERROR:CHART_RENDER') {
      onError();
    }
  };

  return (
    <View style={styles.chart}>
      <View style={styles.chartTitleContainer}>
        <View style={styles.headerLeft}>
          <Text style={styles.chartTitle}>{title}</Text>
          {subText && <Text style={styles.chartInfo}>{subText}</Text>}
        </View>
        <View style={styles.headerRight}>
          {duration && (
            <>
              <Clock />
              <Text style={styles.headerRightText}>{duration}</Text>
            </>
          )}
        </View>
      </View>
        <F2Chart initScript={chartConfig} onMessage={handleMessage}/>
    </View>
  );
};
