import React from 'react';

import brain from './experiments/brain.svg';
import meditation from './experiments/meditation.svg';
import magnesium from './experiments/pills.svg';
import green_tea from './experiments/green-tea.svg';
import core_temperature from './experiments/core-temperature.svg';
import coffee from './experiments/coffee.svg';
import chamomile from './experiments/chamomile.svg';
import mct_oil from './experiments/mct-oil.svg';
import fasting from './experiments/fasting.svg';
import pink_noise from './experiments/pink-noise.svg';
import sunglasses from './experiments/sunglasses.svg';
import experiment_flask from './experiments/experiment_flask.svg';
import no_electronics from './experiments/no-electronics.svg';
import earplugs from './experiments/earplugs.svg';
import cbd from './experiments/cbd.svg';
import pills_bottle from './experiments/pills-bottle.svg';
import mushrooms from './experiments/mushrooms.svg';
import no_alcohol from './experiments/no-alcohol.svg';
import sleeping_mask from './experiments/sleeping_mask.svg';
import weighted_blanket from './experiments/weighted-blanket.svg';
import lavender_oil from './experiments/lavender-oil.svg';
import valerian_root from './experiments/root.svg';
import honey from './experiments/honey.svg';
import bed from './experiments/bed.svg';
import lotus from './experiments/lotus.svg';
import journaling from './experiments/journaling.svg';
// import sun from './experiments/sun.svg';

// 'Why run experiments' infographic
import clock from './insights/clock.svg';
import running from './insights/running.svg';
import training from './insights/training.svg';
import star from './insights/star.svg';
import moon from './insights/moon.svg';
import sky from './insights/sky.svg';

// import target from './experiments/target_circular.svg';
// import magicWand from './experiments/magic-wand.svg';
// import goalMountain from './experiments/goal_mountaintop.svg';

const habitIcons = {
  meditation,
  'magnesium-bed': magnesium,
  'im-fasting': fasting,
  'blue-blocking-glasses': sunglasses,
  'no-electronics-bed': no_electronics,
  earplugs,
  melatonin: pills_bottle,
  'reishi-mushroom': mushrooms,
  coffee,
  'lemon-balm': cbd,
  'cbd-bed': cbd,
  'mct-oil': mct_oil,
  'green-tea': green_tea,
  'chamomile-tea': chamomile,
  'core-temperature': core_temperature,
  'pink-noise': pink_noise,
  'no-alcohol': no_alcohol,
  neurofeedback: brain,
  'sleeping-mask': sleeping_mask,
  'weighted-blanket': weighted_blanket,
  'lavender-oil': lavender_oil,
  'valerian-root': valerian_root,
  'custom-supplement': experiment_flask,
  // 'light-exposure': sun,
  'custom-experiment': experiment_flask,
  experiment: experiment_flask,
  // target,
  moon,
  star,
  breathwork: sky,
  sky,
  // goal: goalMountain,
  // magic: magicWand,
  'sleeping-position': bed,
  yoga: lotus,
  exercise: running,
  journaling,
  running,
  training,
  clock,
  'consistent-sleep-schedule': clock,
  'raw-honey-bed': honey,
  // 'whyExperiment-research': target,
  // 'whyExperiment-habits': goalMountain,
  // 'whyExperiment-discover': magicWand,
};

export default habitIcons;
