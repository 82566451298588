export function getStandardDeviation(array) {
  const n = array.length;
  const mean = array.reduce((a, b) => a + b) / n;
  return Math.sqrt(
    array.map(x => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n,
  );
}

export function simpleMovingAverage(prices, window = 5) {
  if (!prices || prices.length < window) {
    return [];
  }

  let index = window - 1;
  const length = prices.length + 1;

  const simpleMovingAverages = [];

  while (++index < length) {
    const windowSlice = prices.slice(index - window, index);
    const sum = windowSlice.reduce((prev, curr) => prev + curr, 0);
    simpleMovingAverages.push(Math.floor(sum / window));
  }

  return simpleMovingAverages;
}

export function exponentialMovingAverage(prices, window = 5) {
  if (!prices || prices.length < window) {
    return [];
  }

  let index = window - 1;
  let previousEmaIndex = 0;
  const length = prices.length;
  const smoothingFactor = 2 / (window + 1);

  const exponentialMovingAverages = [];

  const [sma] = simpleMovingAverage(prices, window, 1);
  exponentialMovingAverages.push(sma);

  while (++index < length) {
    const value = prices[index];
    const previousEma = exponentialMovingAverages[previousEmaIndex++];
    const currentEma = (value - previousEma) * smoothingFactor + previousEma;
    exponentialMovingAverages.push(Math.floor(currentEma));
  }

  return exponentialMovingAverages;
}
