import React, { useEffect } from 'react';
import {Text, StyleSheet, TouchableOpacity, View, ActivityIndicator } from 'react-native';
import tw from 'twrnc';
import ReportTemplatesView from './src/components/ReportTemplatesView'
import { useFonts } from 'expo-font';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import Colors from './src/constants/Colors';
import Container from './src/components/ReviewContainer'
import { useAuth0 } from "@auth0/auth0-react";
import { CommonActions } from '@react-navigation/native';

import AuthProvider, { LoginButton } from './src/components/AuthPortal'
const Stack = createStackNavigator();

const linking = {
  prefixes: [
    /* your linking prefixes */
    'https://yearinreview.bioloopsleep.com',
    'https://yearinreview.crescent.health',
    'https://yearinreview.crescent.health/',
  ],
  config: {
    screens: {
      'View Report': '',
      Home: 'login',
      View: 'view',
      Report: 'report/:id',
    }
    /* configuration for matching screens with paths */
  },
};

function HomePage({ navigation }) {
  const { isLoading, error, user, isAuthenticated } = useAuth0();
    console.log("home user", user, error, isAuthenticated)
  useEffect(() => {
    if (user) {
      let userId = user?.sub?.split('|')[1]
      navigation.dispatch(
        CommonActions.navigate({
          name: 'Report',
          params: {
            id: userId,
          },
        })
      );
    }
  }, [user])
  return <View style={[tw.style('bg-black w-full h-full'), { flex: 1, paddingHorizontal: 20, paddingTop: 0, alignItems: 'center'}]}>
    <Container>
      <View style={{ marginTop: 32 }}>
        <LoginButton/>
      </View>
    </Container>
  </View>
}

function App() {``
  let [fontsLoaded] = useFonts({
    'Teodor-Light': require('./assets/fonts/Teodor-Light.otf'),
    'Spezia-Regular': require('./assets/fonts/Spezia-Regular.otf'),
    'Inter-SemiBold': require('./assets/fonts/Inter-SemiBold.ttf'),
    'SpeziaExtended-SemiBold': require('./assets/fonts/SpeziaExtended-SemiBold.otf'),
    'SpeziaExtended-Medium': require('./assets/fonts/SpeziaExtended-Medium.otf'),
    'SpeziaNarrow-SemiBold': require('./assets/fonts/SpeziaNarrow-SemiBold.otf'),
  });

  return (
    <AuthProvider>
      <NavigationContainer linking={linking} fallback={<Text>Loading...</Text>}>
        {/* content */}
        <Stack.Navigator
          screenOptions={{
            headerShown: false
          }}
        >
          <Stack.Screen name={'Home'} component={HomePage} />
          <Stack.Screen name={'View Report'} component={ReportTemplatesView} />
          <Stack.Screen name={'Report'} component={ReportTemplatesView} />
          <Stack.Screen name={'View'} component={ReportTemplatesView} />
        </Stack.Navigator>
      </NavigationContainer>
    </AuthProvider>
  );
}


export default App;
