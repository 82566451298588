import axios from 'axios'

let config =  {
	cId: '8puWuJWZYls1Ylawxm6CMiYREhsGGSyw',
	url: 'https://mementolabs.fly.dev',
	devUrl: 'https://e7e1-64-71-21-6.ngrok.io'
};


const attributeDataEndPoints = {
	getCustomAttributes:  `${config.url}/api/attributes/custom'`,
	getRecommendationsForAttribute:  `${config.url}/api/experiments/getRecommendationsForAttributes`,
	getCorrelationsForAttribute:  `${config.url}/api/recipes/getCorrelationsForAttributes'`,
	getDataForAttributes: `${config.url}/api/recipes/getDataForAttributeKeys`,
	adminGetDataForAttributes: `${config.url}/api/recipes/admin_getDataForAttributeKeys`,
	adminGetRoutineStats: `${config.url}/api/recipes/admin_getRoutineStats`,
	adminGetProgressStats: `${config.url}/api/recipes/admin_getProgressStats`,
	adminGetProfileStats: `${config.url}/api/recipes/admin_getProfileStats`,
	adminGetSleepSchedule: `${config.url}/api/admin_sleepSchedule`,
}

export const adminGetSleepSummary = (dateString, userId) => {
	var config = {
		headers: { 'Authorization': 'Bearer o0ch153B0bLtd3q6w!rhPkw24fTf0uzTdRK%A7mlHMgzWxRj*Hb*cKp5a4cP' }
	};
  return axios.get(
    `${attributeDataEndPoints.adminGetSleepSchedule}?dateString=${dateString}&userId=${userId}`, config);
};

export const getCustomAttributes = () => {
	return axios.get(attributeDataEndPoints.getCustomAttributes)
};
export const getRecommendationsForAttributes = (accessToken, useAllExperiments=true) => {
	var config = {
		headers: { 'Authorization': "Bearer " + accessToken }
	};
	return axios.get(`${attributeDataEndPoints.getRecommendationsForAttribute}?useAllExperiments=${useAllExperiments}`, config)
};
export const getCorrelationsForAttributes = () => {
	return axios(attributeDataEndPoints.getCorrelationsForAttribute, { })
};
export const getDataForAttributes = (accessToken, attributeKeys, startDate, endDate) => {
	var config = {
		headers: { 'Authorization': "Bearer " + accessToken }
	};
	return axios.get(`${attributeDataEndPoints.getDataForAttributes}?attributeKeys=${JSON.stringify(attributeKeys)}&startDate=${startDate}&endDate=${endDate}`, config)
};
export const adminGetDataForAttributes = (userId, attributeKeys, startDate, endDate) => {
	var config = {
		headers: { 'Authorization': 'Bearer o0ch153B0bLtd3q6w!rhPkw24fTf0uzTdRK%A7mlHMgzWxRj*Hb*cKp5a4cP' }
	};
	return axios.get(`${attributeDataEndPoints.adminGetDataForAttributes}?userId=${userId}&attributeKeys=${JSON.stringify(attributeKeys)}&startDate=${startDate}&endDate=${endDate}`, config)
};
export const adminGetRoutineStats = (userId, startDate, endDate) => {
	var config = {
		headers: { 'Authorization': 'Bearer o0ch153B0bLtd3q6w!rhPkw24fTf0uzTdRK%A7mlHMgzWxRj*Hb*cKp5a4cP' }
		// headers: { 'Authorization': `Bearer ${process.env.ADMIN_API_TOKEN}` }
	};
	return axios.get(`${attributeDataEndPoints.adminGetRoutineStats}?userId=${userId}&startDate=${startDate}&endDate=${endDate}`, config)
};

export const adminGetProfileStats = (userId, startDate, endDate) => {
	var config = {
		headers: { 'Authorization': 'Bearer o0ch153B0bLtd3q6w!rhPkw24fTf0uzTdRK%A7mlHMgzWxRj*Hb*cKp5a4cP' }
		// headers: { 'Authorization': `Bearer ${process.env.ADMIN_API_TOKEN}` }
	};
	return axios.get(`${attributeDataEndPoints.adminGetProfileStats}?userId=${userId}&startDate=${startDate}&endDate=${endDate}`, config)
};
export const adminGetProgressStats = (userId, startDate, endDate) => {
	var config = {
		headers: { 'Authorization': 'Bearer o0ch153B0bLtd3q6w!rhPkw24fTf0uzTdRK%A7mlHMgzWxRj*Hb*cKp5a4cP' }
		// headers: { 'Authorization': `Bearer ${process.env.ADMIN_API_TOKEN}` }
	};
	return axios.get(`${attributeDataEndPoints.adminGetProgressStats}?userId=${userId}&startDate=${startDate}&endDate=${endDate}`, config)
};

export const setReminder = (reminder, experiment) => {
	// var config = {
	// 	headers: { 'Authorization': "Bearer " + accessToken }
	// };
	// return fetchApi(`${attributeDataEndPoints.setReminder}?experimentId=${experimentId}`, reminder, "POST")
};
