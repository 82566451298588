import React, { useEffect } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import Colors from '../../constants/Colors';
import F2Chart from '../F2Chart'
import Layout, { isWeb } from '../../constants/Layout'
import { hexToRgbA } from '../../utils/ColorsUtils'
import moment from 'moment';

const styles = StyleSheet.create({
	legends: {
		marginTop: 0,
		marginLeft: 0,
		width: '100%',
		textAlign: 'center',
		flexDirection: 'row',
	},
	legend: {
		marginRight: 2,
		flexDirection: 'row',
		textAlign: 'center'
	},
	dot: {
		fontSize: 12,
		borderColor: '#fff',
        fontFamily: 'Spezia-Regular',
		justifyContent: 'flex-start',
		alignItems: 'center'
	},
	label: {
		marginRight: 8,
		marginLeft: 4,
		fontFamily: 'Spezia-Regular',
		color: Colors.darkBlackColor,
		fontSize: 12,
		justifyContent: 'flex-end',
	}
})

const exampleData = Array(365).fill().map((_, index) => {
    return {
        dateString: moment("2022-01-01", "YYYY-MM-DD").startOf('year').add(index, 'day').format("YYYY-MM-DD"),
        value: Math.random() * 100 + 60,
    }
})

const initScript = (data) => `
(function(){
    chart = new F2.Chart({
        id: 'chart',
        pixelRatio: window.devicePixelRatio,
        padding: [12, 12, 'auto'],
    });
    chart.source(${JSON.stringify(data)}, {
        month: {
            type: 'cat',
            values: [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        },
        // date: {
        //     type: 'timeCat',
        //     mask: 'ddd',
        //     range: [0, 1],
        // },
        date: {
            type: 'cat',
            values: Array(31).fill().map((_, i) => i),
        },
        day: {
            type: 'cat',
            values: [ 'Mon.', 'Tue.', 'Wed.', 'Thu.', 'Fri.', 'Sat.', 'Sun.']
        }
        // day: {
        //     type: 'timeCat',
        //     mask: 'ddd',
        //     range: [0, 1],
        //     tickCount: 7,
        // }
    });
    chart.legend('value', {
        position: 'bottom',
        align: 'left',
        itemFormatter: function itemFormatter(val) {
            if (!val) {
                return 'No data'
            }
            if (val > 85) {
                return '>85% efficiency'
            }
            return '<85% efficiency'
        },
        nameStyle: {
            fill: '${Colors.darkLightColor}',
            fontFamily: 'Teodor-Light',
            fontSize: '12',
        }, 
    })
    chart.axis('date', false)
    chart.polygon()
    .position('month*date')
    .color('value*highlight', (value, highlight) => {
        if (!value) {
            return '#e1e1fb'
        }
        if (value > 85) {
            return '#66cc91'
        }
        if (value < 85) {
            return '#FEE1C0'
        }
        if (highlight == -1) {
            return '${Colors.orangeColor}'
        }
        if (highlight == 1) {
            return '${Colors.shadowColor}'
        }
        return ['#e1e1fb', '#FEE1C0', '#79d9d0']
        // return '#ffafa2-#FEE1C0-#79d9d0'
    })
    .style({
        lineWidth: 2,
        stroke: '#fff'
    })
    .animate({
        appear: {
            duration: 800
        }
    });
    chart.render();
})();
`;

export default function HeatmapChart(props) {
    const { data, minDate, maxDate } = props
    const onMessage = event => {
		const {
			nativeEvent: { data }
		} = event;
        if (data === 'ERROR:CHART_RENDER') {
            console.log("ERROR RENDERING CHART")
        }
	};

    let emptyData = exampleData.map((d) => {
        return {
            ...d,
            month: moment(d.dateString).month(),
            day: moment(d.dateString).day(),
            date: moment(d.dateString).date(),
            value: null
        }
    })
    function unique(array, propertyName) {
        return array.filter((e, i) => array.findIndex(a => a[propertyName] === e[propertyName]) === i);
    }
    console.log("longestDurationDateString", maxDate)
    console.log("shortestDurationDateString", minDate)
    let source = data.map((d) => {
        if (moment.utc(d.date).format("YYYY-MM-DD") == minDate) {
            console.log("found min date:", d)
        }
        if (moment.utc(d.date).format("YYYY-MM-DD") == maxDate) {
            console.log("found max date:", d)
        }
        return {
            ...d,
            dateString: moment.utc(d.date).format("YYYY-MM-DD"),
            month: moment.utc(d.date).month(),
            day: moment.utc(d.date).day(),
            date: moment.utc(d.date).date(),
            highlight: moment.utc(d.date).format("YYYY-MM-DD") == minDate ? -1: moment.utc(d.date).format("YYYY-MM-DD") == maxDate ? 1: 0
        }
    })

    let filledData = unique([...source, ...emptyData], 'dateString')
    let legends = [
        {
            legend: 'No data',
            color: "#e1e1fb"

        },
        {
            legend: '<85% efficiency',
            color: "#FEE1C0"
        },
        {
            legend: '>85% efficiency',
            color: "#66cc91"
            
        },
    ]

    return (
        <View style={{
            width: isWeb ? Layout.window.width/2: Layout.window.width - 48,
            justifyContent: "center",
            alignSelf: "center",
            alignItems: "center",
            height: 320,
        }}>
            <View style={styles.legends}>
                {legends.map((item, i) => {
                    return (<View style={styles.legend} key={i}>
                        <Text style={[styles.dot, { 'color': item.color }]}>●</Text>
                        <Text style={[styles.label, { color: Colors.lightColor, fontSize: 12 }]}>{item.legend}</Text>
                    </View>)
                })}
            </View>
            <F2Chart 
                initScript={initScript(filledData)} 
                onMessage={onMessage}
            />
        </View>
        
    )
}