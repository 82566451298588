const palette = {
  background: '#000',
  white: '#fff',
  lightColor: '#EBF3FF',
  lightBlueColor: '#C0D6FE',
  primary: {},
  secondary: {},
  tertiary: {
    main: '#79d8cf',
  },
  heart: {
    main: '#ff9a9a',
    accent: '#ff4d4d',
  },
};

export const theme = {
  palette,
  typography: {
    fontFamily: {
      bold: 'TitilliumWeb-Bold',
      regular: 'Spezia-Regular',
      light: 'TitilliumWeb-Light',
      thin: 'TitilliumWeb-Thin',
    },
    h1: {
      fontSize: 32,
      lineHeight: 40,
      fontFamily: 'TitilliumWeb-Bold',
    },
    h2: {
      fontSize: 26,
      lineHeight: 33,
      fontFamily: 'TitilliumWeb-Bold',
    },
    h3: {
      fontSize: 22,
      lineHeight: 31,
      fontFamily: 'TitilliumWeb-Bold',
    },
    h4: {
      fontSize: 19,
      lineHeight: 26,
      fontFamily: 'Spezia-Regular',
    },
    h5: {
      fontSize: 16,
      fontFamily: 'TitilliumWeb-Bold',
    },
    h6: {
      fontSize: 14,
      lineHeight: 20,
      fontFamily: 'Spezia-Regular',
    },
    h7: {
      fontSize: 14,
      lineHeight: 20,
      fontFamily: 'Spezia-Regular',
    },
    smallTitle: {
      fontSize: 16,
    },
    b1: {
      fontSize: 14,
      lineHeight: 22,
      fontFamily: 'Spezia-Regular',
    },
    b2: {
      fontSize: 12,
      lineHeight: 18,
      fontFamily: 'Spezia-Regular',
    },
    date: {
      fontSize: 12,
      lineHeight: 14,
      color: palette.white,
      fontFamily: 'TitilliumWeb-Bold',
    },
  },
};
