import React from 'react';
import {View, StyleSheet} from 'react-native';

import AntIcon from 'react-native-vector-icons/AntDesign';
import FAIcon from 'react-native-vector-icons/FontAwesome';

const styles = StyleSheet.create({
  maxHeartRateIcon: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  heartBeatArrow: {
    position: 'absolute',
    top: 7,
    right: 3,
  },
});

export const Heartbeat = () => (
  <View style={styles.maxHeartRateIcon}>
    <FAIcon name="heart" color="#ff9a9a" size={32} />
    <AntIcon
      name="arrowup"
      style={styles.heartBeatArrow}
      color="#ff4d4d"
      size={14}
    />
  </View>
);
