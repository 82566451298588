import React from 'react';

import F2Chart from '../F2Chart';
import { isGoodTrend } from '../../constants/Metrics';
import Colors from '../../constants/Colors';
import { View, Image, Text, StyleSheet } from 'react-native';
import { hexToRgbA } from '../../utils/ColorsUtils';

import Layout, { isWeb } from '../../constants/Layout';
import { todayISODateString, formatToDateString } from '../../utils/datetime';
import {
  sum,
  average,
  make_window,
  moving_average,
  averageFromData,
  maxFromData,
  computeTrendFromTimeseriesData,
} from '../../utils/GraphStatsUtils';
import moment from 'moment'

const styles = StyleSheet.create({
  metricHeader: {
    fontFamily: 'TitilliumWeb-Regular',
  },
	textHeader: {
		fontFamily: 'SpeziaNarrow-SemiBold',
	}
});
function getWindowDimensions() {
  const { width, height } = Layout.window;
  return {
    width,
    height,
  };
}

// TODO: calculate these dynamically based on other demographic data
const targetRangeForMetric = metricKey => {
	console.log("metricKey", metricKey)
  switch (metricKey) {
    case 'duration':
      return { min: 6, average: 6.5, max: 7 };
    case 'efficiency':
      return { min: 85, average: 95, max: 100 };
    case 'onset_latency':
      return { min: 0, average: 7.5, max: 15 };
    case 'awake':
      return { min: 0.5, average: 0.67, max: 0.75 };
    case 'rem':
      return { min: 1, average: 2, max: 3 };
    case 'deep':
      return { min: 1, average: 2, max: 3 };
    case 'restless':
      return { min: 20, average: 30, max: 40 };
    default:
      return { min: 'min', max: 'max' };
      break;
  }
};

const predictedBaseline = 95;

const initScript = (
  data,
  movingMeans,
  graphAverage,
  metricKey,
	startDate,
  endDate,
  color,
) => `
(function(){
   var lineChart = new F2.Chart({
			id: 'chart',
			pixelRatio: window.devicePixelRatio,
			padding: [${isWeb ? 48: 12}, 'auto', 'auto', ${isWeb ? 48: 12}],
		});
		
		lineChart.source(${JSON.stringify(data)}, {
			date: {
				type: 'timeCat',
				mask: 'MMM D',
				range: [0, 1],
				tickCount: 8
			},
			value: {
                tickCount: 5,
                max: ${JSON.stringify(graphAverage > 50 ? 100 : null)}
			}
		});
		// lineChart.guide().rect({
		// 	start: [${JSON.stringify(endDate)}, ${JSON.stringify(
  targetRangeForMetric(metricKey).min,
)}],
		// 	end: ['max', ${JSON.stringify(targetRangeForMetric(metricKey).max)}],
		// 	style: {
		// 		fill: ${JSON.stringify(Colors.whiteColor)},
		// 		fillOpacity: 0.2
		// 	}
		// });
		lineChart.guide().text({
			position: [${JSON.stringify(endDate)}, 'max'],
			content: 'Target range',
			style: {
				fill: ${JSON.stringify(Colors.whiteColor)},
				fontFamily: 'SpeziaExtended-Medium',
				textAlign: 'center',
				textBaseline: 'top',
				fontSize: 10
			},
			offsetX: 48,
			offsetY: 32
		});
		lineChart.axis('value', false)
		lineChart.axis('date', {
			tickLine: {
				length: 4,
				lineWidth: 1,
				stroke: '#fff'
			},
			label: function label(text, index) {
				if (index % 2 === 0) {
					return {
						fill: '#fff',
            textAlign: 'left',
						fontFamily: 'SpeziaExtended-Medium',
						fontWeight: 'bold'
					};
				}
				return null;
			}
		});

		lineChart.interval()
			.position('date*value').size('type', function (val) {
				if (val == 'Prediction' || val == 'Moving') {
					return 0;
				}
				return null;
			})
			.color('l(90) 0:${hexToRgbA('#545db3', 0.4)} 1:${hexToRgbA(
  Colors.shadowColor,
  0.4,
)}');

		const movingAverageColor = '${Colors.lavenderColor}'
		lineChart.line().position('date*value').color('type', [movingAverageColor]).size('type', function (val) {
			if (val == 'Moving') {
				return 1;
			}
			return 0;
		}).shape('type', function (type) {
			if (type == 'Prediction') {
				return 'dash'
			}
			return 'smooth'
		}).animate({
			appear: {
				duration: 1200
			}
		});
		lineChart.legend('type', {
			custom: true,
			items: [
				{
					name: 'MOVING AVERAGE  ',
					marker: function marker(x, y, r, ctx) {
						ctx.lineWidth = 1;
						ctx.strokeStyle = ctx.fillStyle;
						ctx.moveTo(x - r - 3, y);
						ctx.lineTo(x + r + 3, y);
						ctx.stroke();
					},
          titleStyle: {
            fontSize: 8, 
          },
          nameStyle: {
            fontSize: 8, 
          },
          valueStyle: {
            fontSize: 8, 
          },
					fill: movingAverageColor,
					checked: true
				},
				{
					name: '30-DAY BASELINE',
					marker: function marker(x, y, r, ctx) {
						ctx.lineWidth = 1;
						ctx.strokeStyle = ctx.fillStyle;
						ctx.moveTo(x - r - 2, y);
						ctx.lineTo(x - r, y);
						ctx.moveTo(x - r + 2, y);
						ctx.lineTo(x - r + 4, y);
						ctx.moveTo(x - r + 6, y);
						ctx.lineTo(x - r + 8, y);
						ctx.stroke();
					},
          titleStyle: {
            fontSize: 8, 
          },
          nameStyle: {
            fontSize: 8, 
          },
          valueStyle: {
            fontSize: 8, 
          },

					fill: ${JSON.stringify(Colors.lighterGrayColor)},
					checked: true
				},
				// {
				// 	name: 'Predictions',
				// 	marker: function marker(x, y, r, ctx) {
				// 		ctx.arc(x, y, 6, 0, Math.PI * 2, false);
				// 		ctx.fill();
				// 	},
				// 	fill: ${JSON.stringify(Colors.brightTintColor)},
				// 	checked: true
				// },
			],
			nameStyle: {
				fill: ${JSON.stringify(Colors.darkLightColor)},
				fontFamily: "Avenir-Book"
			},
			position: 'top',
			verticalAlign: 'top',
			itemWidth: 0.3 * (${JSON.stringify(getWindowDimensions().width)}),
			valueStyle: {
				fill: '#fff'
			}
		});
		lineChart.guide().line({
			start: [${JSON.stringify(
        moment(startDate).format('YYYY-MM-DD'),
      )}, ${JSON.stringify(graphAverage)}],
			end: [${JSON.stringify(endDate)}, ${JSON.stringify(graphAverage)}],
			style: {
				lineWidth: 2,
				stroke: ${JSON.stringify(Colors.lighterGrayColor)},
				lineDash: [8, 8]
			}
		});
		lineChart.guide().text({
			position: [${JSON.stringify(
        moment(startDate).format('YYYY-MM-DD'),
      )}, ${JSON.stringify(graphAverage)}],
			content: ${JSON.stringify(graphAverage.toFixed(1))},
			offsetY: -8,
			offsetX: -8,
			style: {
				fill: ${JSON.stringify(Colors.darkLightColor)},
				fontFamily: 'Avenir-Book'
			}
		});
		// lineChart.guide().line({
		// 	start: [${JSON.stringify(endDate)}, ${JSON.stringify(predictedBaseline)}],
		// 	end: [${JSON.stringify(
      moment(endDate).add(5, 'days').format('YYYY-MM-DD'),
    )}, ${JSON.stringify(predictedBaseline)}],
		// 	style: {
		// 		lineWidth: 2,
		// 		stroke: ${JSON.stringify(Colors.lightBlueColor)},
		// 		lineDash: [4, 8]
		// 	}
		// });
		// lineChart.guide().text({
		// 	position: [${JSON.stringify(endDate)}, ${JSON.stringify(predictedBaseline)}],
		// 	content: 'Your predicted 7-day baseline: ${JSON.stringify(
      predictedBaseline,
    )}',
		// 	style: {
		// 		fill: ${JSON.stringify(Colors.lightBlueColor)},
		// 		textAlign: 'center',
		// 		fontSize: ${8},
		// 		fontFamily: 'Avenir-Book',
		// 		fontWeight: 'bold',
		// 	},
		// 	offsetY: 8,
		// 	offsetX: 8
		// });
		let graphPredictions = [${JSON.stringify({
      date: moment(endDate).add(3, 'days').format('YYYY-MM-DD'),
      value: targetRangeForMetric(metricKey).average,
      type: 'Prediction',
      title: 'Predictions',
    })}];
		// if () {
		// 	graphPredictions = 
		// } else {
		// 	graphPredictions = 
		// }
		graphPredictions.forEach(function (obj) {
			lineChart.guide().line({
				start: [${JSON.stringify(endDate)}, ${JSON.stringify(movingMeans.slice().pop().value,)}],
				end: [obj.date, obj.value],
				style: {
					lineWidth: 2,
					stroke: ${JSON.stringify(Colors.brightTintColor)},
					lineDash: [2, 8],
				},
			});
			// lineChart.guide().text({
			// 	position: [obj.date, obj.value],
			// 	content: obj.value,
			// 	style: {
			// 		fill: ${JSON.stringify(Colors.lightColor)},
			// 		textAlign: 'center',
			// 		fontSize: 12,
			// 		fontFamily: 'Avenir-Book',
			// 		margin: 4
			// 	},
			// 	offsetY: -8,
			// 	offsetX: 4
			// });
		});
		lineChart.point().position('date*value').style('type', {
			stroke: ${JSON.stringify(Colors.whiteColor)},
			lineWidth: 4,
			fill: ${JSON.stringify(Colors.whiteColor)},
			r: function r(type) {
				if (type == 'Prediction') {
					return 4;
				}
				return 0;
			}
		})
		lineChart.point().position('date*value').style('type', {
			stroke: ${JSON.stringify(hexToRgbA(Colors.whiteColor, 0.2))},
			lineWidth: 4,
			fill: ${JSON.stringify(hexToRgbA(Colors.whiteColor, 0.2))},
			r: function r(type) {
				if (type == 'Prediction') {
					return 28;
				}
				return 0;
			}
		})
		// const recommendations = JSON.stringify(recommendedExperiments)
		lineChart.tooltip({
			showItemMarker: false,
			showTitle: true,
			titleStyle: {
				marginTop: 32,
				margin: [32, 0, 0]
			},
			background: {
				radius: 8,
				fill: ${JSON.stringify(hexToRgbA(Colors.darkPurpleColor, 1))},
				padding: [8, 8, 8]
			},
			onShow: function onShow(ev) {
				const items = ev.items;
				if (items[0].name == "Prediction") {
					// items[0].title = recommendations[graphPredictions.findIndex((item) => item.value == items[0].value)].name
				}
				items[0].name = items[0].name;
				items[1].name = items[0].name == "Prediction" ? "vs Predicted baseline" : "Moving average";
				const value = items[0].value;
				items[0].value = value;
				items[1].value = items[0].name == "Prediction" ? predictedBaseline : items[1].value;
				while (items.length > 2) {
					items.pop()
				}
			}

		});
		lineChart.render();
})();
`;

export default function RollingTrendsChart(props) {
	const { metric, dateString } = props;
  const {
    label,
    average,
		baselineAverage,
    lastValue,
    pastData,
    units,
    percentChange,
    value,
    service,
    data,
    id,
  } = metric;
	let formattedData = data.map((item) => ({
		date: item.x,
		value: item.y
	}))
	const windowSize = 3;
	let movingMeans = moving_average(
		windowSize,
		windowSize - 1,
		formattedData.map(d => d.value),
	)

	console.log("movingMeans", movingMeans)
	movingMeans = movingMeans.map((avg, index) => ({
		date: moment(formattedData[index].date).format('YYYY-MM-DD'),
		value: Math.round(avg * 10) / 10,
		type: 'Moving',
	}))
  const graphColor = props.graphColor || Colors.violetColor;
  const barGraphHeight = 60;
  const trend = value ? isGoodTrend(value, percentChange) : isGoodTrend(id);

  const noData = data.length == 0;
	
	const directionArrow =
    percentChange > 0 ? '↑' : percentChange == 0 ? '-' : '↓';
  const trendColor =
    percentChange == 0
      ? '#aaa'
      : trend
      ? Colors.brightTintColor
      : Colors.redColor;
			// const endDate = moment(data[data.length - 1].date).format(
			// 	'YYYY-MM-DD',
			// );
	const graphAverage = average;
	// use baseline average here as well;
	
	// const percentChange =
	// 	movingMeans[movingMeans.length - 1].value -
	// 	movingMeans[0].value;
	const endDate = noData ? dateString: moment(formattedData.slice().pop()?.date).format('YYYY-MM-DD');
	const sourceData = [
		...formattedData.map(x => ({
			...x,
			date: moment(x.date).format('YYYY-MM-DD'),
			type: metric ? metric.label : '',
		})),
		{
			date: endDate,
			value: movingMeans[movingMeans.length - 1].value,
		},
		...movingMeans,
		 ...Array.from({ length: targetRangeForMetric(value).average ? 5: 0 }, (x, i) => i).map(i => ({
			date: moment(endDate)
				.add(i + 1, 'days')
				.format('YYYY-MM-DD'),
			type: 'Prediction',
			value: null,
		})),
		targetRangeForMetric(value).average ? {
			date: moment(endDate)
				.add(3, 'days')
				.format('YYYY-MM-DD'),
			value: targetRangeForMetric(value).average,
			type: 'Prediction',
			title: 'title',
		}: null,
	].filter(x => x);


	const startDate = sourceData.slice().shift()?.date;

	return <View
      style={{
        
        // width: isWeb ? : Layout.window.width - 16,
      }}>
			<View style={{ borderRadius: 8 }}>
			{!formattedData || formattedData.length == 0 ? (
					<View style={{
						padding: 8
					}}>
						<Text
							style={[
								styles.textHeader,
								{
									color: Colors.whiteColor,
									margin: 0,
									fontSize: 10,
								},
							]}>
							{metric ? metric.label?.toUpperCase() : ''}
						</Text>
						<Text
							style={[
								styles.textHeader,
								{
									fontFamily: 'Teodor-Light',
									color: Colors.lavenderColor,
									fontSize: 32,
								},
							]}>
							{'No data available.'}
						</Text>
					</View>
				): (
						<View
							style={{
								position: 'relative',
								padding: 8
							}}>
							<Text
								style={[
									styles.textHeader,
									{
										color: Colors.whiteColor,
										margin: 0,
										fontSize: 10,
									},
								]}>
								{metric ? metric.label?.toUpperCase() : ''}
							</Text>
							<Text
								style={[
									styles.textHeader,
									{
										fontFamily: 'Teodor-Light',
										color: Colors.lavenderColor,
										fontSize: 32,
										marginBottom: 16,
									},
								]}>
								{`${graphAverage.toFixed(1)}${units || ''}`}
								<Text
									style={[
										{
											marginLeft: 4,
											fontFamily: 'Teodor-Light',
											justifyContent: 'flex-start',
											alignSelf: 'flex-start',
											alignItems: 'flex-start',
											color: trendColor,
											fontSize: 16,
											textAlign: 'right',
										},
									]}>
									{average != null &&
										percentChange != null &&
										`${directionArrow}${(percentChange)?.toFixed(1)}% `}
								</Text>
								{/* <Text
									style={[
										{
											fontFamily: 'Teodor-Light',
											justifyContent: 'flex-start',
											alignSelf: 'flex-start',
											alignItems: 'flex-start',
											color: Colors.lighterGrayColor,
											fontSize: 16,
											textAlign: 'right',
										},
									]}>
									{average != null &&
										percentChange != null &&
										`vs baseline`}
								</Text> */}
							</Text>
							<View style={{
								border: 'solid',
								borderColor: Colors.darkLightBlackColor,
								borderWidth: 1,
								borderRadius: 8,
								padding: 8,
								height: getWindowDimensions().height / 3,
							}}>
								<F2Chart
									initScript={initScript(
										sourceData,
										movingMeans,
										graphAverage,
										value,
										startDate,
										endDate,
									)}
									onChange={tooltip => {
										// console.log(tooltip);
									}}
								/>
							</View>
						</View>
					)};
			</View>
	</View>
}