import React, {PureComponent, createRef} from 'react';
import {StyleSheet, Platform} from 'react-native';
import {WebView as RNWebView} from 'react-native-webview';

const source = Platform.select({
  ios: require('./f2chart.html'),
  android: {uri: 'file:///android_asset/f2chart.html'},
});

const styles = StyleSheet.create({
  webView: {
    flex: 1,
    backgroundColor: 'transparent',
  },
});

const changeData = data => `chart.changeData(${JSON.stringify(data)});`;

class F2Chart extends PureComponent {
  static defaultProps = {
    onChange: () => {},
    initScript: '',
    data: [],
    webView: RNWebView,
  };

  constructor(props) {
    super(props);
    this.chart = createRef();
    this.update = this.update.bind(this);
  }

  componentDidUpdate(nextProps, _nextState) {
    if (nextProps.initScript !== this.props.initScript) {
      this.repaint(nextProps.initScript);
    }
  }

  update = data => {
    this.chart.current.injectJavaScript(changeData(data));
  };

  repaint = script => this.chart.current.injectJavaScript(script);

  render() {
    const {webView: WebView, data, onChange, initScript, ...props} = this.props;

    return (
      <WebView
        javaScriptEnabled
        ref={this.chart}
        scrollEnabled={false}
        style={styles.webView}
        injectedJavaScript={initScript}
        source={source}
        originWhitelist={['*']}
        onMessage={props.onMessage}
        onLoad={props.onChartLoad}
        {...props}
      />
    );
  }
}

export const Chart = F2Chart;
