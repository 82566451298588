import { Dimensions, Platform } from 'react-native';

const width = Dimensions.get('window').width;
const height = Dimensions.get('window').height;

export const is_iOS = Platform.OS === 'ios';
export const isWeb = width > 700 && Platform.OS === 'web';

console.log('in layouts!!!', is_iOS)



export default {
  window: {
    width,
    height,
  },
  isSmallDevice: width < 375,
};
