const isGoodTrend = (metricValue, percentChange) => {
	switch (metricValue) {
		case 'onset_latency':
		case 'onsetLatency':
		case 'awake':
		case 'wakeup_count':
		case 'stress':
		case 'restless':
		case 'unproductive_time':
		case 'resting_hr':
		case 'resting_heart_rate':
			return percentChange < 0
		case 'heart_rate_variability_RMSSD':
		case 'mood':
		case 'energy':
		case 'focus':
		case 'productive_percent':
		case 'productive_time':
		case 'light':
		case 'efficiency':
		case 'rem':
		case 'deep':
		case 'duration':
			return percentChange > 0
		default:
			return percentChange > 0
	}
}

const metricToTagColor = (metricValue) => {
	switch (metricValue) {
		case 'onset_latency':
		case 'onsetLatency':
		case 'awake':
		case 'light':
		case 'efficiency':
		case 'restless':
		case 'rem':
		case 'deep':
		case 'duration':
		case 'stress':
		case 'sleep_quality_subjective':
			return '#545db3'
		case 'resting_hr':
		case 'resting_heart_rate':
		case 'unproductive_time':
		case 'heart_rate_variability_RMSSD':
			return '#DC8CAE'
		case 'mood':
			return '#C0D6FE'
		case 'energy':
			return '#ffbc23'
		case 'focus':
		case 'productive_percent':
		case 'productivity_pulse':
		case 'productive_time':
			return '#C0D6FE'
		default:
			return '#545db3'
	}
}

const METRICS = (attributeKey, service, isRecommended = false) => {
    switch (attributeKey) {
        // SLEEP METRICS
        case 'cumulative_nights_tracked':
        case 'cumulative_checkins_completed':
            return {
                value: attributeKey,
                label: "Total nights tracked",
                defaultUnit: "%",
                service: service,
                group: "custom",
                isRecommended,
                priority: 3
            }
        case 'cumulative_login_count':
            return {
                value: attributeKey,
                label: "Cumulative login",
                defaultUnit: "%",
                service: service,
                group: "custom",
                isRecommended,
                priority: 3
            }
        case 'daily_routines_completed_count':
            return {
                value: attributeKey,
                label: "Daily routines completed",
                defaultUnit: "%",
                service: service,
                group: "custom",
                isRecommended,
                priority: 3
            }
        case 'daily_messages_sent_count':
            return {
                value: attributeKey,
                label: "Daily messages sent",
                defaultUnit: "%",
                service: service,
                group: "custom",
                isRecommended,
                priority: 3
            }
        case 'cumulative_routines_completed_count':
            return {
                value: attributeKey,
                label: "Total routines completed",
                defaultUnit: "%",
                service: service,
                group: "custom",
                isRecommended,
                priority: 3
            }
        case 'breathing_rate':
        case 'breath_average':
            return {
                value: attributeKey,
                label: "Breathing rate",
                interpretation: {
                    increase: 'you have higher breathing rate',
                    decrease: 'you walk less',
                    same: 'you walk the same',
                },
                service: service,
                group: "respiratory",
                priority: 1,
                isRecommended,
            }
        case 'temperature_delta':
            return {
                value: attributeKey,
                label: "Temperature deviation",
                interpretation: {
                    increase: 'you have a higher body temperature',
                    decrease: 'you have a lower body temperature',
                    same: 'you have the same body temperature',
                },
                service: service,
                group: "body",
                priority: 1,
                isRecommended,
            }
        case 'wakeup_count':
            return {
                value: attributeKey,
                label: "Number of wake-ups",
                defaultUnit: "%",
                service: service,
                group: "sleep",
                isRecommended,
                priority: 3
            }
        case 'stretching_duration':
            return {
                value: attributeKey,
                label: "Stretching duration",
                interpretation: {
                    increase: 'you get more sunlight exposure',
                    decrease: 'you get less sunlight exposure',
                    same: 'you get the same sunlight exposure',
                },
                defaultUnit: "minute",
                pluralUnits: "minutes",
                service: service,
                group: "activity",
                priority: 1,
                isRecommended,
            }
        case 'yoga_duration':
            return {
                value: attributeKey,
                label: "Yoga duration",
                interpretation: {
                    increase: 'you get more sunlight exposure',
                    decrease: 'you get less sunlight exposure',
                    same: 'you get the same sunlight exposure',
                },
                defaultUnit: "minute",
                pluralUnits: "minutes",
                service: service,
                group: "activity",
                priority: 1,
                isRecommended,
            }
        case 'sunlight_exposure_duration':
            return {
                value: attributeKey,
                label: "Sunlight duration",
                interpretation: {
                    increase: 'you get more sunlight exposure',
                    decrease: 'you get less sunlight exposure',
                    same: 'you get the same sunlight exposure',
                },
                defaultUnit: "minute",
                pluralUnits: "minutes",
                service: service,
                group: "weather",
                priority: 1,
                isRecommended,
            }
        case 'activity_time':
            return {
                value: attributeKey,
                label: "Time of activity",
                interpretation: {
                    increase: 'you walk more',
                    decrease: 'you walk less',
                    same: 'you walk the same',
                },
                service: service,
                group: "activity",
                priority: 1,
                isRecommended,
            }
        case 'steps':
            return {
                value: attributeKey,
                label: "Steps",
                interpretation: {
                    increase: 'you walk more',
                    decrease: 'you walk less',
                    same: 'you walk the same',
                },
                service: service,
                group: "activity",
                priority: 1,
                isRecommended,
            }
        case 'medium_intensity_activity':
            return {
                value: attributeKey,
                label: "Medium intensity activity",
                interpretation: {
                    increase: 'you get more medium intensity activity',
                    decrease: 'you get less medium intensity activity',
                    same: 'you get the same amount of medium intensity activity',
                },
                service: service,
                group: "activity",
                priority: 1,
                isRecommended,
            }
        case 'high_intensity_activity':
            return {
                value: attributeKey,
                label: "High intensity activity",
                defaultUnit: "minutes",
                interpretation: {
                    increase: 'you get more high intensity activity',
                    decrease: 'you get less high intensity activity',
                    same: 'you get the same amount of high intensity activity',
                },
                service: service,
                group: "activity",
                priority: 1,
                isRecommended,
            }
        case 'duration':
            return {
                value: attributeKey,
                label: "Sleep duration",
                interpretation: {
                    increase: 'you sleep longer',
                    decrease: 'you sleep shorter',
                    same: 'you sleep the same',
                },
                service: service,
                group: "sleep",
                isRecommended,
                priority: 1
            }
        case 'sleep_score':
            return {
                value: attributeKey,
                label: "Sleep score",
                interpretation: {
                    increase: 'you have a higher sleep score',
                    decrease: 'you have a lower sleep score',
                    same: 'you have the same sleep score',
                },
                service: service,
                group: "sleep",
                isRecommended,
                priority: 1
            }
        case 'deep':
            return {
                value: attributeKey,
                label: "Deep duration",
                interpretation: {
                    increase: 'you get more deep sleep',
                    decrease: 'you get less deep sleep',
                    same: 'you have the same deep sleep',
                },
                service: service,
                group: "sleep",
                isRecommended,
                priority: 1
            }
        case 'rem':
            return {
                value: attributeKey,
                label: "REM duration",
                service: service,
                group: "sleep",
                isRecommended,
                priority: 1
            }
        case 'light':
            return {
                value: attributeKey,
                label: "Light duration",
                service: service,
                group: "sleep",
                isRecommended,
                priority: 1
            }
        case 'efficiency':
            return {
                value: attributeKey,
                label: "Sleep efficiency",
                defaultUnit: "%",
                service: service,
                group: "sleep",
                isRecommended,
                priority: 3
            }
        case 'onset_latency':
            return {
                value: attributeKey,
                label: "Sleep onset",
                service: service,
                group: "sleep",
                isRecommended,
                priority: 3
            }
        case 'awake':
            return {
                value: attributeKey,
                label: "Awake duration",
                service: service,
                group: "sleep",
                isRecommended,
                priority: 3
            }
        case 'wakeup_count':
            return {
                value: attributeKey,
                label: "Number of wake-ups",
                defaultUnit: "%",
                service: service,
                group: "sleep",
                isRecommended,
                priority: 3
            }
        case 'restless':
            return {
                value: attributeKey,
                label: "Restlessness",
                defaultUnit: "%",
                service: service,
                group: "sleep",
                isRecommended,
                priority: 3
            }
        case 'resting_heart_rate':
            return {
                value: attributeKey,
                label: "Resting heart rate",
                defaultUnit: "bpm",
                service: service,
                group: "heart",
                isRecommended,
                priority: 3
            }
        case 'heart_rate_variability_SDNN':
            return {
                value: attributeKey,
                label: "Heart rate variability",
                service: "oura",
                group: "heart",
                isRecommended,
                priority: 2
            }
        case 'heart_rate_variability_RMSSD':
            return {
                value: attributeKey,
                label: "Heart rate variability",
                service: "oura",
                group: "heart",
                isRecommended,
                priority: 2
            }
        case 'stress':
            return {
                value: attributeKey,
                label: "Stress",
                group: "custom",
                isRecommended,
                priority: 3
            }
        case 'mood':
            return {
                value: attributeKey,
                label: "Mood",
                group: "custom",
                isRecommended,
                priority: 3
            }
        case 'focus':
            return {
                value: attributeKey,
                label: "Focus",
                group: "custom",
                isRecommended,
                priority: 3
            }
        case 'energy':
            return {
                value: attributeKey,
                label: "Energy",
                group: "custom",
                isRecommended,
                priority: 3
            }
        case 'supplement_usage':
            return {
                value: attributeKey,
                label: "Supplement usage",
                group: "custom",
                isRecommended,
                priority: 13
            }
        case 'supplement_amount':
            return {
                value: attributeKey,
                label: "Supplement usage",
                group: "custom",
                isRecommended,
                priority: 13
            }
        case 'alcohol_usage':
            return {
                value: attributeKey,
                label: "Alcohol amount",
                group: "custom",
                isRecommended,
                priority: 10
            }
        case 'bedtime_end':
            return {
                value: attributeKey,
                label: "Wake time",
                // defaultUnit: "", 
                // units: ["mg"], 
                isRecommended,
                group: "sleep",
                priority: 9
            }
        case 'bedtime_start':
            return {
                value: attributeKey,
                label: "Bedtime",
                // defaultUnit: "", 
                // units: ["mg"], 
                isRecommended,
                group: "sleep",
                priority: 8
            }
        case 'waketime_custom':
            return {
                value: attributeKey,
                label: "Wake time (manual)",
                // defaultUnit: "", 
                // units: ["mg"], 
                isRecommended,
                group: "custom",
                priority: 9
            }
        case 'bedtime_custom':
            return {
                value: attributeKey,
                label: "Bedtime (manual)",
                // defaultUnit: "", 
                // units: ["mg"], 
                isRecommended,
                group: "custom",
                priority: 8
            }
        case 'stimulating_work_completion_time':
            return {
                value: attributeKey,
                label: "Time of last electronic usage",
                // service: service,
                isRecommended,
                group: "custom",
                priority: 7
            }
        case 'no_electronics_usage':
            return {
                value: attributeKey,
                label: "No electronic usage before bed",
                // service: service,
                isRecommended,
                group: "custom",
                priority: 7
            }
        case 'electronics_last_usage_time':
            return {
                value: attributeKey,
                label: "Time of last electronic usage",
                // service: service,
                isRecommended,
                group: "custom",
                priority: 7
            }
        case 'journaling_usage_time':
            return {
                value: attributeKey,
                label: "Time of journaling",
                // service: service,
                isRecommended,
                group: "custom",
                priority: 7
            }
        case 'journaling_duration':
            return {
                value: attributeKey,
                label: "Journal duration",
                // service: service,
                defaultUnit: "minutes",
                pluralUnits: "minutes",
                isRecommended,
                group: "custom",
                priority: 7
            }
        case 'warm_bath_duration':
            return {
                value: attributeKey,
                label: "Warm bath duration",
                // service: service,
                defaultUnit: "minutes",
                pluralUnits: "minutes",
                isRecommended,
                group: "custom",
                priority: 7
            }
        case 'cold_shower_time':
            return {
                value: attributeKey,
                label: "Time of cold shower",
                // service: service,
                isRecommended,
                group: "custom",
                priority: 7
            }
        case 'cold_shower_duration':
            return {
                value: attributeKey,
                label: "Cold shower duration",
                // service: service,
                defaultUnit: "minutes",
                pluralUnits: "minutes",
                isRecommended,
                group: "custom",
                priority: 7
            }
        case 'warm_bath_time':
            return {
                value: attributeKey,
                label: "Time of warm bath before bed",
                // service: service,
                isRecommended,
                group: "custom",
                priority: 7
            }
        case 'earplugs_usage':
            return {
                value: attributeKey,
                label: "Earplugs usage at night",
                // service: service,
                isRecommended,
                group: "custom",
                priority: 6
            }
        case 'coffee_amount':
            return {
                value: attributeKey,
                label: "Coffee amount",
                defaultUnit: "cup",
                pluralUnits: "cups",
                units: ["cup"],
                group: "custom",
                isRecommended,
                priority: 5
            }
        case 'phosphatidylserine_amount':
            return {
                value: attributeKey,
                label: "Phosphatidylserine amount",
                defaultUnit: "mg",
                defaultUnit: "mg",
                units: ["mg"],
                group: "custom",
                isRecommended,
                priority: 5
            }
        case 'lemon_balm_amount':
            return {
                value: attributeKey,
                label: "Lemon balm amount",
                defaultUnit: "mg",
                defaultUnit: "mg",
                units: ["mg"],
                group: "custom",
                isRecommended,
                priority: 5
            }
        case 'reishi_amount':
            return {
                value: attributeKey,
                label: "Reishi amount",
                defaultUnit: "mg",
                units: ["mg"],
                group: "custom",
                isRecommended,
                priority: 5
            }
        case 'reishi_usage_time':
            return {
                value: attributeKey,
                label: "Time of reishi usage",
                group: "custom",
                isRecommended,
                priority: 5
            }
        case 'last_coffee_time':
            return {
                value: attributeKey,
                label: "Time of last coffee",
                group: "custom",
                isRecommended,
                priority: 5
            }
        case 'chamomile_tea_amount':
            return {
                value: attributeKey,
                label: "Chamomile tea amount",
                defaultUnit: "cup",
                pluralUnits: "cups",
                units: ["cup"],
                group: "custom",
                isRecommended,
                priority: 5
            }
        case 'room_temperature':
            return {
                value: attributeKey,
                label: "Room temperature",
                defaultUnit: "degrees",
                units: ["degrees"],
                group: "custom",
                isRecommended,
                priority: 5
            }
        case 'cooling_pad_usage':
            return {
                value: attributeKey,
                label: "Cooling pad usage at night",
                // service: service,
                isRecommended,
                group: "custom",
                priority: 6
            }
        case 'cooling_pad_temperature':
            return {
                value: attributeKey,
                label: "Cooling pad temperature",
                defaultUnit: "degrees",
                units: ["degrees"],
                group: "custom",
                isRecommended,
                priority: 5
            }
        case 'inclined_bed_usage':
            return {
                value: attributeKey,
                label: "Inclined bed usage",
                // service: service,
                group: "custom",
                isRecommended,
                priority: 4
            }
        case 'pink_noise_usage':
            return {
                value: attributeKey,
                label: "Pink noise usage",
                // service: service,
                group: "custom",
                isRecommended,
                priority: 4
            }
        case 'tart_cherry_amount':
            return {
                value: attributeKey,
                label: "Tart cherry amount",
                defaultUnit: "mg",
                units: ["mg"],
                // service: service,
                group: "custom",
                isRecommended,
                priority: 4
            }
        case 'valerian_root_amount':
            return {
                value: attributeKey,
                label: "Valerian root amount",
                defaultUnit: "mg",
                units: ["mg"],
                // service: service,
                group: "custom",
                isRecommended,
                priority: 4
            }
        case 'valerian_root_usage_time':
            return {
                value: attributeKey,
                label: "Time of valerian root usage",
                // service: service,
                group: "custom",
                isRecommended,
                priority: 4
            }
        case 'lavender_oil_usage':
            return {
                value: attributeKey,
                label: "Lavender oil usage",
                // service: service,
                group: "custom",
                isRecommended,
                priority: 4
            }
        case 'lavender_oil_usage_time':
            return {
                value: attributeKey,
                label: "Time of lavender oil usage",
                // service: service,
                group: "custom",
                isRecommended,
                priority: 4
            }
        case 'weighted_blanket_usage':
            return {
                value: attributeKey,
                label: "Weighted blanket usage",
                // service: service,
                group: "custom",
                isRecommended,
                priority: 4
            }
        case 'sleeping_mask_usage':
            return {
                value: attributeKey,
                label: "Sleeping mask usage",
                // service: service,
                group: "custom",
                isRecommended,
                priority: 4
            }
        case 'sleep_quality_subjective':
            return {
                value: attributeKey,
                label: "Sleep quality score (subjective)",
                // service: service,
                group: "sleep",
                isRecommended,
                priority: 3
            }
        case 'sleep_refresh_custom':
            return {
                value: attributeKey,
                label: "Sleep quality score (subjective)",
                // service: service,
                group: "sleep",
                isRecommended,
                priority: 3
            }
        case 'rem_duration_custom':
            return {
                value: attributeKey,
                label: "REM duration minutes (manual)",
                // service: service,
                group: "custom",
                isRecommended,
                priority: 3
            }
        case 'deep_duration_custom':
            return {
                value: attributeKey,
                label: "Deep duration minutes (manual)",
                // service: service,
                group: "custom",
                isRecommended,
                priority: 3
            }
        case 'sleep_efficiency_custom':
            return {
                value: attributeKey,
                label: "Sleep efficiency (manual)",
                // service: service,
                group: "custom",
                isRecommended,
                priority: 3
            }
        case 'magnesium_amount':
            return {
                value: attributeKey,
                label: "Magnesium amount",
                defaultUnit: "mg",
                units: ["mg"],
                group: "custom",
                isRecommended,
                priority: 3
            }
        case 'melatonin_amount':
            return {
                value: attributeKey,
                label: "Melatonin amount",
                defaultUnit: "mg",
                units: ["mg"],
                group: "custom",
                isRecommended,
                priority: 3
            }
        case 'breathwork_minutes':
            return {
                value: attributeKey,
                label: "Breathwork minutes",
                defaultUnit: "minute",
                pluralUnits: "minutes",
                units: ["minutes"],
                group: "custom",
                isRecommended,
                priority: 3
            }
        case 'meditation_minutes':
            return {
                value: attributeKey,
                label: "Minutes meditated",
                defaultUnit: "minute",
                pluralUnits: "minutes",
                units: ["minutes"],
                group: "custom",
                isRecommended,
                priority: 3
            }
        case 'raw_honey_amount':
            return {
                value: attributeKey,
                label: "Raw honey amount",
                defaultUnit: "tablespoon",
                pluralUnits: "tablespoons",
                units: ["tablespoon"],
                group: "custom",
                isRecommended,
                priority: 3
            }
        case 'CBD_amount':
        case 'cbd_amount':
            return {
                value: attributeKey,
                label: "CBD amount",
                defaultUnit: "mg",
                units: ["mg"],
                group: "custom",
                isRecommended,
                priority: 3
            }
        case 'CBD_usage_time':
        case 'cbd_usage_time':
            return {
                value: attributeKey,
                label: "Time of CBD usage",
                group: "custom",
                isRecommended,
                priority: 3
            }
        case 'MCT_oil_amount':
            return {
                value: attributeKey,
                label: "MCT oil amount",
                defaultUnit: "mL",
                units: ["mL"],
                group: "custom",
                isRecommended,
                priority: 3
            }
        case 'MCT_oil_usage_time':
            return {
                value: attributeKey,
                label: "Time of MCT oil usage",
                group: "custom",
                isRecommended,
                priority: 3
            }
        case 'chamomile_tea_usage_time':
            return {
                value: attributeKey,
                label: "Time of chamomile tea usage",
                group: "custom",
                isRecommended,
                priority: 3
            }
        case 'blue_blocking_glasses_usage':
            return {
                value: attributeKey,
                label: "Blue blocking glasses usage",
                defaultUnit: "",
                units: [""],
                group: "custom",
                isRecommended,
                priority: 3
            }
        case 'blue_blocking_glasses_usage_time':
            return {
                value: attributeKey,
                label: "Time of blue blocking glasses usage",
                defaultUnit: "",
                units: [""],
                group: "custom",
                isRecommended,
                priority: 3
            }
        case 'fasting_completion':
            return {
                value: attributeKey,
                label: "Fasting completion",
                defaultUnit: "",
                units: [""],
                group: "custom",
                priority: 3
            }
        case 'fasting_duration':
            return {
                value: attributeKey,
                label: "Fasting duration",
                defaultUnit: "hours",
                units: [""],
                group: "custom",
                priority: 3
            }
        case 'bedtime_offset':
            return {
                value: attributeKey,
                label: "Bedtime offset",
                defaultUnit: "hour",
                pluralUnits: "hours",
                units: [""],
                group: "custom",
                priority: 3
            }
        case 'duration_offset':
            return {
                value: attributeKey,
                label: "Fasting duration offset",
                defaultUnit: "hours",
                units: [""],
                group: "custom",
                priority: 3
            }
        case 'multiday_fast':
            return {
                value: attributeKey,
                label: "Multi-day fast",
                defaultUnit: "",
                units: [""],
                group: "custom",
                priority: 3
            }
        case 'fasting_start_time':
            return {
                value: attributeKey,
                label: "Start time of fasting",
                defaultUnit: "",
                units: [""],
                group: "custom",
                priority: 3
            }
        case 'fasting_end_time':
            return {
                value: attributeKey,
                label: "End time of fasting",
                defaultUnit: "",
                units: [""],
                group: "custom",
                priority: 3
            }
        case 'alcohol_amount':
            return {
                value: attributeKey,
                label: "Alcohol amount",
                defaultUnit: "cup",
                pluralUnits: "cups",
                units: ["cup"],
                group: "custom",
                isRecommended,
                priority: 3
            }
        case 'last_alcohol_time':
            return {
                value: attributeKey,
                label: "Time of last alcohol",
                defaultUnit: "",
                units: [""],
                group: "custom",
                isRecommended,
                priority: 3
            }
        case 'sickness':
            return {
                value: attributeKey,
                label: "Sickness",
                defaultUnit: "",
                units: [""],
                group: "custom",
                isRecommended,
                priority: 3
            }
        case 'blue_blocking_glasses_usage_time':
            return {
                value: attributeKey,
                label: "Time of blue blocking glasses usage",
                defaultUnit: "",
                units: [""],
                group: "custom",
                isRecommended,
                priority: 3
            }
        // RESCUETIME
        case 'weather':
            return {
                value: attributeKey,
                label: "Weather",
                group: "weather",
                defaultUnit: "",
                isRecommended,
                priority: 1
            }
        case 'temperature':
            return {
                value: attributeKey,
                label: "Temperature",
                group: "temperature",
                defaultUnit: "",
                isRecommended,
                priority: 1
            }
        case 'productive_time':
            return {
                value: attributeKey,
                label: "Productive time",
                service: service,
                group: "productivity",
                isRecommended,
                priority: 1
            }
        case 'unproductive_time':
            return {
                value: attributeKey,
                label: "Unproductive time",
                service: service,
                group: "productivity",
                isRecommended,
                priority: 2
            }
        case 'productivity_pulse':
            return {
                value: attributeKey,
                label: "Overall productivity pulse",
                service: service,
                group: "productivity",
                isRecommended,
                priority: 3
            }
        case 'productive_percent':
        case 'productive_percentage':
            return {
                value: attributeKey,
                label: "Overall productive percentage",
                service: service,
                group: "productivity",
                isRecommended,
                priority: 3
            }
        default:
            break;
    }
}

export {
	METRICS,
	metricToTagColor,
	isGoodTrend
}