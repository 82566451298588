import React, { useEffect } from 'react';
import { View } from 'react-native';
import Colors from '../../constants/Colors';
import F2Chart from '../F2Chart'
import Layout, { isWeb } from '../../constants/Layout'
import { hexToRgbA } from '../../utils/ColorsUtils'
import { histogram } from "d3-array";
import moment from 'moment'
import { groupBy, partition } from 'lodash'

const totalAverage = (data) => data.reduce((a, b) => a + b, 0) / (data.length)
function binSleepScheduleData(data) {
    if (data.length == 0) {
        return {
            bedtimeBins: [], wakeupBins: [], timeValues: [], latestWakeup: null, earliestBedtime: null

        }
    }
    const flattenedData = data.map((x) => {
        return x.data.map((d) => {
            return {
                ...d,
                x: moment(d.value).valueOf(),
                y: moment(d.value).hour() + moment(d.value).minutes() / 60,
                type: x.id == 'bedtime_end' ? "Wakeup" : "Bedtime"
            }
        })
    }).flat()


    const [bedtimes, wakeupTimes] = partition(flattenedData, x => x.type == "Bedtime")
    if (bedtimes.length == 0 && wakeupTimes.length == 0) {
        return {
            bedtimeBins: [], wakeupBins: [], timeValues: [], latestWakeup: null, earliestBedtime: null

        }
    }    
    const bedtimeValues = bedtimes.map((x) => {
        if (x.y > 16) {
            return x.y - 24
        }
        return x.y
    })
    const wakeupValues = wakeupTimes.map(x => x.y)
    let earliestBedtime = Math.floor(Math.min(...bedtimeValues))
    const latestWakeup = Math.ceil(Math.max(...wakeupValues))
    // console.log("thresholds", earliestBedtime, latestWakeup)
    // console.log("thresholds", (latestWakeup - earliestBedtime) * 2 - 1)
    var histGenerator = histogram()
        .domain([earliestBedtime, latestWakeup])    // Set the domain to cover the entire intervall [0,1]
        .thresholds((latestWakeup - earliestBedtime) * 2 - 1);
    const timeValues = new Array(latestWakeup - earliestBedtime).fill(0).map((_, index) => earliestBedtime + index)
    const bedtimeBins = histGenerator(bedtimeValues).map((array, index) => {
        return {
            x: earliestBedtime + (index * 0.5),
            y: array.length,
            type: "Bedtime"

        }
    });
    const bedtimeAverage = totalAverage(bedtimeValues.map(x => x)) - 24
    const wakeupTimeAverage = totalAverage(wakeupValues)

    const wakeupBins = histGenerator(wakeupValues).map((array, index) => {
        return {
            x: earliestBedtime + (index * 0.5),
            y: array.length,
            type: "Wakeup"
        }
    });;
    const bedtimeConsistency = Math.sqrt(bedtimeValues.map(x => Math.pow(x - 24 - bedtimeAverage, 2)).reduce((a, b) => a + b) / bedtimeValues.length)

    const wakeupConsistency = Math.sqrt(wakeupValues.map(x => Math.pow(x - wakeupTimeAverage, 2)).reduce((a, b) => a + b) / wakeupValues.length)
    // console.log('wakeupConsistency', wakeupConsistency)
    let consistency = (Math.abs(bedtimeConsistency) + Math.abs(wakeupConsistency)) / 2
    return {
        bedtimeBins, wakeupBins, timeValues, latestWakeup, earliestBedtime, consistency
    }
}


const initDualHistogramScript = (data, timeValues, earliestBedtime, latestWakeup) => `
(function(){
    chart =  new F2.Chart({
        id: 'chart',
        pixelRatio: window.devicePixelRatio,
        padding: [24, 48, 'auto'],
    });

	chart.source(${JSON.stringify(data)}, {
         x: {
            //  type: 'timeCat',
            //  mask: 'h:mm a',
            values: ${JSON.stringify(timeValues)},
            tickCount: ${Math.round(timeValues.length)},
            formatter: function (value) {
                if (value < 0) {
                    return (value + 24) % 12 + 'pm'
                }
                if (value%12 == 0) {
                    return value == 0 ? '12am': '12pm'
                }
                if (value >= 0 && value < 12) {
                    return value + 'am'
                }
                return value % 12 + 'pm'
            }
        },
    });
    chart.axis('y', false)
    chart.axis('x', {
        tickLine: {
            length: 4,
            lineWidth: 1,
            stroke: '#fff'
        },
        label: function label(text, index) {
            if (index % 2 == 1) {
                return {
                    fill: '#fff',
                    fontFamily: 'Avenir-Book',
                    fontWeight: 'bold'
                };
            }
            return null
        }
    });
	chart.interval().position('x*y').shape('line')
	.color('type', (x) => {
        if (x == 'Bedtime') {
            return 'l(90) 0:${Colors.lightPurpleColor} 1:${Colors.purpleColor}';
        }
        return  'l(90) 0:${Colors.orangeColor} 1:${Colors.lightColor}';
	}).size(4).animate({
		appear: {
			duration: 800
		}
	}).style({

    });;
    chart.legend(false)
	chart.tooltip({
        showItemMarker: false,
		onShow: function onShow(ev) {
			const items = ev.items;
            items[0].name = items[0].title;
            const value1 = items[0].value
            const value2 = items[1].value
            items[0].value = 'Count: ' + items[0].value;
            if (value1 == 0) {
                items[0].value = ""
                items[1].name = ""
                items[1].value = "Count: " + items[1].value
            } else if (value2 == 0) {
                items[1].value = ""
                items[1].name = ""
                
            }
		}
	});

	chart.render();
})();
`;


export default function SleepScheduleHistogramChart(props) {
    const { data } = props
    const {
        bedtimeBins, wakeupBins, timeValues, latestWakeup, earliestBedtime, consistency
    } = binSleepScheduleData(data)
    

    console.log("bedtimeBins", bedtimeBins)
    console.log("wakeupBins", wakeupBins)
    return (
        <View style={{
            width: isWeb ? Layout.window.width/2: Layout.window.width,
            justifyContent: "center",
            alignSelf: "center",
            alignItems: "center",
            height: 240,
            paddingHorizontal: 0,
        }}>
            <F2Chart initScript={initDualHistogramScript([...bedtimeBins, ...wakeupBins], timeValues, earliestBedtime, latestWakeup)} />
            
        </View>
        
    )
}

