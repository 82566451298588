import React from 'react';
import {View} from 'react-native'
import AntIcon from 'react-native-vector-icons/AntDesign';
import {theme} from '../../styles/theme';

export const Heart = () => (
  <View>
    <AntIcon name="heart" color={theme.palette.heart.main} size={32} />
  </View>
);
