import moment from 'moment';

const todayDateString = () => {
  return moment().format('ddd, MMM D YYYY');
};
const tomorrowDateString = () => {
  return moment().add(1, 'days').format('ddd, MMM D YYYY');
};

const convertTimestampToDateString = (timestamp, utc = false) => {
  if (utc) {
    return moment.utc(timestamp).format('ddd, MMM D YYYY');
  }
  return moment(timestamp).format('ddd, MMM D YYYY');
};

const formatToDateString = (timestamp, format) => {
  return moment(timestamp).format(format);
};

const convertTimestampToDayOfWeek = timestamp => {
  return moment(timestamp).format('dddd');
};
const isDateSameWeek = timestamp => {
  return moment(timestamp).isSame(moment(), 'week');
};

const currentTimestamp = () => {
  return moment().valueOf();
};

function pad(num) {
  return ('0' + num).slice(-2);
}
function formatSecondsToDuration(secs) {
  var minutes = Math.floor(secs / 60);
  secs = secs % 60;
  var hours = Math.floor(minutes / 60);
  minutes = minutes % 60;
  if (hours <= 0) {
    return `${pad(minutes)}:${pad(secs)}`;
  }
  return `${pad(hours)}:${pad(minutes)}:${pad(secs)}`;
  // return pad(hours)+":"+pad(minutes)+":"+pad(secs); for old browsers
}

const previousDay = timestamp => {
  return moment(timestamp, 'YYYY-MM-DD').subtract(1, 'days');
};
const nextDay = timestamp => {
  return moment(timestamp, 'YYYY-MM-DD').add(1, 'days');
};

const convertToISODateString = timestamp => {
  return moment(timestamp).format('YYYY-MM-DD');
};

const todayISODateString = () => {
  return moment().format('YYYY-MM-DD');
};

const nDaysAgoISODateString = n => {
  return moment().subtract(n, 'days').format('YYYY-MM-DD');
};

const yesterdayISODateString = () => {
  return moment().add(-1, 'days').format('YYYY-MM-DD');
};
const tomorrowISODateString = () => {
  return moment().add(1, 'days').format('YYYY-MM-DD');
};

const getTimeOfDay = (datetime = moment()) => {
  const hour = moment(datetime).local().hour();
  if (hour <= 4) {
    return 'evening';
  }
  if (hour < 12) {
    return 'morning';
  }
  if (hour >= 12 && hour < 17) {
    return 'afternoon';
  }
  if (hour >= 17) {
    return 'evening';
  }
};
const getEmojiForTimeOfDay = () => {
  const hour = moment().local().hour();
  if (hour <= 4) {
    return '🌙';
  }
  if (hour < 12) {
    return '☀️';
  }
  if (hour >= 12 && hour < 17) {
    return '🌤';
  }
  if (hour >= 17) {
    return '🌙';
  }
};

function isEvening(date = moment()) {
  const hour = date.local().hour();
  // return true
  return hour >= 17 || hour <= 4;
}

export const getDuration = (time, format) => {
  const duration = moment.duration(time);
  if (duration.asHours() >= 1) {
    return moment.utc(duration.asMilliseconds()).format(format ?? 'H[hr]mm[m]');
  } else if (duration.asMinutes() >= 1) {
    return moment
      .utc(duration.asMilliseconds())
      .format(format ?? 'mm[min]ss[s]');
  } else {
    return moment.utc(duration.asMilliseconds()).format(format ?? 'ss[s]');
  }
};

export const getCalendarFormat = dateString => {
  if (dateString) {
    return moment(dateString, 'YYYY-MM-DD').calendar(null, {
      lastDay: '[Yesterday]',
      sameDay: '[Today]',
      nextDay: '[Tomorrow]',
      lastWeek: '[Last] dddd',
      nextWeek: 'dddd',
      sameElse: 'MMM DD',
    });
  }
  return 'Today';
};

export const getDurationBetweenPoints = (start, end, format) =>
  getDuration(moment(end).diff(moment(start)), format);

export const calendarOptions = {
  lastDay: '[Yesterday]',
  sameDay: '[Today]',
  nextDay: 'MMM D',
  lastWeek: 'MMM D',
  nextWeek: 'MMM D',
  sameElse: 'MMM D',
};

export {
  todayDateString,
  tomorrowDateString,
  isEvening,
  formatToDateString,
  formatSecondsToDuration,
  isDateSameWeek,
  getTimeOfDay,
  getEmojiForTimeOfDay,
  yesterdayISODateString,
  todayISODateString,
  nDaysAgoISODateString,
  convertToISODateString,
  tomorrowISODateString,
  convertTimestampToDateString,
  convertTimestampToDayOfWeek,
  currentTimestamp,
  previousDay,
  nextDay,
};
