import axios, {AxiosInstance} from 'axios';

class API {
  client: AxiosInstance;

  constructor() {
    const axiosInstance = axios.create({
      baseURL: 'https://api.bioloopsleep.com/api/',
      timeout: 6000,
    });

    this.client = axiosInstance;
  }
}

export const APIController = new API();

export default APIController.client;
