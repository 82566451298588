import React, { useEffect } from 'react';
import {Text, StyleSheet, Image, TouchableOpacity, Platform, View, ScrollView } from 'react-native';
// import {showModal} from 'react-native-navigation-hooks';
import useOnAppActivated from '../hooks/useOnAppActivated';
import tw from 'twrnc';
import {getTodaysWorkoutReport} from '../services/workout-service';
import Modal from './modal'
import Colors from '../constants/Colors';
import { Row, Col, Grid} from "react-native-easy-grid";
// import { } from 'native-base'
import Card from './Card'
import Layout, { isWeb } from '../constants/Layout'
import { HABIT_ID_TO_NAME } from '../constants/Attributes'
import PopulationBarChart from './Charts/HistogramChart'
import HeatmapChart from './Charts/Heatmap'
import MonthHeatmapChart from './Charts/MonthHeatmap'
import CircleChart from './Charts/CircleChart'
import TrendsChart from './Charts/TrendChart'
import RollingTrendChart from './Charts/RollingTrendChart'
import PieChart from './Charts/PieChart'
import HighlightedPieChart from './Charts/HighlightedPieChart'
import StackedBarChart from './Charts/StackedBarChart'
import SleepScheduleHistogramChart from './Charts/DualHistogramChart'
import { SleepScore } from './SleepScoreCard'
import LottieView from 'lottie-react-native';
import { hexToRgbA } from '../utils/ColorsUtils';
import * as Animatable from 'react-native-animatable';
import habitIcons from '../../assets/ExperimentIcons'
import curly_ball from '../../assets/curly_ball.png'
import diamond_star from '../../assets/diamond_star.png'
import planet_ring from '../../assets/planet_ring.png'
import flat_waves from '../../assets/flat_waves.png'
import upward_waves from '../../assets/upward_waves.png'
import bioloop_logo from '../../assets/crescent_logo_violet.png'
import Tooltip from './Tooltip'
import Icon from 'react-native-vector-icons/Feather';
import { GradientText } from "./GradientText";
import LinearGradient from 'react-native-linear-gradient'
import AnimateNumber from 'react-native-animate-number'
import * as attributesApi from '../data/attributes/api'
import useSWR from 'swr';
import moment from 'moment'
import Clipboard from '@react-native-clipboard/clipboard'
import RoutineImpactView from './RoutineImpactView'
import commonStyles from '../theme/common'
import {
	classifyConsistency, formatTime, interpretConsistency
} from '../utils/timeUtils';
import { BenchmarkProgressBar } from './Charts/BenchmarkBar'
import Helmet from 'react-helmet'
import SleepScheduleView from './Charts/SleepScheduleView';

const styles = StyleSheet.create({
    moonContainer: {
        borderRadius: 800, // border borderRadius same as width and height
        width: isWeb ? Layout.window.width * 0.5 * 0.33: Layout.window.width * 0.5,
        height: isWeb ? Layout.window.width * 0.5 * 0.33: Layout.window.width * 0.5,
        marginRight: 0, // reposition the circle inside parent view
        position: 'absolute',
        top: Layout.window.width * 0.5 * 0.11, // show the bottom part of circle
        right: isWeb ? -Layout.window.width * 0.5 * 0.33 * 0.33: -Layout.window.width * 0.13,
        overflow: 'hidden',
        borderWidth: 2,
        borderColor: hexToRgbA(Colors.brightColor, 1),
        backgroundColor: hexToRgbA(Colors.brightColor, 0.2) // hide not important part of image
    },
})

function StarPattern(props) {
    return <View style={{ top: isWeb ? 48: 36, left: isWeb ? 32: 12, zIndex: 1, position: "absolute" }}>
        <Image resizeMode='contain' source={diamond_star} style={{ position: 'absolute', left: 44, top: -12, width: 12, height: 12, opacity: 0.3 }} />
        <Image resizeMode='contain' source={diamond_star} style={{ position: 'absolute', left: 22, top: -4, width: 16, height: 16, opacity: 0.4 }} />
        <Image resizeMode='contain' source={diamond_star} style={{ position: 'absolute', left: 0, top: -20, width: 18, height: 18, opacity: 0.5 }}   />
    </View>
}
function SectionCard(props) {
    const style = props.style ?? {}
    return (<Animatable.View 
        animation="fadeIn"
        style={[{ marginTop: 8 }]}
    >
        <LinearGradient 
            colors={props.colors ?? [Colors.darkBlackColor, Colors.darkBlackColor]}
            start={{ x: 0, y: 0 }}
            end={{ x: 1, y: 0.3 }}
            style={[commonStyles.containerDropShadow, {
                zIndex: 2, paddingTop: 32, minHeight: 100, ...shadowOptions(),  ...style
            }]}
        >
            {/* <StarPattern/> */}
            {props.children}
        </LinearGradient>
    </Animatable.View>)
}

function SectionKeyStat(props) {
    const style = props.style ?? {}
    return (<Text style={[tw.style('text-white p-2 font-medium', {
        fontFamily: 'Teodor-Light', color: props.color ?? Colors.shadowColor,fontSize: 48, textAlign: "center"
        }), style]}>
        {props.children}
    </Text>)
}
function SectionKeyStatSubtitle(props) {
    const style = props.style ?? {}
    return (<Text style={[tw.style('text-white p-2 font-medium', {
        fontFamily: 'SpeziaNarrow-SemiBold', color: Colors.lightColor, fontSize: 20, textAlign: 'center'
    }), style]}>
        {props.children}
    </Text>)
}

let movieExamples = [
    {
        name: 'Inception',
        duration: 2.46666
    },
    {
        name: 'Interstellar',
        duration: 2.816666
    },
]

let shadowOptions = (color=Colors.whiteColor) => {
    return { 
        backgroundColor: Colors.darkLightBlackColor,
        padding: 8,
        borderRadius: 8,
        paddingVertical: 16,
        shadowOffset: {
            width: 0,
            height: 0,
        },
        shadowColor: color,
        shadowOpacity: 0.25,
        shadowRadius: 10,
    }
}

const sum = (data) => data.reduce((a, b) => a + b, 0)
const totalAverage = (data) => sum(data)/ (data.length)


const renderRoutineStat = ({ label, duration, count, completedCount, values }, index) => {
let units = duration != 0 ? "minutes" : "times"
let nominator = duration != 0 ? Math.round(duration * 10) / 10 * completedCount : completedCount
let denominator = duration != 0 ? Math.round(duration * 10) / 10 * count : count
let keyStatFontSize = nominator ? Math.max(Math.min(Math.round(Math.abs(nominator / 1000 * 100)), 28), 16) : Math.max(Math.min(Math.round(Math.abs(completedCount / 1000 * 100)), 28), 16)
let subtitleFontSize = Math.min(keyStatFontSize - 4, 10)

let percentComplete = Math.round(completedCount / count * 100)
return <div style={index != topRoutines.length - 1 ? { borderRightWidth: 1, borderRightColor: Colors.lighterGrayColor } : {}}>
    <SectionKeyStatSubtitle style={{ fontSize: 12, marginBottom: -16, color: Colors.darkTintColor }}>
    {label}
    </SectionKeyStatSubtitle>
    <SectionKeyStat
    style={{
        marginBottom: -12, textAlign: "center", fontSize: keyStatFontSize,
        color: percentComplete < 30 ? Colors.redColor : percentComplete > 70 ? Colors.positiveGreenColor : Colors.darkOrangeColor
    }}
    >
    {`${nominator}/${denominator}`}
    </SectionKeyStat>
    <SectionKeyStatSubtitle style={{ fontSize: subtitleFontSize }}>
    {units}
    </SectionKeyStatSubtitle>
</div>
}

function HabitImpactCell(props) {
  const { name: label, averagePercentChange, outcomeMetricKey, habitKey: iconKey } = props;
  // const { name, habitKey } = habit;
  // const tagsRow = tags.map(mapTagToIcon)
  const iconSize = 18;
  const iconSrc =
    habitIcons[iconKey] || habitIcons.experiment;
  const directionArrow =
    averagePercentChange > 0 ? '↑' : averagePercentChange == 0 ? '-' : '↓';
  const trend = 1;
  const trendColor =
    averagePercentChange == 0
      ? '#aaa'
      : trend
      ? Colors.brightTintColor
      : (averagePercentChange < 0.03 && averagePercentChange > 0) ||
        (averagePercentChange > -0.03 && averagePercentChange < 0)
      ? '#aaa'
      : Colors.redColor;

    console.log("props", props)
  return (
    <View
      style={{
        borderColor: Colors.darkLightBlackColor,
        borderWidth: 1,
        borderRadius: 8,
        padding: 8,
        paddingLeft: 8,
        paddingRight: 8,
        marginBottom: 8,
        width: 'auto',
      }}>
      <View style={{ flexDirection: 'row' }}>
        <View
          style={{
            alignSelf: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            height: 24,
            width: 24,
          }}>
          {<Image source={iconSrc} style={{ height: iconSize, width: iconSize }} />}
          {<iconSrc width={iconSize} height={iconSize}/>}
          {/* {<iconSrc({ width: iconSize, height: iconSize})} */}
        </View>
        <Text
          icon
          style={{
            fontFamily: 'Spezia-Regular',
            marginTop: 2,
            marginBottom: 2,
            fontSize: 14,
            color: Colors.lightColor,
            paddingLeft: 8,
          }}>
          {label}
        </Text>
        <Text
          icon
          style={{
            position: 'absolute',
            fontFamily: 'SpeziaExtended-Regular',
            marginTop: 4,
            marginBottom: 0,
            fontSize: 12,
            color: trendColor,
            paddingLeft: 8,
            right: 8,
          }}>
          {`${directionArrow}${(averagePercentChange * 100).toFixed(1)}%`}
        </Text>
      </View>
    </View>
  );
}

const renderHabitStat = ({ label, duration, count, completedCount, values, ...restProps }, index) => {
{

    let units = duration != 0 ? "times" : "times"
    let stat = duration != 0 ? Math.round(duration * 10) / 10 : null
    let keyStatFontSize = 24 ?? Math.max(Math.min(Math.round(Math.abs(stat / 3000 * 100)), 28), 28)
    let subtitleFontSize = Math.min(keyStatFontSize - 4, 10)
    let percentComplete = Math.round(completedCount / count * 100)

return <View style={[commonStyles.containerWithBorder, { width: isWeb ? Layout.window.width / 2: Layout.window.width - 64, margin: 8 }]}>
    <SectionKeyStatSubtitle style={{ fontFamily: "Inter-SemiBold", fontSize: 16, marginBottom: -16, color: Colors.lightColor }}>
        {HABIT_ID_TO_NAME(restProps.experimentId, restProps.experimentName)}
    </SectionKeyStatSubtitle>
    <SectionKeyStat
        style={{
        marginBottom: -20, textAlign: "center", fontSize: keyStatFontSize,
        color: percentComplete < 30 ? Colors.redColor : percentComplete > 70 ? Colors.positiveGreenColor : Colors.darkOrangeColor
        }}
    >
        {0 ?? stat != null ? stat : `${completedCount}/${count}`}
    </SectionKeyStat>
    <SectionKeyStatSubtitle style={{ fontSize: subtitleFontSize }}>
        {units}
    </SectionKeyStatSubtitle>
    </View>
}
}

const renderActivityStat = ({ label, duration, count, completedCount }, index) => {
let units = duration && duration != 0 ? "minutes" : "times"
let percentChange = duration != 0 ? duration : count
let keyStatFontSize = 24 ?? Math.max(Math.min(Math.round(Math.abs(percentChange / 3000 * 100)), 28), 28)
let subtitleFontSize = Math.min(keyStatFontSize - 4, 12)

return <View style={[commonStyles.containerWithBorder, { width: isWeb ? Layout.window.width / 2: Layout.window.width - 64, margin: 8 }]}>
    <SectionKeyStatSubtitle style={{ fontFamily: "Inter-SemiBold", fontSize: 16, marginBottom: -16, color: Colors.lightColor }}>
    {label}
    </SectionKeyStatSubtitle>
    <SectionKeyStat
    style={{
        marginBottom: -20, textAlign: "center", fontSize: keyStatFontSize
    }}
    >
        <AnimateNumber 
            value={Math.round(percentChange*10)/10} 
            countBy={10}
            formatter={(val) => {
                return `${val > 0 ? '':''}${parseFloat(val).toFixed(0)}`
            }}
        />
    </SectionKeyStat>
    <SectionKeyStatSubtitle style={{ fontSize: subtitleFontSize }}>
    {units}
    </SectionKeyStatSubtitle>
</View>
}


export default function ReviewReport(props) {
    let userId = props.userId ?? "5c5616128b926624fe44191b"
    let attributeKeys = ['bedtime_start', 'bedtime_end', 'efficiency', 'duration']
    let todayDateString = props.endDate ?? moment().format("YYYY-MM-DD")
    let startDate = props.startDate ?? "2021-01-01"
    const [pressedShare, setPressedShare] = React.useState(false)
    const { data: userSleepData } = useSWR(['/api/attributeData', userId, attributeKeys, todayDateString], () => attributesApi.adminGetDataForAttributes(userId, attributeKeys, startDate, todayDateString).then((response) => {
        const { attributes } = response.data
        return attributes;
    }));
    const { data: routineStats } = useSWR(['/api/routineStats', userId, startDate, todayDateString], () => attributesApi.adminGetRoutineStats(userId, startDate, todayDateString).then((response) => {
        const data = response.data
        return data;
    }));

    const { data: membershipStats } = useSWR(['/api/membershipStats', userId, startDate, todayDateString], () => attributesApi.adminGetProgressStats(userId, startDate, todayDateString).then((response) => {
        const data = response.data
        return data;
    }));
    const { data: profileStats } = useSWR(['/api/profileStats', userId, startDate, todayDateString], () => attributesApi.adminGetProfileStats(userId, startDate, todayDateString).then((response) => {
        const data = response.data
        return data;
    }));
    let sleepScheduleData = userSleepData?.filter(x => x.id == 'bedtime_start' || x.id == 'bedtime_end') ?? []
    let asleepDurationData = userSleepData?.filter(x => x.id == 'duration').pop()?.data ?? []
    let sleepEfficiencyData = userSleepData?.filter(x => x.id == 'efficiency').pop()?.data ?? []
    let durationValues = asleepDurationData?.map((x => x.value)) ?? []
    let shortestDuration = durationValues.length > 0 ? Math.min(...durationValues): 0
    let shortestDurationIndex = durationValues.length > 0 ? durationValues.indexOf(shortestDuration): 0
    let longestDuration = durationValues.length > 0 ? Math.max(...durationValues): 0
    let longestDurationIndex = durationValues.length > 0 ? durationValues.indexOf(longestDuration): 0

    let dateFormat = isWeb ? "ddd, MMMM DD": "ddd, MM DD"
    let shortestDurationDate = asleepDurationData.length > 0 ? moment.utc(asleepDurationData[shortestDurationIndex]?.date).format(dateFormat): '-'
    let longestDurationDate =  asleepDurationData.length > 0 ? moment.utc(asleepDurationData[longestDurationIndex]?.date).format(dateFormat): '-'
    let shortestDurationDateString =  asleepDurationData.length > 0 ? moment.utc(asleepDurationData[shortestDurationIndex]?.date).format("YYYY-MM-DD"): '-'
    let longestDurationDateString =  asleepDurationData.length > 0 ? moment.utc(asleepDurationData[longestDurationIndex]?.date).format("YYYY-MM-DD"): '-'
    let totalAsleep = durationValues.length > 0 ? Math.round(sum(durationValues)): 0
    let efficiencyValues = sleepEfficiencyData?.map((x => x.value)) ?? []
    let numberOfEfficientNights = efficiencyValues?.filter((x) => x > 85).length ?? 0
    let averageEfficiency = efficiencyValues.length > 0 ? Math.round(totalAverage(efficiencyValues)) > 100 ? 100: Math.round(totalAverage(efficiencyValues)): '-'
    let movieExample = movieExamples[Math.floor(Math.random() * movieExamples.length)]
    let numberOfNights = durationValues.length ?? 0
    let sleepStagePercentages = membershipStats?.sleepStagePercentages ?? null
    let membershipMonths = membershipStats?.numberOfMonths ?? 0
    let improvementPercentage = membershipStats?.improvementPercentage ?? 0
    let totalRoutinesCompleted = routineStats?.totalRoutinesCompleted ?? 0
    // let totalAsleep = membershipStats?.totalHoursAsleep ?? 0
    let topInsights = membershipStats?.topInsights ?? []
    let topInsightMetricIds = topInsights.map(x => x.metric.value)
    let numberOfTrendsWithInsights = 0
    let topMetrics = membershipStats?.topMetrics?.slice(0, 3).map((metric) => {
        if (topInsightMetricIds.includes(metric.value)) {
            let index = topInsights.findIndex((x) => x.metric.value == metric.value)
            let insight = topInsights.splice(index, 1).pop()
            numberOfTrendsWithInsights += 1
            return {
                ...metric,
                insight
            }
        }
        return {
            ...metric
        }
    }) ?? []
    let numberOfInsightsToShow = Math.max(3 - numberOfTrendsWithInsights, 0)
    let topGoals = membershipStats?.topGoals ?? []


    let noMembershipProgress = membershipStats?.noMembershipProgress
    let sleepConsistencyPercentile = membershipStats?.sleepConsistencyPercentile ?? "-%"
    let dominantStage = membershipStats?.dominantStage 
    let dominantStagePercentage = membershipStats?.dominantStagePercentage 
    let dominantStageCaption = dominantStage == 'REM' ? "You dream big": dominantStage == 'Deep'? "Rolling in the deep...": "You see the light"
    let chronotype = membershipStats?.chronotype ?? "-"
    let chronotypeCaption = `You're a ${chronotype.toLowerCase()}`
    let typicalBedtime = membershipStats?.typicalBedtime ?? "-"
    let typicalWakeupTime = membershipStats?.typicalWakeupTime ?? "-"
    let bedtimeConsistency = membershipStats?.bedtimeConsistency ?? "-"
    let wakeupConsistency = membershipStats?.wakeupConsistency ?? "-"
    let consistency = membershipStats?.bedtimeConsistency && membershipStats?.wakeupConsistency ? (Math.abs(bedtimeConsistency) + Math.abs(wakeupConsistency)) / 2: null;

    let percentAsleep = Math.round(100*totalAsleep/(24*numberOfNights) * 10)/10
    let dominantStageColor = dominantStage == 'REM' ? Colors.baselineBlueColor: dominantStage == 'Deep'? Colors.lightPurpleColor: Colors.lightBlueColor

    let sleepProfile = profileStats?.sleepProfile ?? membershipStats?.sleepProfile ?? "Adventurer"
    let sleepProfileCaption = profileStats?.sleepProfileCaption ?? membershipStats?.sleepProfileCaption ?? `You're among the first to join our beta.\nWelcome to the Bioloop community - we're excited to have you as a new member this ${period}. `
    let sleepProfileAvatarUrl = profileStats?.sleepProfileAvatarUrl ?? membershipStats?.sleepProfileAvatarUrl ??`https://s3-us-west-1.amazonaws.com/bioloop.io/adventurer_avatar.png`
    let sleepProfileColors = profileStats?.sleepProfileColors ?? membershipStats?.sleepProfileColors ?? [Colors.orangeColor, Colors.shadowColor, Colors.violetColor]
    let sleepProfileCaptionColor = profileStats?.sleepProfileCaptionColor ?? "#E2DBFF"

    let uniqueRoutinesCount = routineStats?.uniqueRoutinesCount ?? 0
    let topRoutines = routineStats?.topRoutines ?? []
    let topActivities = routineStats?.topActivities ?? []
    let topHabits = routineStats?.topHabits?.filter(x => x.count != 0) ?? []
    let totalHabitsCompleted = topHabits.map((x) => x.completedCount).reduce((a, b) => a + b, 0) ?? 0

    let numDaysInPeriod = moment(todayDateString).diff(moment(startDate), 'days')
    let period = numDaysInPeriod <= 8 ? "week": numDaysInPeriod <= 31 ? "month": "year"

    // if (true) {
    //     return <View>
    //         <MonthHeatmapChart data={[]} minDate={"2022-08-01"} maxDate={"2022-08-13"}/>
    //     </View>

    // }

    console.log("bedtimeConsistency", bedtimeConsistency)
    let sections = [
        {
            title: "Highlights",
            background: () => {
                return (<Image resizeMode='cover' style={[{
                    left: isWeb ? "-15%": -24, bottom: isWeb ? -200: -48, width: isWeb ? Layout.window.width * 0.7: Layout.window.width + 24, aspectRatio: 1, height: isWeb ? 240: 100, position: "absolute", zIndex: -1, opacity: 0.7
                }]} source={upward_waves} />)
            },
            render: () => <SectionCard style={{ alignItems: "center", justifyContent: "center", paddingBottom: 48 }}>
                 <Text style={tw.style('text-white p-2 font-medium', {
                fontFamily: 'Inter-SemiBold', fontSize: isWeb ? 20: 14, textAlign: isWeb ? 'center': 'left'
                })}>
                    {`YOUR PROGRESS ${noMembershipProgress ? "":"AS A MEMBER "}THIS ${period.toUpperCase()}`}
                </Text>
                <CircleChart score={numberOfNights} totalScore={numDaysInPeriod}/>
                <SectionKeyStatSubtitle style={tw.style('text-white p-2 font-medium', {
                    fontFamily: 'SpeziaNarrow-SemiBold', fontSize: isWeb ? 16: 12, textAlign: isWeb ? 'center': 'left'
                })}>
                    {noMembershipProgress ? ``: null}
                </SectionKeyStatSubtitle>
                <View style={{
                    flexDirection: "row", flexWrap: "wrap", textAlign: "center", justifyContent: "center", alignItems: "center"
                }}>
                    {totalHabitsCompleted != null ? <View>
                        <SectionKeyStat color={Colors.lavenderColor} style={{ marginBottom: -16, textAlign: "center", fontSize: isWeb ? 28: 28 }}>
                            <AnimateNumber value={totalHabitsCompleted} countBy={1}/>
                        </SectionKeyStat>
                        <SectionKeyStatSubtitle style={{ fontSize: 14 }}>
                            habits completed
                        </SectionKeyStatSubtitle>
                    </View>: null}
                    {totalRoutinesCompleted != null ? <View>
                        <SectionKeyStat style={{ marginBottom: -16, textAlign: "center", fontSize: isWeb ? 28: 28 }}>
                            <AnimateNumber value={totalRoutinesCompleted} countBy={1}/>
                        </SectionKeyStat>
                        <SectionKeyStatSubtitle style={{ fontSize: 14 }}>
                            routines completed
                        </SectionKeyStatSubtitle>
                    </View>: null}
                    {improvementPercentage ? <View>
                        <SectionKeyStat color={Colors.positiveGreenColor} style={{ marginBottom: -16, textAlign: "center", fontSize: isWeb ? 28: 28 }}>
                            <AnimateNumber value={improvementPercentage} countBy={1} formatter={(val) => {
                                return '+' + parseFloat(val).toFixed(0) + ''
                            }}/>
                        </SectionKeyStat>
                        <SectionKeyStatSubtitle style={{ fontSize: 14 }}>
                            improvement score
                        </SectionKeyStatSubtitle>
                    </View>: null}
                </View>
            </SectionCard>
        },
        {
            title: "Top trends & insights",
            render: () => <SectionCard style={{ zIndex: 1 }}>
                {/* <SectionKeyStat style={{ textAlign: isWeb ? "center": "left", fontSize: isWeb ? 48: 32, fontFamily: "SpeziaExtended-SemiBold" }}>
                    {chronotypeCaption}
                </SectionKeyStat> */}
                 {topMetrics.length > 0 ? <Text style={tw.style('text-white p-2 font-medium', {
                    fontFamily: 'Inter-SemiBold', fontSize: isWeb ? 20: 14, textAlign: isWeb ? 'center': 'left'
                })}>
                TRENDS ACROSS YOUR SLEEP
                </Text>: null}
                    {/* Insert overview here */}
                {/* <SectionKeyStatSubtitle style={{ marginTop: -16, textAlign: isWeb ? "center": "left", fontSize: isWeb ? 20: 16 }}>
                </SectionKeyStatSubtitle> */}

                <View style={{ flexDirection: "row" }}>
                   
                </View>
             
                <View style={{
                    // flexDirection: "row", flexWrap: 'wrap', justifyContent: "center", alignItems: "center", alignSelf: "center"
                }}>
                    {topMetrics?.map(({ percentChange, label, color, insight, ...restProps }) => {
                        let keyStatFontSize = Math.max(Math.min(Math.round(Math.abs(percentChange/100*200)), 28), 16)
                        let subtitleFontSize = Math.min(keyStatFontSize-4, 12)

                        return <View style={{  
                            margin: isWeb ? 8: 0,
                            marginTop: 16,
                            padding: isWeb ? 16: 0,
                            border: 'solid',
                            borderColor: Colors.darkLightBlackColor,
                            borderWidth: isWeb ? 1: 0,
                            borderRadius: 8,
                        }}>
                            {period == 'week' ?
                                <TrendsChart
                                metric={{ percentChange, label, color, ...restProps }}
                                graphColor={color}
                                //   onChartLoad={onChartLoad}
                                dateString={todayDateString}
                                />: <RollingTrendChart
                                    metric={{ percentChange, label, color, ...restProps }}
                                    graphColor={color}
                                    dateString={todayDateString}
                                />
                            }
                            {insight && insight?.impactors.length > 0 ? <View style={{ marginTop: 12 }}>
                                <SectionKeyStatSubtitle style={{ fontFamily: 'SpeziaNarrow-SemiBold', color: Colors.lightColor, fontSize: 10, textAlign: "left", marginBottom: -12 }}>
                                    {"Key factors".toUpperCase()}
                                </SectionKeyStatSubtitle>
                                <Text
                                    style={[
                                        {
                                        fontFamily: "Spezia-Regular",
                                        color: Colors.lightBlueColor,
                                        margin: 8,
                                        fontSize: 10,
                                        textAlign: 'left',
                                        },
                                    ]}>
                                    {insight.goalLabel}
                                </Text>
                                <View style={{
                                    padding: 8
                                }}>

                                {insight?.impactors?.map((impactor) => <HabitImpactCell {...impactor}/>)}
                                </View>
                            </View>:  <View style={{ marginTop: 12 }}>
                                <SectionKeyStatSubtitle style={{ fontFamily: 'SpeziaNarrow-SemiBold', color: Colors.lightColor, fontSize: 10, textAlign: "left", marginBottom: -12 }}>
                                    {"Key factors".toUpperCase()}
                                </SectionKeyStatSubtitle>
                                <Text
                                    style={tw.style('text-white p-2 font-medium', { fontFamily: 'Spezia-Regular', fontSize: 10, textAlign: isWeb ? 'center': 'left', color: Colors.lightGrayColor, })}>
                                    {"No data available. You can unlock more insights by tracking more habits and routines related to this goal in the Crescent app."}
                                </Text>
                            </View>}
                        </View>
                    })}
                </View>
                <View style={{
                    margin: isWeb ? 8: 0,
                    padding: isWeb ? 16: 0,
                    border: 'solid',
                    borderColor: Colors.darkLightBlackColor,
                    borderWidth: isWeb ? 1: 0,
                    borderRadius: 8,
                }}>
                {(topInsights.length > 0 && numberOfInsightsToShow > 0) ? <Text style={tw.style('text-white p-2 font-medium', {
                    fontFamily: 'Inter-SemiBold', fontSize: isWeb ? 20: 14, textAlign: isWeb ? 'center': 'left',
                    marginTop: 12
                })}>
                    {`${topMetrics == 0? '':'OTHER '}KEY FACTORS`}
                </Text>: null}
                {numberOfInsightsToShow > 0 ? topInsights.slice(0, numberOfInsightsToShow).map(({ goalLabel, metric, impactors }) => {
                    if (impactors.length == 0) {
                        return null
                    }
                    return ( <View style={{  }}>
                        <SectionKeyStatSubtitle style={{ fontFamily: 'Teodor-Light', fontSize: 24, textAlign: "left", marginBottom: -12 }}>
                            {metric.label}
                        </SectionKeyStatSubtitle>
                        <Text
                            style={[
                                {
                                fontFamily: "Spezia-Regular",
                                color: Colors.lightGrayColor,
                                margin: 8,
                                fontSize: 10,
                                textAlign: 'left',
                                },
                            ]}>
                            {goalLabel}
                        </Text>
                         <View style={{
                            padding: 8,
                        }}>
                        {impactors.map((impactor) => <HabitImpactCell {...impactor}/>)}
                        </View>
                    </View>
                    )
                }): null}
                {topMetrics == 0 && topInsights.filter((x => x.impactors?.length > 0)).length == 0 ? <Text style={tw.style('text-white p-2 font-medium', {
                    fontFamily: 'Spezia-Regular', fontSize: isWeb ? 20: 14, textAlign: isWeb ? 'center': 'left', color: Colors.lightGrayColor
                })}>
                    {`No data available.\nPlease check that your devices are syncing.`}
                </Text>: null}
                <SectionKeyStatSubtitle style={{ fontFamily: 'Spezia-Regular', fontSize: 12, textAlign: "left", marginBottom: -12, color: Colors.secondaryColor }}>
                    <Icon name="lock" size={12} color={Colors.secondaryColor} />
                    {` You can unlock more insights and trends by completing your programs and routines in the app.`}
                </SectionKeyStatSubtitle>
                </View>
            </SectionCard>
        },
        {
            title: "Sleep schedule",
            render: () => <SectionCard style={{ zIndex: 1}}>
                {/* <SectionKeyStat style={{ textAlign: isWeb ? "center": "left", fontSize: isWeb ? 48: 32, fontFamily: "SpeziaExtended-SemiBold" }}>
                    {chronotypeCaption}
                </SectionKeyStat> */}
                {(numDaysInPeriod >= 28) ? (<><SectionKeyStatSubtitle style={{ textAlign: isWeb ? "center": "left", fontSize: isWeb ? 20: 16 }}>
                    {"You typically went to bed around "}
                    <Icon name="moon" size={isWeb ? 20: 16} color={Colors.lightPurpleColor} />
                    <Text style={{ color: Colors.lightPurpleColor, fontFamily: 'Inter-Heavy' }}>
                        {`${typicalBedtime}`}
                    </Text>
                </SectionKeyStatSubtitle>
                <SectionKeyStatSubtitle style={{ marginTop: -16, textAlign: isWeb ? "center": "left", fontSize: isWeb ? 20: 16 }}>
                    {"and woke up around "}
                    <Icon name="sunrise" size={isWeb ? 20: 16} color={Colors.orangeColor} />
                    <Text style={{ color: Colors.orangeColor, fontFamily: 'Inter-Heavy' }}>
                        {`${typicalWakeupTime}`}
                    </Text>
                </SectionKeyStatSubtitle></>): <Text style={tw.style('text-white p-2 font-medium', {
                    fontFamily: 'Inter-SemiBold', fontSize: isWeb ? 20: 14, textAlign: isWeb ? 'center': 'left'
                })}>
                    {"SLEEP CONSISTENCY".toUpperCase()}
                </Text>}
                <View style={{ marginVertical: isWeb ? 20: 0, marginHorizontal: 4 }}>
                    <Text
                    style={{
                        fontFamily: 'Teodor-Light',
                        fontSize: 32,
                        textAlign: isWeb ? 'center': 'left',
                        color: Colors.whiteColor,
                        marginBottom: 16,
                    }}>
                    {consistency != null
                        ? `${classifyConsistency(consistency)} consistency`
                        : 'No consistency available'}
                    </Text>
                    <BenchmarkProgressBar
                        score={Math.round(consistency * 60)}
                        currentIndex={consistency == null ? null:interpretConsistency(consistency)}
                    />
                </View>
                {(numDaysInPeriod >= 28) ? <SleepScheduleHistogramChart data={sleepScheduleData} />: numDaysInPeriod <= 8 ? 
                <SleepScheduleView 
                    userId={userId}
                    data={sleepScheduleData}
                    dateString={todayDateString}
                />: null}
                <View style={{ flexDirection: "row" }}>
                    <SectionKeyStatSubtitle style={{ width: "50%", fontSize: isWeb ? 14: 12, textAlign: isWeb ? "center": "left" }}>
                        <Icon name="moon" size={14} color={Colors.lightPurpleColor} />
                        <Text style={{ color: Colors.lightPurpleColor}}>
                            {` Your bedtime typically varied by ${bedtimeConsistency == null || bedtimeConsistency == '-' ? '-': bedtimeConsistency > 1 ? `${Math.floor(bedtimeConsistency)}${Math.floor(bedtimeConsistency) > 1 ? 'hrs': 'hr'}`: ''}${bedtimeConsistency%1*60 != 0 ? `${(bedtimeConsistency%1*60).toFixed(0)}${(bedtimeConsistency%1*60)<2 ? "min": 'mins'}`:''}`}
                        </Text>
                    </SectionKeyStatSubtitle>
                    <SectionKeyStatSubtitle style={{ width: "50%", fontSize: isWeb ? 14: 12, textAlign: isWeb ? "center": "left" }}>
                        <Icon name="sunrise" size={14} color={Colors.orangeColor} />
                        <Text style={{ color: Colors.orangeColor}}>
                            {` Your wakeup time typically varied by ${wakeupConsistency == null || wakeupConsistency == '-' ? '-': wakeupConsistency >= 1 ? `${Math.floor(wakeupConsistency)}${Math.floor(wakeupConsistency) > 1 ? 'hrs': 'hr'}`: ''}${wakeupConsistency%1*60 != 0 ? `${(wakeupConsistency%1*60).toFixed(0)}${(wakeupConsistency%1*60)<2 ? "min": 'mins'}`:''}`}
                        </Text>
                    </SectionKeyStatSubtitle>
                </View>
                <SectionKeyStatSubtitle style={{ fontSize: 14, textAlign: isWeb ? "center": "left" }}>
                    {"Your overall sleep consistency is in the "}
                    <Icon name="award" size={14} color={Colors.positiveGreenColor} />
                    <Text style={{ color: Colors.positiveGreenColor, fontFamily: 'Inter-Heavy' }}>
                        {` Top ${sleepConsistencyPercentile} `}
                    </Text>
                    {"of the Crescent community."}
                </SectionKeyStatSubtitle>
            </SectionCard>
        },
        // {
        //     title: "Your total tracked sleep time",
        //     background: () => {
        //         return (<Image resizeMode='contain' style={[{
        //             left: -200, top: -128, width: 480, height: 460, position: "absolute", zIndex: 0, opacity: 0.7
        //         }]} source={planet_ring} />)
        //     },
        //     render: () => <SectionCard>
        //         <PieChart width={300} percent={percentAsleep}/>
        //         <SectionKeyStat style={{ position: "absolute", width: "50%", left: "25%", top: isWeb ? 100: "22%", fontSize: isWeb ? 64: 48 }}>
        //             <AnimateNumber value={totalAsleep} countBy={100}/>
        //         <SectionKeyStatSubtitle style={{ fontSize: 16 }}>
        //             hrs tracked
        //         </SectionKeyStatSubtitle>
        //     </SectionKeyStat>
        //         <SectionKeyStatSubtitle style={{ marginTop: 8, textAlign: isWeb ? "center": "left" }}>
        //             {`That's like watching ${movieExample.name}`}
        //             <Text style={{ color: Colors.lightBlueColor, fontFamily: 'Teodor-Light', fontSize: 24 }}>
        //             {` ${Math.floor(totalAsleep/movieExample.duration)}`} 
        //             </Text>
        //             {` times,`}
        //         </SectionKeyStatSubtitle>
        //          <SectionKeyStatSubtitle style={{ textAlign: isWeb ? "center": "left" }}>
        //             {`or spending about `}
        //             <Text style={{ color: Colors.lightBlueColor, fontFamily: 'Teodor-Light', fontSize: 24 }}>
        //             {`${(totalAsleep/24).toFixed(1)}`}
        //             </Text>
        //             {` days asleep.`}
        //         </SectionKeyStatSubtitle>
        //         {/* That's like watching 900 times */}
        //     </SectionCard>
        // },
        // sleepStagePercentages ? {
        //     title: "Your typical sleep breakdown",
        //     background: () => {
        //         return (<Image resizeMode='contain' style={[{
        //             right: -80, bottom: -80, width: 280, height: 260, position: "absolute", zIndex: 0, opacity: 0.7
        //         }]} source={curly_ball} />)
        //     },
        //     render: () => <SectionCard>
        //         <HighlightedPieChart width={300} 
        //             data={sleepStagePercentages}
        //             percent={percentAsleep} 
        //             highlightedArcPercent={percentAsleep}

        //         />
        //         <SectionKeyStat style={{ marginTop: isWeb ? -24: -48, color: dominantStageColor, fontSize: isWeb ? 48: 32, textAlign: isWeb ? "center": "left", fontFamily: "SpeziaExtended-SemiBold" }}>
        //             {dominantStageCaption}
        //         </SectionKeyStat>
        //         <SectionKeyStatSubtitle style={{ marginTop: -12, textAlign: isWeb ? "center": "left" }}>
        //             {`You spend about `}
        //             <Text style={{ color: dominantStageColor, fontFamily: "Teodor-Light", fontSize: 36 }}>
        //                 {`${dominantStagePercentage.toFixed(1)}%`}
        //             </Text>
        //             {` of your sleep in `}
        //             <Text style={{ color: dominantStageColor }}>
        //             {`${dominantStage} sleep`}
        //             </Text>
        //         </SectionKeyStatSubtitle>
        //         <View style={{ marginTop: isWeb ? 48: 12, flexDirection: isWeb ? "row": "column", alignItems: 'center', justifyContent: 'center', marginBottom: 24 }}>
        //             <View style={{ width: "25%"}}>
        //                 <StackedBarChart data={sleepStagePercentages}/>
        //             </View>
        //             <View style={{ width: isWeb ? "50%": "100%", marginTop: isWeb ? 0: 24 }}>
        //                 <SectionKeyStatSubtitle style={{ marginBottom: -16, color: Colors.lavenderColor, fontFamily: "SpeziaExtended-SemiBold", textAlign: isWeb ? "center":"left" }}>
        //                     You 
        //                     <Text style={{ marginBottom: -16, color: Colors.darkLightColor, fontFamily: "Spezia-Regular" }}>
        //                         {" vs "}
        //                     </Text>
        //                     <Text style={{ marginBottom: -16, color: Colors.lightBlueColor, fontFamily: "Spezia-Regular" }}>
        //                         {"the rest of the Crescent community"}
        //                     </Text>
        //                     <Text style={{ marginBottom: -16, color: Colors.darkLightColor, fontFamily: "Spezia-Regular" }}>
        //                         {" on"}
        //                     </Text>
        //                     <Text style={{ marginBottom: -16, color: dominantStageColor, fontFamily: "Spezia-Regular" }}>
        //                         {` ${dominantStage} sleep`}
        //                     </Text>
        //                 </SectionKeyStatSubtitle>
        //                 <PopulationBarChart width={Layout.window.width/4}/>
        //             </View>
        //         </View>
        //     </SectionCard>
        // }: null,
        // {
        //     title: "You vs the rest of the community:",
        //     render: () => <SectionCard style={{ alignItems: "center", justifyContent: "center", flexDirection: "row" }}>
        //     </SectionCard>
        // },
       period != 'week' ? {
            title: "Sleep history ",
            render: () => <SectionCard>
                {numberOfEfficientNights && numberOfEfficientNights > 0 ? <SectionKeyStat style={{ color: Colors.positiveGreenColor, textAlign: "center"}}>
                    <AnimateNumber value={numberOfEfficientNights} countBy={1}/>
                </SectionKeyStat>: null}
                {numberOfEfficientNights && numberOfEfficientNights > 0 ?<SectionKeyStatSubtitle style={{ marginTop: -16, marginBottom: 32 }}>
                    days with 85%+ efficiency
                </SectionKeyStatSubtitle>: 0}
                {sleepEfficiencyData && numberOfEfficientNights && <MonthHeatmapChart data={sleepEfficiencyData} minDate={shortestDurationDateString} maxDate={longestDurationDateString}/>}
                <View style={{ flexDirection: "row", marginBottom: 16, marginTop: 32 }}>
                    <View style={{ width: "33%"}}>
                        <SectionKeyStatSubtitle style={{ fontSize: 14, marginBottom: -16, color: Colors.secondaryColor, textAlign: isWeb ? "center": "left" }}>
                        {/* {"Mon, June 20"} */}
                        {shortestDurationDate}
                        </SectionKeyStatSubtitle>
                        <SectionKeyStat style={{ color: Colors.secondaryColor, fontSize: isWeb ? 48: 28 }}>
                            {shortestDuration.toFixed(1)}
                            <SectionKeyStatSubtitle style={{ color: Colors.secondaryColor }}>
                            hrs
                            </SectionKeyStatSubtitle>
                        </SectionKeyStat>
                        <SectionKeyStatSubtitle style={{ fontSize: 14, marginTop: -16 }}>
                        {"Shortest night"}
                        </SectionKeyStatSubtitle>
                    </View>
                    <View style={{ width: "33%", alignItems: 'center', justifyContent: 'center'}}>
                        <SectionKeyStatSubtitle style={{ fontSize: isWeb ? 14: 14, marginBottom: -12, color: Colors.brightTintColor }}>
                        {"Typical night"}
                        </SectionKeyStatSubtitle>
                        <SleepScore text={averageEfficiency} />
                        <SectionKeyStatSubtitle style={{ fontSize: 14, marginTop: 8, marginBottom: 0 }}>
                            Your sleep efficiency
                        </SectionKeyStatSubtitle>
                    </View>
                     <View style={{ width: "33%"}}>
                        <SectionKeyStatSubtitle style={{ fontSize: 14, marginBottom: -16, color: Colors.shadowColor, textAlign: isWeb ? "center": "left"}}>
                        {/* {"Tue, August 28"} */}
                        {longestDurationDate}
                        </SectionKeyStatSubtitle>
                        <SectionKeyStat style={{ 
                            fontSize: isWeb ? 48: 28
                        }}>
                            {longestDuration.toFixed(1)}
                            <SectionKeyStatSubtitle style={{ color: Colors.shadowColor }}>
                            hrs
                            </SectionKeyStatSubtitle>
                        </SectionKeyStat>
                        <SectionKeyStatSubtitle style={{ fontSize: 14, marginTop: -16 }}>
                        {"Longest night"}
                        </SectionKeyStatSubtitle>
                    </View>
                </View>
            </SectionCard>
        }: null,
        (topRoutines.length > 0 || topRoutines.length > 0) ? {
            title: "Top routines, habits & activities",
            render: () => <SectionCard
                style={{
                    alignItems: "center", justifyContent: "center", paddingBottom: 48
                }}
            >
                {uniqueRoutinesCount ? <View>
                <SectionKeyStatSubtitle style={{ textAlign: isWeb ? "center": "left", fontFamily: "SpeziaExtended-SemiBold" }}>
                    {"You tried "}
                    <Text style={{ color: Colors.lavenderColor, fontFamily: "Teodor-Light", fontSize: isWeb ? 28: 28 }}>
                        <AnimateNumber value={uniqueRoutinesCount} countBy={1}/>
                    </Text>
                    {` new routines from your coach this ${period}`}
                </SectionKeyStatSubtitle>
                {topRoutines.length > 0 ? <Text style={tw.style('text-white p-2 font-medium', {
                    marginTop: 24,
                    fontFamily: 'SpeziaNarrow-SemiBold', fontSize: isWeb ? 20: 12, textAlign: isWeb ? 'center': 'left'
                })}>
                    YOUR FAVORITE ROUTINES
                </Text>: null}
                {topRoutines.length > 0 ? <View style={{
                    flexDirection: "column", flexWrap: 'wrap', justifyContent: "center", alignItems: "center", alignSelf: "center", marginBottom: 32
                }}>
                    {topRoutines?.map(({ label, duration, completedCount, count }) => {
                        let units = "times"
                        let percentChange = completedCount
                        let keyStatFontSize = 24 ?? Math.max(Math.min(Math.round(Math.abs(percentChange/1000*100)), 28), 24)
                        let subtitleFontSize = Math.min(keyStatFontSize-4, 12)

                        console.log("percentChange/100*500", percentChange/100*500)
                        console.log("keyStatFontSize", keyStatFontSize)
                        return <View style={[commonStyles.containerWithBorder, { width: isWeb ? Layout.window.width / 2: Layout.window.width - 64, margin: 8 }]}>
                            <SectionKeyStatSubtitle style={{ fontFamily: "Inter-SemiBold", fontSize: 16, color: Colors.whiteColor, marginBottom: -16 }}>
                                {label}
                            </SectionKeyStatSubtitle>
                            <SectionKeyStat color={Colors.positiveGreenColor} 
                                style={{ 
                                    marginBottom: -20, textAlign: "center", fontSize: keyStatFontSize
                                }}
                            >
                                <AnimateNumber 
                                    value={Math.round(percentChange*10)/10} 
                                    countBy={10}
                                    formatter={(val) => {
                                        return `${val > 0 ? '':''}${parseFloat(val).toFixed(0)}`
                                    }}
                                />
                                /
                                <AnimateNumber 
                                    value={Math.round(count*10)/10} 
                                    countBy={10}
                                    formatter={(val) => {
                                        return `${val > 0 ? '':''}${parseFloat(val).toFixed(0)}`
                                    }}
                                />
                            </SectionKeyStat>
                            <SectionKeyStatSubtitle style={{ fontSize: subtitleFontSize, color: Colors.whiteColor }}>
                                {units}
                            </SectionKeyStatSubtitle>
                        </View>
                    })}
                </View>
                : null}
                {topActivities.length > 0 ? <Text style={tw.style('text-white p-2 font-medium', {
                    fontFamily: 'SpeziaNarrow-SemiBold', fontSize: isWeb ? 20: 12, textAlign: isWeb ? 'center': 'left'
                })}>
                    YOUR TOP ACTIVITIES
                </Text>: null}
                {topActivities.length > 0 ? <View style={{
                    flexDirection: "column", flexWrap: 'wrap', justifyContent: "center", alignItems: "center", alignSelf: "center", marginBottom: 32
                }}>
                    {topActivities?.map(renderActivityStat)}
                </View>: null}
                {topActivities.length > 0 ? <Text style={tw.style('text-white p-2 font-medium', {
                    fontFamily: 'SpeziaNarrow-SemiBold', fontSize: isWeb ? 20: 12, textAlign: isWeb ? 'center': 'left'
                })}>
                    YOUR TOP HABITS
                </Text>: null}
                {topHabits.length > 0 ? <View style={{
                    flexDirection: "column", flexWrap: 'wrap', justifyContent: "center", alignItems: "center", alignSelf: "center"
                }}>
                    {topHabits?.map(renderHabitStat)}
                </View>: null}

                {/* <RoutineImpactView activeHabits={topHabits}/> */}
            </View>: null}
            </SectionCard>
        }: null,
        // routineStats?.topHabits ? {
        //     title: "Your top activities & habits",
        //     render: () => <SectionCard/>
        // }: null,
    ].filter(x => x)
    const userName = routineStats?.userName ?? ""

    let pageTitle = `${userName}'s ${period}ly report for ${moment(startDate).format("MMMM")}`
    let pageDescription = `Check out ${userName}'s progress as a Crescent member`

    let reportDateRange = moment(startDate).month() != moment(todayDateString).month() ? `${moment(startDate).format("MMM DD")} - ${moment(todayDateString).format("MMM DD, YYYY")}`: `${moment(startDate).format("MMM DD")} - ${moment(todayDateString).format("DD, YYYY")}`
    return <View style={{ marginTop: isWeb ? 0: 48, flex: 1 }}>
        <Helmet>
            <title>
                  {`${pageTitle}`} | Crescent
            </title>
            <meta property="og:title" content={`${pageTitle} | Crescent`} />
            <meta name="description" property="description" content={pageDescription}/>
            <meta name="description" property="og:description" content={pageDescription} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content={'https://crescent.co'} />
            <meta name="twitter:title" content={pageTitle} />
            <meta name="twitter:description" content={'Personalized sleep health, energy and wellbeing programs & insights.'} />
            <meta name="twitter:image" content={"https://s3.us-west-1.amazonaws.com/bioloop.io/crescent_banner.png"} />
            <meta property="og:url" content={'https://crescent.co'} />
            <meta property="og:image" content='https://s3.us-west-1.amazonaws.com/bioloop.io/crescent_banner.png' />

        </Helmet>
        <LinearGradient colors={[Colors.darkBlackColor, Colors.darkBlackColor]} start={{ x: 0, y: 0 }} end={{ x: 0, y: 1 }} style={{
        padding: 16, width: isWeb ? Layout.window.width * 6/10: Layout.window.width,
        paddingVertical: 64, overflow: "hidden", marginBottom: 64
        }}>
            {props.hideReportTitle ? null:
            <Image source={bioloop_logo} resizeMode={'contain'} style={{
                alignSelf: "flex-start",
                position:"absolute",
                top: 20,
                left: 12,
                width: Math.min(Layout.window.width * 0.33, 160), height: 60
            }}>
            </Image>}
            {props.hideReportTitle ? null: <Animatable.View animation="fadeIn" 
                style={{ marginTop: isWeb ? -24: 0, zIndex: 1, flexDirection: "row", alignItems: "center", justifyContent: 'center'}}>
                <View style={{ flex: 1, }}>
                     <Animatable.Text animation="fadeIn" style={tw.style('text-white font-medium', {
                        fontFamily: 'Inter-SemiBold', fontSize: 24, textAlign: 'right'
                    })}>
                        <Text style={tw.style('text-white font-medium', {
                        fontFamily: 'Inter-SemiBold', fontSize: 24, textAlign: 'right'
                        })}>{routineStats ? `${userName}'s ${period}ly report`: ''}</Text>
                    </Animatable.Text>
                    <Animatable.Text animation="fadeIn" style={tw.style('text-white font-medium', {
                        fontFamily: "SpeziaExtended-Medium", color: Colors.lightColor,
                        fontSize: isWeb ? 24: 16, textAlign: 'right'
                    })}>
                        {reportDateRange}
                    </Animatable.Text>
                </View>
            </Animatable.View>}
            {/* <Animatable.View animation="fadeIn" style={[styles.moonContainer, { zIndex: -1 }]} /> */}
            {/* <LottieView
                style={{ position: "absolute", left: "33%", width: "33%", top: -8, alignSelf: "center", opacity: 0.5, zIndex: -1 }}
                source={require('../../assets/animations/dna_loader.json')}
                autoPlay
                speed={1.0}
                loop={true}
            /> */}
            {(!userSleepData || !routineStats || !membershipStats) ? null: <View style={{ alignItems: "center", justifyContent: "center", paddingBottom: 0 }}>
                {/* <GradientText colors={sleepProfileColors} style={tw.style('text-white p-2 font-medium', {
                    fontFamily: "SpeziaExtended-SemiBold", color: Colors.whiteColor,
                    fontSize: isWeb ? 48: 36, textAlign: 'center'
                    })}>
                        {sleepProfile}
                </GradientText>
                <LinearGradient colors={sleepProfileColors} start={{ x: 0, y: 0 }} end={{ x: 1, y: 0 }} style={{ paddingHorizontal: 16, padding: 32, borderRadius: 4, borderWidth: 2, borderColor: Colors.lightBlueColor, width: 240, height: 240, justifyContent: "center", alignItems: "center"
                }}>
                    {sleepProfileAvatarUrl ? <View style={{
                    }}>
                        <Image resizeMode='contain' style={[{
                            width: 200, height: 200, zIndex: 0, opacity: 1
                        }]} source={{ uri: sleepProfileAvatarUrl }} />
                    </View>: null}
                </LinearGradient>
                 <SectionKeyStatSubtitle style={tw.style('text-white p-2 font-medium', {
                    fontFamily: 'Optima-Medium', fontSize: isWeb ? 20: 18, textAlign: isWeb ? 'center': 'center', marginTop: 8, color: sleepProfileCaptionColor 
                })}>
                    {sleepProfileCaption}
                </SectionKeyStatSubtitle> */}
            </View>}
            {(!userSleepData || !routineStats || !membershipStats) ? <View style={[{ height: Layout.window.height, ...shadowOptions(), backgroundColor: Colors.darkBlackColor, marginHorizontal: 0, marginTop: 48,  }]}>
                <LottieView source={require('../../assets/animations/blue-loading-circle.json')} autoPlay loop style={{
                    width: isWeb ? '25%': '50%', alignSelf: "center", opacity: 0.5
                }} />
                <Animatable.Text animation="fadeIn" style={tw.style('text-white font-medium', {
                    fontFamily: 'Spezia-Regular', fontSize: 24, textAlign: 'center', marginTop: isWeb ? 72: 48
                })}>
                    Loading your report...
                </Animatable.Text>
            </View>: sections.map((section, index) => {
                return <View style={{ marginTop: 24 }}>
                    <Text style={tw.style('text-white p-2 font-medium', {
                        fontFamily: 'Teodor-Light', color: Colors.whiteColor, fontSize: 36
                    })}>
                        {section.title}
                    </Text>
                    {index == 0 ? <View style={{ 
                        position: "absolute", right: 4, top: isWeb ? 24: 24
                    }}>
                        <TouchableOpacity style={{
                            // backgroundColor: Colors.shadowColor, padding: 8, width: "100%", paddingHorizontal: 16, borderRadius: 2
                        }}
                        onPress={() => {
                            Clipboard.setString(`https://report.crescent.co/?uid=${userId}&startDate=${startDate}&endDate=${todayDateString}`);
                            setPressedShare(true)
                        }}
                    >
                        <Text style={{ fontFamily: "SpeziaExtended-SemiBold", textAlign: "left", fontSize: isWeb ? 18: 14, color: Colors.shadowColor }}>
                            {`${pressedShare ? "Copied link!": "Share report"} `}
                            <Icon name="copy" color={Colors.shadowColor} size={isWeb ? 18:14} style={{ }} />
                        </Text>
                    </TouchableOpacity>
                    </View>: null}
                    {/* {section.background ? section.background(): null} */}
                    {section.render()}
                </View>
            })}
        {/* <Image resizeMode='contain' style={[{
            left: -240, bottom: 64, width: Layout.window.width/2, height: 120, position: "absolute", zIndex: -1, opacity: 0.7
        }]} source={flat_waves} /> */}
        </LinearGradient>
    </View>
}