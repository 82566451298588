import React, { useEffect } from 'react';
import {Text, StyleSheet, TouchableOpacity, ImageBackground, Image, Platform, View, ScrollView } from 'react-native';
// import {showModal} from 'react-native-navigation-hooks';
import useOnAppActivated from '../hooks/useOnAppActivated';
import tw from 'twrnc';
import {getTodaysWorkoutReport} from '../services/workout-service';
import Modal from './modal'
import Colors from '../constants/Colors';
import { Row, Col, Grid} from "react-native-easy-grid";
// import { } from 'native-base'
import Card from './Card'
import Layout, { is_iOS } from '../constants/Layout'

import Icon from 'react-native-vector-icons/Feather';
import { GradientText } from "./GradientText";
import ReviewReport from "./ReviewReport";
import { CommonActions } from '@react-navigation/native';

import backgroundGradient from '../../assets/background_gradient_3.png'

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    cardIcon: {
        alignSelf: 'center',
        justifyContent: 'center',
        alignItems: 'center',
    },
    cardText: {
        fontFamily: 'Optima-Medium',
        color: Colors.lightColor,
        marginTop: 4,
        fontSize: 16,
    },
    grid: {
        borderColor: '#000',
        borderRadius: 8,
        // width: '80%',
        marginLeft: 16,
        marginRight: 16,
    },
    gridContainer: {
        shadowOffset: {
            width: Platform.OS === "ios" ? 5 : 0,
            height: Platform.OS === "ios" ? 5 : 0,
        },
        width: '100%',
        padding: 0,
        paddingBottom: 16, 
        // shadowColor: '#768fff',
        // backgroundColor: '#000',
        // shadowOpacity: 0.1,
        // shadowRadius: 8,
        // elevation: Platform.OS === "android" ? 1 : undefined,
    },
    column: {
        height: 85,
        // left: '-35%',
        // width: '50%',        
        // marginLeft: '-10%'
    },
    reportTemplateCard: {
        margin: 8, 
        marginTop: 16, 
        marginLeft: 8, 
        padding: 16, 
        borderColor: Colors.darkTintColor, 
        borderRadius: 8, 
        borderWidth: 0, 
        paddingBottom: 32
    },
    header: {
        color: Colors.shadowColor,
        fontSize: 16,
        marginBottom: 16,
        textAlign: 'left',
        alignSelf: 'flex-start',
        fontFamily: 'SpeziaExtended-SemiBold',
    },

});

const customIconFilepath = {
    '': '',
    'apple-health': require('../../assets/integrations/apple_health.png'),
    'apple-health-disabled': require('../../assets/integrations/apple_health.png'),
    'fitbit': require('../../assets/integrations/fitbit.png'),
    'oura': require('../../assets/integrations/oura.png'),
    'oura-disabled': require('../../assets/integrations/oura.png'),
    'whoop': require('../../assets/integrations/whoop-red.png'),
    'whoop-disabled': require('../../assets/integrations/whoop-red.png'),
    'garmin-disabled': require('../../assets/integrations/garmin_white.png'),
    'garmin': require('../../assets/integrations/garmin_white.png'),
    'fitbit-disabled': require('../../assets/integrations/gray_fitbit.png'),
}

const styleCard = (enabled, callback) => {
    const onPress = callback
    const color = enabled ? Colors.tintColor : Colors.grayColor
    const iconStyle = { color }
    const cardStyle = {
        alignSelf: 'center',
        justifyContent: 'center',
        alignItems: 'center'
     }
    const enabledColor = enabled ? Colors.positiveGreenColor : Colors.redColor

    return { onPress, iconStyle, cardStyle, enabledColor }
}

function TemplateReportCard(props) {
  const { reportName, onPress } = props
  return <View style={[styles.reportTemplateCard]}>
    {/* <Text
      style={tw.style('text-white p-2 font-medium', {
        fontFamily: 'SpeziaExtended-SemiBold',
      })}>
      {reportName}
    </Text> */}
    <TemplateCard
        icon="chart"
        type="feather"
        title={reportName}
        callback={onPress}
    />
  </View>
}


export const IntegrationIcon = (props) => {
    if (!props.icon) {
        return <View />
    }
    const { icon: { type, name }, iconStyle, enabled , size} = props
    if (type == 'feather') {
        return <Icon size={size} style={[styles.cardIcon, iconStyle]} name={name} />
    } else {
        var icon = enabled
            ? customIconFilepath[name]
            : customIconFilepath[name + '-disabled'];
        return <Image resizeMode='contain' style={[styles.cardIcon, { height: size, width: size, borderRadius: size/2 }]} source={icon} />
    }
}

function TemplateCard(props) {
    const { title, icon, key, callback, enabled, size } = props
    const { onPress, iconStyle, cardStyle, enabledColor } = styleCard(enabled, callback)
    const fontSize = is_iOS ? null : Math.sqrt(Layout.window.width * 0.4)
    const iconSize = 30
    const children = (
        <View>
            <IntegrationIcon size={iconSize} iconStyle={iconStyle} enabled={enabled} icon={icon} />
            <Text adjustsFontSizeToFit={true} numberOfLines={1} style={[styles.cardText, iconStyle, {width: '150%', color: Colors.lightColor }]}>{title}</Text>
            <TouchableOpacity
                style={[tw.style(
                'py-2 mt-6',
                ), {
                backgroundColor: Colors.shadowColor,
                borderRadius: 0,
                flex: 1,
                }]}
                onPress={onPress}>
            <Text
                style={tw.style('text-white p-2 font-medium', {
                fontFamily: 'SpeziaExtended-SemiBold',
                })}>
                {"Select this template"}
                </Text>
            </TouchableOpacity>
        </View>
    );
    
    return (
        <Col key={key} style={[styles.column, { borderWidth: 2, borderColor: Colors.darkTintColor, borderRadius: 2 }]}>
            <TouchableOpacity onPress={onPress} style={{ zIndex: 1 }} >
                <Card content={children} size={size} cardStyle={[cardStyle, size]}/>
            </TouchableOpacity>
        </Col>
    );
}

function IntegrationContainer(props) {
    const {header, onPress, templates } = props
    const cards = templates.map(TemplateReportCard)
    return (
        <View style={styles.container}>
            {header != "" ? <GradientText style={styles.header}>{header}</GradientText>: null}
            <Grid style={styles.gridContainer}>
                {cards}
            </Grid>
        </View>
    );
}


const ReportTemplatesScreen = ({ route, navigation }) => {
    const { id, startDate, endDate, period, hideReportTitle } = route?.params ?? {}
  const [latestActivity, setActivity] = React.useState(undefined);
  const [reportProps, setReportProps] = React.useState({});
  const [showReport, setShowReport] = React.useState(false);

  const bootstrap = async () => {
    const {workouts} = await getTodaysWorkoutReport();
    // reconcile workouts for today in local state with new workouts from server
    // if a new one is available, trigger the modal after 1500 ms
    // showModal('Overlay', {activity: workouts[0]});
    // would put this in local state so when you launch the modal
    // from something like the in chat report you don't have to fetch it again
    console.log("getting today's workout report", workouts[0])
    setActivity(workouts[0]);
  };

  /*
    Register an event handler when the app is opened
    Typically used for hydration of local state
    For our use case, we're checking the server for any new workout data
     - if we are able to save a trigger in local state from the background observer,
     - we could likely only fetch when: appActivated && state.workoutDetected
  */
  useOnAppActivated(() => bootstrap());
  useEffect(() => {
    bootstrap()
  }, [])

    const urlParams = new URLSearchParams(window.location.search);
    const userId = urlParams.get('uid').split("?useInAppModal=false")[0] ?? id;
    console.log("userId", userId, id)
  if (userId == "null" || userId == null) {
      if (!id) {
          navigation.dispatch(
            CommonActions.navigate({
              name: 'Home',
              params: {
                id: userId,
              },
            })
          );
      }
  }
  return (
    <View style={{ flex: 1}}>   
        <View style={[tw.style('w-full'), { flex: 1, paddingHorizontal: 20, paddingTop: 0, backgroundColor: Colors.darkBlackColor, minHeight: Layout.window.height * 3 }]}>
      {/* <Text
      
          style={tw.style('text-white font-medium', {
            fontFamily: 'SpeziaExtended-SemiBold', fontSize: 36
          })}>
          Share a report
      </Text> */}
      {/* <IntegrationContainer 
        header={"Analysis"}
        onPress={() => {
        }}
        templates={[
          {
              reportName: "Sleep overview",
              onPress: () => {
                 setReportProps({
                    activity: latestActivity
                  })
                  setShowReport(true)
                }
          }, {
              reportName: "Trends",
              onPress: () => {}
          }, {
              reportName: "Correlations",
              onPress: () => {}
          }, {
              reportName: "Activity Impact",
              onPress: () => {}
          },
          
      ]}
      /> */}
      {/* <IntegrationContainer 
        header={"Reports"}
        templates={[
          {
              reportName: "Week in review",
              onPress: () => {
                 setReportProps({
                    activity: latestActivity
                  })
                  setShowReport(true)
              }
          }, {
              reportName: "Month in review",
              onPress: () => {}
          }, {
              reportName: "Year in review",
              onPress: () => {}
          }
      ]}
      /> */}
      
       {/* <Text
          style={tw.style('text-white p-2 font-medium', {
            fontFamily: 'SpeziaExtended-SemiBold',
            fontSize: 24, textAlign: "left"

          })}>
          Preview:
        </Text> */}
      {<View style={{
          alignItems: "center", justifyContent: "center"
      }}>
          {<ReviewReport userId={userId} startDate={startDate} endDate={endDate} period={period} hideReportTitle={hideReportTitle}/>}
        {showReport ? <Modal
            {...reportProps}
        />: null}
    </View>}
    </View>
    </View>
  );
};

export default ReportTemplatesScreen;
