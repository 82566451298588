const primaryColor = '#79d9d0';
const crimsonColor = '#5E0026';
const secondaryColor = '#FF7857';
const orangeColor = '#FFA599';
const purpleColor = '#57276f';
const brightColor = '#FEE1C0';
const lightColor = '#FAF7F7';
const lightBlueColor = '#C0D6FE';
const lightPurpleColor = '#f2d2ff';
const violetColor = '#4B5CC5';
const slateGrayColor = '#182538';
const tintColor = '#7581ae';
const brightBlueColor = '#06d2f0';
const brightTintColor = '#79d9d0';
const actionGreenColor = '#66cc91';
const positiveGreenColor = '#66cc91';
const royalBlueColor = '#0d2481';
const darkBlueColor = '#1D7EF7';
const negativeRedColor = '#ff7a9f';
const baselineBlueColor = '#2FEAFC';
const trialPurpleColor = '#535FD7';
const shadowColor = '#728CFF';
const lavenderColor = '#D9CFF7';
const redColor = '#f87077';
const blackColor = '#3c3c3c';
const whiteColor = '#FFF';
const grayColor = '#8c8c8c';
const lightGrayColor = '#8595AE';
const lighterGrayColor = '#f3eeed';
const evenLighterGrayColor = '#f6f8ff';
const homeBackgroundColor = '#fdfdfe';

const blockBackgroundColor = '#f1f1fb';
const tagBackgroundColor = '#e8e9f9';

// const orangeColor = '#ffafa2';
// const purpleColor = '#57276f';
// const brightColor = "#FEE1C0";
// const lightColor = "#ebf3ff";
// const lightBlueColor = "#C0D6FE";
// const shadowColor = '#768fff';
const darkBlackColor = '#010101';

const darkGrayColor = '#1c1d1f';
const darkLightBlackColor = '#1c1d1f';
const darkLighterBlackColor = '#3c3c3c';
const darkPurpleColor = '#262937';
const darkTintColor = '#333063';
const yellowColor = '#ffef44';
const darkOrangeColor = '#FFC74A';
const darkAccentColor = '#EFAF66';
const darkPrimaryColor = '#2a2438';
const darkSecondaryColor = '#5c5470';
const darkTertiaryColor = '#352f44';
const darkLightColor = '#dbd8e3';

export const darkModePalette = {
  primary: darkPrimaryColor,
};

export default {
  primaryColor,
  crimsonColor,
  lightColor,
  secondaryColor,
  yellowColor,
  brightColor,
  lightBlueColor,
  lightPurpleColor,
  purpleColor,
  orangeColor,
  violetColor,
  brightBlueColor,
  royalBlueColor,
  darkBlueColor,
  slateGrayColor,
  tintColor,
  darkGrayColor,
  darkBlackColor,
  darkOrangeColor,
  darkLightBlackColor,
  darkLighterBlackColor,
  darkPrimaryColor,
  darkPurpleColor,
  darkTintColor,
  darkAccentColor,
  darkSecondaryColor,
  darkTertiaryColor,
  darkLightColor,
  actionGreenColor,
  positiveGreenColor,
  baselineBlueColor,
  trialPurpleColor,
  negativeRedColor,
  brightTintColor,
  homeBackgroundColor,
  tagBackgroundColor,
  blockBackgroundColor,
  shadowColor,
  lavenderColor,
  blackColor,
  whiteColor,
  redColor,
  grayColor,
  lightGrayColor,
  lighterGrayColor,
  evenLighterGrayColor,
  tabIconDefault: '#ccc',
  tabIconSelected: tintColor,
  tabBar: '#fefefe',
  errorBackground: 'red',
  errorText: '#fff',
  warningBackground: '#EAEB5E',
  warningText: '#666804',
  noticeBackground: tintColor,
  noticeText: '#fff',
};
