import { fonts } from './';
import { StyleSheet } from 'react-native';
import * as theme from './';

export default StyleSheet.create({
  headingText: {
    fontSize: fonts.FONT_SIZE_XXLARGE,
    fontWeight: fonts.FONT_WEIGHT_HEAVY,
    fontFamily: fonts.FONT_FAMILY_HEAVY,
  },
  subheadingText: {
    ...theme.typography.h6,
    color: theme.palette.lavenderColor,
    fontFamily: 'SpeziaExtended-SemiBold',
    textAlign: 'center',
  },
  navigationHeaderText: {
    marginTop: 36,
    fontFamily: 'SpeziaExtended-SemiBold',
    color: '#fff',
    fontSize: fonts.FONT_SIZE_MEDIUM,
    textAlign: 'left',
  },
  secondaryHeadingText: {
    fontSize: fonts.FONT_SIZE_LARGE,
    fontWeight: fonts.FONT_WEIGHT_HEAVY,
    fontFamily: fonts.FONT_FAMILY_HEAVY,
  },
  subtitleText: {
    fontSize: fonts.FONT_SIZE_SMALL,
    fontFamily: fonts.FONT_FAMILY_LIGHT,
  },
  card: {
    borderRadius: 8,
    padding: 12,
  },
  largeButton: {
    borderRadius: 4,
    padding: 8,
    paddingLeft: 16,
    paddingRight: 16,
  },
  smallButton: {
    borderRadius: 4,
    padding: 2,
    paddingLeft: 8,
    paddingRight: 8,
  },
  largeButtonText: {
    fontSize: fonts.FONT_SIZE_LARGE,
    fontWeight: fonts.FONT_WEIGHT_HEAVY,
    fontFamily: fonts.FONT_FAMILY_HEAVY,
    textAlign: 'center',
  },
  mediumButtonText: {
    fontSize: fonts.FONT_SIZE_MEDIUM,
    fontFamily: fonts.FONT_FAMILY_HEAVY,
    textAlign: 'center',
  },
  smallButtonText: {
    fontSize: fonts.FONT_SIZE_SMALL,
    fontFamily: fonts.FONT_FAMILY_HEAVY,
    textAlign: 'center',
  },
  containerDropShadow: {
    shadowColor: '#FFF',
    shadowOpacity: 0.25,
    shadowRadius: 10,
    shadowOffset: {
      y: 0,
    },
  },
  containerWithBorder: {
		borderWidth: 1,
		borderColor: '#1c1d1f',
		borderRadius: 8,
  },
});
