import moment from 'moment';

export function classifyConsistency(std: number) {
  if (Math.abs(std) <= 0.25) {
    return 'Excellent';
  }
  if (Math.abs(std) <= 0.75) {
    return 'Great';
  }
  if (Math.abs(std) <= 1) {
    return 'Good';
  }
  if (Math.abs(std) <= 1.5) {
    return 'Average';
  }
  if (Math.abs(std) <= 2) {
    return 'Fair';
  }
  if (Math.abs(std) > 2) {
    return 'Poor';
  } else {
    console.error('classifyConsistency in timeUtils.ts got an illegal value');
    return 'Average';
  }
}

const consistenciesClasses = [
  'Poor',
  'Fair',
  'Average',
  'Good',
  'Great',
  'Excellent',
];

export function interpretConsistency(std: number) {
  return consistenciesClasses.indexOf(classifyConsistency(std));
}

export function interpetDeviation(
  deviation: number,
  value: number,
  average: number,
  hasTarget: boolean,
) {
  let descriptor = 'later';
  if (deviation < 0 || value < average) descriptor = 'earlier';
  const minutes = Math.abs(deviation) % 60;
  const hrs = Math.floor(Math.abs(deviation) / 60);
  return deviation > 5
    ? `${hrs > 0 ? `${hrs}${hrs == 1 ? 'hr' : 'hrs'}` : ''}${
        hrs > 0 && minutes > 0 ? ' ' : ''
      }${
        minutes > 0 ? `${minutes}${minutes == 1 ? 'min' : 'mins'}` : ''
      } ${descriptor} than ${hasTarget ? 'target' : 'usual'}`
    : deviation < -5
    ? `${hrs > 0 ? `${hrs}${hrs == 1 ? 'hr' : 'hrs'}` : ''}${
        hrs > 0 && minutes > 0 ? ' ' : ''
      }${
        minutes > 0 ? `${minutes}${minutes == 1 ? 'min' : 'mins'}` : ''
      } ${descriptor} than ${hasTarget ? 'target' : 'usual'}`
    : hasTarget
    ? 'On target 🎉'
    : 'Same as usual 🎉';
}

export function formatTime(time: number) {
  return moment.utc(time * 3600 * 1000).format('hh:mma');
}
