import React, { useState } from 'react';

import { View, StyleSheet, Text } from 'react-native';
import Colors from '../../constants/Colors';
import LinearGradient from 'react-native-linear-gradient';
import commonStyles from '../../theme/common';

const styles = StyleSheet.create({
  progBar: {
    marginTop: 4,
    marginBottom: 8,
    color: '#182538',
  },
  values: {
    flexDirection: 'row',
  },
  value: {
    justifyContent: 'flex-start',
    textAlign: 'center',
  },
  scale: {},
  bars: {
    flexDirection: 'row',
  },
  bar: {
    justifyContent: 'flex-start',
    height: 12,
    // borderColor: Colors.lightBlueColor,
    // borderWidth: 1,
    borderRightWidth: 0,
  },
  animatedBar: {
    height: 8,
    borderColor: Colors.brightColor,
    borderWidth: 0,
  },
  legends: {
    marginTop: 8,
    marginLeft: 0,
    width: '100%',
    textAlign: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  legend: {
    marginRight: 2,
    flexDirection: 'row',
    textAlign: 'center',
  },
  dot: {
    fontSize: 10,
    borderColor: '#fff',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 2,
  },
  label: {
    marginLeft: 2,
    fontFamily: 'SpeziaExtended-Medium',
    color: Colors.darkBlackColor,
    fontSize: 10,
    justifyContent: 'center',
  },
});

export const benchmarkColors = [
  '#dd77ff',
  '#a077ff',
  '#6d8dff',
  '#6dc4ff',
  Colors.primaryColor,
  Colors.positiveGreenColor,
];

function BenchmarkProgressBar(props) {
  const { currentIndex, score } = props;

  const currentValue = 1.3;
  const currentRangeMin = 1.1;
  const currentRangeMax = 1.5;
  const predictedValue = 1.7;

  const gradients = [
    Colors.crimsonColor,
    Colors.secondaryColor,
    Colors.orangeColor,
    Colors.darkAccentColor,
    Colors.lavenderColor,
    Colors.shadowColor,
    Colors.darkTintColor,
  ];

  const readings = [
    {
      name: 'You currently',
      markerPosition: 50,
      currentPercentage: 100,
      maxPercentage: 10,
      ranges: '>1.6 hrs',
      units: 'hrs',
      legend: 'Poor',
      color: benchmarkColors[0],
    },
    {
      name: 'You currently',
      markerPosition: 50,
      currentPercentage: 100,
      maxPercentage: 20,
      ranges: '>1.6 hrs',
      units: 'hrs',
      legend: 'Fair',
      color: benchmarkColors[1],
    },
    {
      name: 'You currently',
      showMarker: true,
      isCurrent: true,
      isProjection: false,
      value: currentValue.toFixed(1),
      markerPosition: 50,
      currentPercentage: 100,
      ranges: `${currentRangeMin} hrs-${currentRangeMax} hrs`,
      units: 'hrs',
      maxPercentage: 20,
      legend: 'Average',
      color: benchmarkColors[2],
    },
    {
      name: 'You currently',
      showMarker: true,
      isCurrent: true,
      isProjection: false,
      value: currentValue.toFixed(1),
      markerPosition: 50,
      currentPercentage: 100,
      ranges: `${currentRangeMin} hrs-${currentRangeMax} hrs`,
      units: 'hrs',
      maxPercentage: 20,
      legend: 'Good',
      color: benchmarkColors[3],
    },
    // predictedValue != 0 ? {
    // 	name: `Your predicted improvement${experimentName ? ` with ${experimentName} ` : ''}`,
    // 	valueChange: `(+ ${valueChange} hrs)`,
    // 	showMarker: true,
    // 	isProjection: true,
    // 	value: predictedValue ? predictedValue.toFixed(1) : 0,
    // 	markerPosition: (predictedValue - predictedRangeMin) / (predictedRangeMax - predictedRangeMin) * 100,
    // 	currentPercentage: (predictedValue - predictedRangeMin) / (predictedRangeMax - predictedRangeMin) * 100,
    // 	maxPercentage: 50,
    // 	ranges: `${currentRangeMax} hrs-${predictedRangeMax} hrs`,
    // 	units: 'hrs',
    // 	legend: 'Very Good',
    // 	color: Colors.brightTintColor
    // } : null,
    {
      name: 'You currently',
      markerPosition: 50,
      currentPercentage: 100,
      maxPercentage: 20,
      ranges: '>1.6 hrs',
      units: 'hrs',
      legend: 'Great',
      color: benchmarkColors[4],
    },
    {
      name: 'You currently',
      currentPercentage: 100,
      markerPosition: 50,
      maxPercentage: 10,
      ranges: '>1.6 hrs',
      units: 'hrs',
      legend: 'Excellent',
      color: benchmarkColors[5],
    },
  ];
  // const readings = [
  // 	{
  // 		color: Colors.baselineBlueColor,
  // 		currentPercentage: 100,
  // 		maxPercentage: 50,
  // 		stageLength: 4
  // 	},
  // 	{
  // 		currentPercentage: 0,
  // 		maxPercentage: 50,
  // 		color: Colors.trialPurpleColor,
  // 		stageLength: 4
  // 	},
  // ].filter(x => x)
  // const currentIndex = 1
  const onlyShowCurrentPercentage = true;
  const currentProgress = readings
    .map((stage, i) => {
      if (i < currentIndex) {
        return ((2 * stage.markerPosition) / 100) * stage.maxPercentage;
      }
      if (i == currentIndex) {
        return (stage.markerPosition / 100) * stage.maxPercentage;
      }
      return 0;
    })
    .reduce((a, b) => a + b);
  const [showStageLengths, setShowStageLengths] = useState(false);
  let values =
    readings &&
    readings.length &&
    readings.map(function (item, i) {
      const adjustedPercentage =
        item.currentPercentage > 100 ? 100 : item.currentPercentage;
      if (showStageLengths && item.maxPercentage > 0) {
        const stageLengthLabel = `${item.stageLength}d`;
        return (
          <View
            key={`benchmark-progress-bar-stage-${i}`}
            style={{ width: item.maxPercentage + '%' }}>
            <Text
              key={'progress-value'}
              style={{
                fontSize: 12,
                fontFamily: 'Avenir-Medium',
                overflow: 'hidden',
                color: '#000',
              }}>{`${stageLengthLabel}`}</Text>
          </View>
        );
      }
      if (i == currentIndex) {
        return (
          <>
            {/* {score && <Text key={'progress-value'} style={{ fontSize: 14, fontFamily: "TitilliumWeb-Regular", overflow: 'hidden', marginLeft: `${currentProgress > 90 ? 78 : currentProgress}%`, width: 100, color: Colors.lightColor }}>{`${score}`}</Text>} */}
            <Text
              key={'progress-value'}
              style={{
                fontSize: 16,
                fontFamily: 'Spezia-Regular',
                overflow: 'hidden',
                marginLeft: `${
                  currentProgress > 50
                    ? Math.min(currentProgress, 65)
                    : currentProgress
                }%`,
                color: Colors.whiteColor,
                width: 100,
              }}>{`${item.name}`}</Text>
          </>
          //  else if (onlyShowCurrentPercentage) {
          // 	return (
          // 		<View className="value" style={{ color: '#fff', width: item.maxPercentage + '%', opacity: 0 }} key={i}>
          // 			<Text>-</Text>
          // 		</View>
          // 	)
          // }
        );
      }
    }, this);
  let calibrations =
    readings &&
    readings.length &&
    readings.map(function (item, i) {
      const adjustedPercentage =
        item.currentPercentage > 100 ? 100 : item.currentPercentage;

      if (i == currentIndex) {
        return (
          <View
            className="graduation"
            style={{ zIndex: 9 }}
            key={`graduation-${i}`}>
            <Text
              key={`text-${i}`}
              style={{
                color: Colors.whiteColor,
                marginLeft: `${currentProgress}%`,
              }}>
              {'|'}
            </Text>
            <View
              style={{
                marginLeft: `${currentProgress}%`,
                position: 'absolute',
                top: 18,
              }}>
              <View
                key={`text-${i}`}
                style={[
                  {
                    borderWidth: 1,
                    borderRadius: 8,
                    height: 12,
                    width: 12,
                    marginLeft: -4,
                    zIndex: 9,
                    borderColor: Colors.whiteColor,
                  },
                  commonStyles.containerDropShadow,
                ]}></View>
            </View>
          </View>
        );
      }
    }, this);
  let bars =
    readings &&
    readings.length &&
    readings.map(function (item, i) {
      if (item.maxPercentage > 0) {
        const adjustedPercentage =
          item.currentPercentage > 100 ? 100 : item.currentPercentage;
        const currentProgress = Math.round(adjustedPercentage);
        return (
          <View
            className="bar"
            key={`bar-${i}`}
            style={[
              styles.bar,
              {
                // backgroundColor: hexToRgbA(item.color, 0.4),
                width: item.maxPercentage + '%',
                borderLeftWidth: i == 0 ? 1 : null,
                borderRightWidth: i == readings.length - 1 ? 1 : null,
                borderTopLeftRadius: i == 0 ? 5 : null,
                borderBottomLeftRadius: i == 0 ? 5 : null,
                borderTopRightRadius: i == readings.length - 1 ? 5 : null,
                borderBottomRightRadius: i == readings.length - 1 ? 5 : null,
              },
            ]}>
            <View
              className="animatedProgressBar"
              style={[
                styles.animatedBar,
                {
                  // backgroundColor: item.color,
                  borderTopLeftRadius: i == 0 ? 5 : null,
                  width: currentProgress + '%',
                  borderTopLeftRadius: i == 0 ? 5 : null,
                  borderBottomLeftRadius: i == 0 ? 5 : null,
                  borderTopRightRadius: i == readings.length - 1 ? 5 : null,
                  borderBottomRightRadius: i == readings.length - 1 ? 5 : null,
                },
              ]}
              key={i}></View>
          </View>
        );
      }
    }, this);
  let legends =
    readings &&
    readings.length &&
    readings.map(function (item, i) {
      if (item.maxPercentage > 0) {
        return (
          <View style={styles.legend} key={`legend-${i}`}>
            {/* <Text className="dot" style={[styles.dot, { color: item.color }]}>
              ●
            </Text> */}
            <Text
              className="label"
              style={[styles.label, { color: Colors.lightColor }]}>
              {item.legend}
            </Text>
          </View>
        );
      }
    }, this);
  return (
    <View className="multicolor-bar" style={styles.progBar}>
      <View style={styles.values}>{values == '' ? '' : values}</View>
      <View className="scale" style={styles.scale}>
        {calibrations == '' ? '' : calibrations}
      </View>
      <LinearGradient
        start={{ x: 0, y: 0 }}
        end={{ x: 1, y: 0 }}
        colors={gradients}
        style={{
          width: '100%',
          opacity: 0.8,
          borderRadius: 16,
        }}>
        <View className="bars" style={styles.bars}>
          {bars == '' ? '' : bars}
        </View>
      </LinearGradient>
      <View className="legends textOverflow" style={[styles.legends]}>
        {legends == '' ? '' : legends}
      </View>
    </View>
  );
}

export { BenchmarkProgressBar };
