import { useAppState } from '@react-native-community/hooks'
import usePrevious from './usePrevious'
import { useEffect, useRef } from 'react'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useOnAppActivated = (onActivated: () => void) => {
  // useRef prevents onActivated to rerun when the callback itself is changed
  // rather than the actual activated change
  const onActivatedRef = useRef(onActivated)
  onActivatedRef.current = onActivated

  const appState = useAppState()
  const previousAppState = usePrevious(appState)
  useEffect(() => {
    if (
      previousAppState !== undefined &&
      previousAppState !== 'active' &&
      appState === 'active'
    ) {
      onActivatedRef.current()
    }
  }, [appState, previousAppState])
}

export default useOnAppActivated
