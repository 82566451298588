
import React from 'react';
import { View, Text, Image, StyleSheet, TouchableOpacity } from 'react-native';
// import { groupToSrc, idToIcon } from '../assets/Icons'
import Colors from '../constants/Colors'
import Layout, { is_iOS } from '../constants/Layout'
import Icon from 'react-native-vector-icons/Feather';

// import AnimatedLinearGradient from './Shimmer'
import LinearGradient from 'react-native-linear-gradient'
import * as Animatable from 'react-native-animatable';

import { hexToRgbA } from '../utils/ColorsUtils';


const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: Colors.darkBlackColor,
	},
	scoreText: {
		textAlign: 'center', 
		color: Colors.whiteColor,
		fontFamily: 'SpeziaExtended-SemiBold',
		fontSize: is_iOS ? 26: 24,
		fontWeight: '600',
		marginTop: 0,
		bottom: 60
	},
	hexagon: {
		width: 70,
		height: 40,
		shadowOpacity: 0.8,
		shadowRadius: 12,
		shadowColor: '#ffe100',
	},
	hexagonInner: {
		width: 70,
		height: 40,
		backgroundColor: hexToRgbA(Colors.brightTintColor, 0.8)
	},
	hexagonAfter: {
		position: 'absolute',
		bottom: -20,
		left: 0,
		width: 0,
		height: 0,
		borderStyle: 'solid',
		borderLeftWidth: 35,
		borderLeftColor: 'transparent',
		borderRightWidth: 35,
		borderRightColor: 'transparent',
		borderTopWidth: 20,
		borderTopColor: hexToRgbA(Colors.brightTintColor, 0.8)

	},
	hexagonBefore: {
		position: 'absolute',
		top: is_iOS ? -20: -20,
		left: 0,
		width: 0,
		height: 0,
		borderStyle: 'solid',
		borderLeftWidth: 35,
		borderLeftColor: 'transparent',
		borderRightWidth: 35,
		borderRightColor: 'transparent',
		borderBottomWidth: is_iOS ? 20: 20,
		borderBottomColor: hexToRgbA(Colors.brightTintColor, 0.8)
	}
})

function Hexagon(props)	{ 
	const marginTop = 4
	const width = 70
	const height = 40
	return (
		<View style={[styles.hexagon, { width, height, marginTop }]}>
			<View style={[styles.hexagonInner, { width, height }]} />
			<View style={[styles.hexagonBefore, { borderLeftWidth: width/2, borderRightWidth: width/2, borderBottomWidth: height/2, top: -height/2 }]} />
			<View style={[styles.hexagonAfter, { borderLeftWidth: width/2, borderRightWidth: width/2, borderTopWidth: height/2, bottom: -height/2 }]} />
			<View style={{ marginTop: -48 }}>
				<Icon name="moon" color={'#FBD321'} size={16} style={{ alignSelf: 'center', bottom: height + 16, marginBottom: 0, zIndex: 1 }} />
				<Text style={[styles.scoreText, { bottom: height + 20, fontSize: height/40 * 36, zIndex: 1, fontFamily: "Teodor-Light" }]}>
					{props.text}
				</Text>
			</View>
		</View>
	)
}

const mapScoreToMoon = (score) => {
	switch (true) {
		case (score >= 90):
			return '🌕'
		case (score >= 70):
			return '🌖'
		case (score >= 50):
			return '🌗'
		case (score >= 25):
			return '🌘'
		default:
			return '🌑';
	}
}

function ordinal_suffix_of(i) {
    var j = i % 10,
        k = i % 100;
    if (j == 1 && k != 11) {
        return i + "st";
    }
    if (j == 2 && k != 12) {
        return i + "nd";
    }
    if (j == 3 && k != 13) {
        return i + "rd";
    }
    return i + "th";
}
const isDev = __DEV__

export function SleepScore(props) {
	const { text, pose } = props
	return (
		<Animatable.View animation="fadeInUp" style={{ margin: 'auto', filter: 'drop-shadow(rgba(255, 225, 0, 0.8) 0px 0px 8px)', marginTop: 24, marginBottom: 12 }}>
			<Hexagon text={text}/>
		</Animatable.View>
	)
}

function SleepScoreCard(props) {
	const isActive = true
	const { iconProps, service, prediction, showButton, currentRank, joinedLeaderboard } = props
	const sleepScore = props.sleepScore || {}
	let { score, bedtime, wakeup, previousScore } = sleepScore
	const experimentId = `magnesium-bed`
	const cardGradients = [Colors.darkTintColor, Colors.darkLightBlackColor]
	const cardTextStyle = { flex: 1 }
	const text = `${score ? `${(Math.min(score, 100)).toFixed(0)}` : `-`}`
	const metrics = [
		{
			label: 'Deep duration',
			value: 'deep',
			service: 'oura',
			group: 'sleep',
			color: Colors.purpleColor
		}
	]
	const previousScoreColor = previousScore - score > 0 ? "#ff7a9f" : previousScore - score == 0 ? Colors.tintColor : Colors.brightTintColor
	return (
		<Animatable.View animation="fadeIn" duration={1500} style={{ ...cardTextStyle, borderRadius: 8, padding: 20, shadowOpacity: 0.3, shadowRadius: 8, shadowColor: Colors.orangeColor, paddingBottom: 0 }}>
			<LinearGradient
				style={{ flex: 1, borderRadius: 8, padding: 16, paddingBottom: 24 }}
				colors={cardGradients}
				start={{ x: 0, y: 0.6 }}
				end={{ x: 0.83, y: 1.2 }}
			>
			{<View style={{ flex: 1, flexDirection: 'row' }}>
			<View style={{ flex: 1, flexDirection: 'column', marginTop: 8, marginBottom: 8 }}>
				<Text textAlign='left' style={{ width: Layout.window.width, fontFamily: 'SpeziaExtended-SemiBold', color: Colors.lightColor, fontSize: 24 }}>
				{`${mapScoreToMoon(score)} My sleep score`}
					{/* <Icon name={"chevron-right"} size={20} color={Colors.orangeColor} /> */}
				</Text>
			{bedtime && <View style={{ alignItems: 'flex-start' }}>	
				<Animatable.Text animation="fadeIn" style={{ textAlign: "left", fontFamily: 'Optima-Regular', color: Colors.lightColor, fontSize: 18 }}>
					<Icon name={"sunset"} size={18} color={Colors.brightColor} />
					{/* {` ${bedtime.timeLabel}`} */}
					{` ${bedtime.timeLabel}`}
					{/* {isDev && ` 10:21pm`} */}
					{<Animatable.Text animation="fadeIn" style={{ fontFamily: 'Optima-Regular', color: Colors.lightColor, fontSize: 18 }}>
						{` - `}
						<Icon name={"sunrise"} size={18} color={Colors.orangeColor} />
					{` ${wakeup.timeLabel}`}
					{/* {isDev && ` 6:25am`} */}
					</Animatable.Text>}
				</Animatable.Text>
				</View>}
				{score && previousScore && <Animatable.Text animation="fadeIn" style={{ fontFamily: "Optima-Regular", fontSize: 18, color: previousScoreColor }}>
					{previousScore && <Icon name={previousScore - score < 0 ? "chevrons-up" : "chevrons-down"} size={18} color={previousScoreColor} />}
					{previousScore && (Math.round(Math.min(score, 100) - Math.min(previousScore, 100))).toFixed(1)}
						{score && <Text style={[styles.textHeader, { fontFamily: "Optima-Regular", fontSize: 16, marginTop: 0, color: Colors.previousScoreColor }]}>
						{' from Yesterday'}
					</Text>}
				</Animatable.Text>}
				</View>
				{score && <View style={{ position: 'absolute', right: -8, alignItems: 'center' }}>
				</View>}
				<View style={{ alignItems: 'flex-end' }}>
					{<SleepScore text={text} />}
					{/* {isDev && <SleepScore text={"92"} />} */}
				</View>
			</View>}
			{(joinedLeaderboard && currentRank) ? <Animatable.View animation="fadeInLeft" style={{ height: 24, marginLeft: -20, padding: 2, paddingLeft: 8, paddingRight: 8, borderRadius: 4, width: 'auto', flexDirection: "row", justifyContent: 'center', alignSelf: 'flex-start' }}>
				<View style={{
					position: 'absolute',
					width: 170,
					top: 0,
					left: 0,
					backgroundColor: 'transparent',
					borderStyle: 'solid',
					borderTopLeftRadius: 0,
					borderBottomLeftRadius: 0,
					borderRightWidth: 28,
					borderTopWidth: 24,
					borderRightColor: 'transparent',
					borderTopColor: Colors.brightColor
				}} />
				<Icon name="trophy" theme="twoTone" size={14} color={Colors.darkPurpleColor} style={{}} />
				<Text style={{ fontFamily: 'Avenir-Black', fontSize: 12, color: Colors.darkPurpleColor }}>
					{` You're in ${ordinal_suffix_of(currentRank)} place`}
				</Text>
				</Animatable.View> : joinedLeaderboard ? <View style={{ height: 24}}/>: <View />}
			{service && <View style={{ flex: 1, flexDirection: 'row', alignSelf: "center", marginTop: 12 }}>
				<Text style={{ textAlign: "center", color: hexToRgbA(Colors.whiteColor, 0.8), fontFamily: 'Optima-Regular', fontSize: 12, marginBottom: 0 }}>
					{'Calculated with '}
				</Text>
				{/* <Image source={idToIcon(service)} style={{ marginTop: -1, width: 18, height: 18, borderRadius: 9 }} /> */}
				<Text style={{ textAlign: "center", color: hexToRgbA(Colors.whiteColor, 0.8), fontFamily: 'Optima-Regular', fontSize: 12, marginBottom: 0 }}>
				{/* {` ${idToHeaderText(service)}`} */}
				</Text>
			</View>}
			</LinearGradient>
		</Animatable.View>
	)
}

export default SleepScoreCard;