import React from 'react';
import { StyleSheet, Text, View, Platform, TouchableOpacity } from 'react-native';

import { is_iOS } from '../constants/Layout'
import Colors from '../constants/Colors';
import Layout from '../constants/Layout';

// import { BoxShadow as Shadow } from 'react-native-shadow'

// import { Icon } from 'expo';
const styles = StyleSheet.create({
    cardContainer: {
        shadowOffset: {
            width: 1,
            height: 1
        },
        shadowColor: Colors.shadowColor,
        shadowOpacity: 0.1,
        shadowRadius: 8,
        borderRadius: 8,
        // elevation: !is_iOS ? 1 : undefined,
    },
    card: {
        borderRadius: 8,
        width: '100%',
        height: '100%',
        padding: 20.
    },
})

const shadowOpt = (size) => {
    return {
        width: size ? size.width: Layout.window.width * 0.23,
        height: size ? size.height: Layout.window.height * 0.125,
        color: Colors.shadowColor,
        border: 4,
        radius: 8,
        opacity: 0.05,
        x: 0,
        y: 0,
    }
}

export const addShadowToCard = (card, size) => {
    var cardShadowSettings = shadowOpt(size)
    // if (size && (size.width || size.height)) {
    //     const { width, height } = size
    //     if (height) {
    //         cardShadowSettings.height = height
    //     }
    //     if (width) {
    //         cardShadowSettings.width = width
    //     }
    // }
    const shadowCard = is_iOS ? card: card
    return shadowCard
}

const Card = (props) => {
    const { content, children, cardStyle, size} = props
    const card = (
        <View style={[styles.cardContainer, props.style]}>
            <View style={[styles.card, props.cardStyle]}>
                {children}
                {content}
            </View>
        </View>
    )
    const shadowCard = addShadowToCard(card, size)
    return (
        shadowCard
    );
}

export default Card;