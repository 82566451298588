import React from 'react';

import F2Chart from '../../components/F2Chart';
import { isGoodTrend } from '../../constants/Metrics';

import Colors from '../../constants/Colors';
import { View, Image, Text, StyleSheet } from 'react-native';
import { hexToRgbA } from '../../utils/ColorsUtils';

import { idToIcon } from '../../../assets/Icons';
import Layout, { isWeb } from '../../constants/Layout';
import { formatToDateString } from '../../utils/datetime';

const styles = StyleSheet.create({
  metricHeader: {
    fontFamily: 'Teodor-Light',
  },
});
function getWindowDimensions() {
  const { width, height } = Layout.window;
  return {
    width,
    height,
  };
}

const initScript = (data, average, trend, color, dateString, pastData) => `
(function(){
    chart =  new F2.Chart({
        id: 'chart',
        pixelRatio: window.devicePixelRatio,
				padding: [20, 0],

    });

	chart.source(${JSON.stringify(data)}, {
		x: {
				type: 'timeCat',
				mask: 'ddd, MMM DD',
				range: [0, 1],
				tickCount: 8
			},
	});

	chart.tooltip({
		showItemMarker: false,
		onShow: function onShow(ev) {
			const items = ev.items;
			items[0].name = null;
			items[0].name = items[0].title;
			items[0].value = '' + items[0].value;
		}
	});
	chart.axis(null)
	chart.guide().line({
		start: [0, ${JSON.stringify(average)}],
		end: [${JSON.stringify(dateString)}, ${JSON.stringify(average)}],
		style: {
			lineWidth: 3,
			lineDash: [3, 8],
			stroke: ${JSON.stringify(Colors.lightGrayColor)}
		}
	});
	chart.line().position('x*y').shape('smooth')
	.color('y', (y) => {
		if (${isGoodTrend(trend, 1)}) {
			return 'l(90) 0:${Colors.brightTintColor} 0.5:${color} 1:${color}';
		}
		if (${isGoodTrend(trend, -1)}) {
			return 'l(90) 0:${color} 0.5:${color} 1:${Colors.brightTintColor}';
		}
		return ${JSON.stringify(color)};
	}).size(2).animate({
		appear: {
			duration: 800
		}
	});
	chart.tooltip({
		showItemMarker: false,
		background: {
			radius: 8,
			fill: ${JSON.stringify(hexToRgbA(Colors.darkPurpleColor, 1))},
			padding: [8, 8, 8]
		},
		onShow: function onShow(ev) {
			const items = ev.items;
			items[0].name = items[0].title;
			// items[1].name = "7-day average";
			const value = items[0].value ? items[0].value.length > 5 ? items[0].value.substring(0, 5): items[0].value: "";
			items[0].value = value;
			// items[1].value = ${JSON.stringify(average)};
		}

	});
	chart.render();
	document.body.style.userSelect = 'none';
})();
`;

export default function TrendsChart(props) {
  const { metric, dateString } = props;
  const {
    label,
    average,
    lastValue,
    pastData,
    units,
    percentChange,
    value,
    service,
    data,
    id,
  } = metric;


  const directionArrow =
    percentChange > 0 ? '↑' : percentChange == 0 ? '-' : '↓';
  const graphColor = props.graphColor || Colors.violetColor;
  const barGraphHeight = 60;
  const trend = value ? isGoodTrend(value, percentChange) : isGoodTrend(id);
  const trendColor =
    percentChange == 0
      ? '#aaa'
      : trend
      ? Colors.brightTintColor
      : Colors.redColor;
  const noData = data.length == 0;
  const startDate = data.slice().shift()?.x;
  const endDate = data.slice().pop()?.x;

	console.log("startDate", lastValue, startDate, endDate, dateString)
  return (
    <View
      style={{
        marginHorizontal: 8
      }}>
      <View style={{ flexDirection: 'row', flex: 1, marginLeft: 0, position: "relative" }}>
        {/* {service ? (
          // <View style={{  borderRadius: 18, width: 36, height: 36, padding: 4 }}>
          <Image
            source={idToIcon(service)}
            style={{
              width: 24,
              height: 24,
              marginRight: 4,
              overflow: 'hidden',
              borderRadius: 12,
            }}
          />
        ) : null} */}
        <Text
          style={[
            {
							fontFamily: "Teodor-Light",
              color: Colors.whiteColor,
              margin: 0,
							left: 0,
              fontSize: 24,
              textAlign: 'left',
            },
          ]}>
          {label !== null && `${label}`}
        </Text>
        <Text
          style={[
            styles.metricHeader,
            {
              justifyContent: 'flex-start',
              alignSelf: 'flex-start',
              alignItems: 'flex-start',
              color: trendColor,
              fontSize: 16,
              textAlign: 'right',
              position: 'absolute',
              right: 0,
              top: 4,
            },
          ]}>
          {average != null &&
            percentChange != null &&
            `${directionArrow}${(percentChange)?.toFixed(1)}% `}
        </Text>
      </View>
      <View style={{ flexDirection: 'row', flex: 1, marginTop: 4, position: "relative" }}>
        {startDate ? (
          <Text
            style={[
              {
								fontFamily: "SpeziaNarrow-SemiBold",
                justifyContent: 'flex-start',
                alignSelf: 'flex-start',
                alignItems: 'flex-start',
                color: Colors.lightColor,
                fontSize: 12,
                textAlign: 'left',
                position: 'absolute',
                left: 0,
                top: 4,
              },
            ]}>
            {formatToDateString(startDate, 'MMM DD')}
          </Text>
        ) : null}
        {endDate ? (
          <Text
            style={[
              {
								fontFamily: "SpeziaNarrow-SemiBold",
                justifyContent: 'flex-end',
                alignSelf: 'flex-end',
                alignItems: 'flex-end',
                position: 'absolute',
                right: 0,
                top: 4,
                color: Colors.lightColor,
                margin: 0,
                fontSize: 12,
                textAlign: 'right',
              },
            ]}>
            {formatToDateString(endDate, 'MMM DD')}
          </Text>
        ) : null}
      </View>
      <View
        style={{
          height: 120,
					width: isWeb ? Layout.window.width /2: Layout.window.width - 96,
        }}>
        <F2Chart
          // function that passes to return javascript string to render chart
          initScript={initScript(
            data,
            average,
            value,
            graphColor,
            endDate,
            pastData,
          )}
          onChange={tooltip => {
            // handle interacting with chart (e.g tooltip callback)
          }}
          // callback for the chart loading
          onChartLoad={props.onChartLoad}
        />
      </View>
      <View style={{ flexDirection: 'row', flex: 1, marginTop: 4, position: "relative" }}>
        <Text
          style={[
            {
							fontFamily: "SpeziaNarrow-SemiBold",
              position: 'absolute',
              top: -8,
              justifyContent: 'flex-start',
              color: Colors.lightGrayColor,
              margin: 0,
              fontSize: 10,
              textAlign: 'left',
            },
          ]}>
          {'Average'}
        </Text>

        <Text
          style={[
            styles.metricHeader,
            {
              justifyContent: 'flex-start',
              color: Colors.lightGrayColor,
              margin: 0,
              fontSize: 20,
              textAlign: 'left',
            },
          ]}>
          {average == null && ''}
          {average !== null && `${average?.toFixed(2)} ${units ? units : ''}`}
        </Text>
        <Text
          style={[
            {
							fontFamily: "SpeziaNarrow-SemiBold",
              justifyContent: 'flex-start',
              position: 'absolute',
              top: -8,
              right: 0,
              color: Colors.whiteColor,
              margin: 0,
              fontSize: 10,
              textAlign: 'right',
            },
          ]}>
          {'Today'}
        </Text>
        <Text
          style={[
            styles.metricHeader,
            {
              position: 'absolute',
              top: 0,
              right: 0,
              justifyContent: 'flex-end',
              alignSelf: 'flex-end',
              alignItems: 'flex-end',
              color: Colors.whiteColor,
              margin: 0,
              fontSize: 20,
              textAlign: 'right',
            },
          ]}>
          {lastValue == null && 'No data from today'}
          {lastValue !== null &&
            `${lastValue?.toFixed(2)} ${units ? units : ''}`}
        </Text>
      </View>
    </View>
  );
}
