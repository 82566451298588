import React, { useEffect } from 'react';
import {Text, StyleSheet, Image, TouchableOpacity, Platform, View, ScrollView } from 'react-native';

import tw from 'twrnc';

import Colors from '../constants/Colors';

import Layout, { isWeb } from '../constants/Layout'

import LottieView from 'lottie-react-native';
import { hexToRgbA } from '../utils/ColorsUtils';
import * as Animatable from 'react-native-animatable';
import curly_ball from '../../assets/curly_ball.png'
import diamond_star from '../../assets/diamond_star.png'
import planet_ring from '../../assets/planet_ring.png'
import flat_waves from '../../assets/flat_waves.png'
import upward_waves from '../../assets/upward_waves.png'
import bioloop_logo from '../../assets/bioloop_logo_white.png'
import Tooltip from './Tooltip'
import Icon from 'react-native-vector-icons/Feather';
import { GradientText } from "./GradientText";
import LinearGradient from 'react-native-linear-gradient'
import AnimateNumber from 'react-native-animate-number'
import * as attributesApi from '../data/attributes/api'
import useSWR from 'swr';
import moment from 'moment'

const styles = StyleSheet.create({
    moonContainer: {
        borderRadius: 800, // border borderRadius same as width and height
        width: isWeb ? Layout.window.width * 0.5 * 0.33: Layout.window.width * 0.5,
        height: isWeb ? Layout.window.width * 0.5 * 0.33: Layout.window.width * 0.5,
        marginRight: 0, // reposition the circle inside parent view
        position: 'absolute',
        top: Layout.window.width * 0.5 * 0.11, // show the bottom part of circle
        right: isWeb ? -Layout.window.width * 0.5 * 0.33 * 0.33: -Layout.window.width * 0.13,
        overflow: 'hidden',
        borderWidth: 2,
        borderColor: hexToRgbA(Colors.brightColor, 1),
        backgroundColor: hexToRgbA(Colors.brightColor, 0.2) // hide not important part of image
    },
})

function StarPattern(props) {
    return <View style={{ top: isWeb ? 48: 36, left: isWeb ? 32: 12, zIndex: 1, position: "absolute" }}>
        <Image resizeMode='contain' source={diamond_star} style={{ position: 'absolute', left: 44, top: -12, width: 12, height: 12, opacity: 0.3 }} />
        <Image resizeMode='contain' source={diamond_star} style={{ position: 'absolute', left: 22, top: -4, width: 16, height: 16, opacity: 0.4 }} />
        <Image resizeMode='contain' source={diamond_star} style={{ position: 'absolute', left: 0, top: -20, width: 18, height: 18, opacity: 0.5 }}   />
    </View>
}

export default function Container(props) {
    return <View style={{ }}>
        <LinearGradient colors={[Colors.darkBlackColor, "#1e1e37"]} start={{ x: 0, y: 0 }} end={{ x: 0, y: 1 }} style={{
        borderColor: Colors.darkTintColor, borderWidth: 2, borderRadius: 2, padding: 16, width: isWeb ? Layout.window.width * 6/10: Layout.window.width,
        paddingVertical: 64, overflow: "hidden", marginBottom: 64
        }}>
            {<Animatable.View animation="fadeIn"style={{ marginTop: isWeb ? -24: 0, zIndex: 1, alignItems: "center", justifyContent: 'center'}}>
                <Image source={bioloop_logo} resizeMode={'contain'} style={{
                    width: 100, height: 40,
            }}>
            </Image></Animatable.View>}
            <Icon name={"star"} style={{ position: 'absolute', left: 66, top: 33 }} size={16} color={hexToRgbA(Colors.brightColor, 0.2)} />
            <Icon name={"star"} style={{ position: 'absolute', left: 22, top: 44 }} size={19} color={hexToRgbA(Colors.brightColor, 0.3)} />
            <Icon name={"star"} style={{ position: 'absolute', left: 44, top: 67 }} size={20} color={hexToRgbA(Colors.brightColor, 0.5)} />
            <Animatable.View animation="fadeIn" style={[styles.moonContainer, { zIndex: -1 }]} />
            <Animatable.Text animation="fadeIn" style={tw.style('text-white p-2 font-medium', {
                fontFamily: 'SpeziaExtended-SemiBold', fontSize: 24, textAlign: 'center'
            })}>
                <GradientText style={tw.style('text-white p-2 font-medium', {
                fontFamily: 'SpeziaExtended-SemiBold', fontSize: 36, textAlign: 'center'
                })}>Welcome,</GradientText>
            </Animatable.Text>
            <Animatable.Text animation="fadeIn" style={tw.style('text-white p-2 font-medium', {
                fontFamily: "SpeziaExtended-SemiBold", color: Colors.shadowColor,
                fontSize: isWeb ? 48: 36, textAlign: 'center'
            })}>
                {"2021 Year in review"}
            </Animatable.Text>
            <LottieView
                style={{ position: "absolute", left: "33%", width: "33%", top: -8, alignSelf: "center", opacity: 0.5, zIndex: -1 }}
                source={require('../../assets/animations/planets-orbit.json')}
                autoPlay
                speed={0.1}
                loop={true}
            />
            {props.children}
        </LinearGradient>
    </View>
}