import React, { PureComponent, useState } from 'react';
import {
	ActivityIndicator, Text, View
} from 'react-native';
import Icon from 'react-native-vector-icons/Feather';
import useSWR from 'swr';

import moment from 'moment';
import * as Animatable from 'react-native-animatable';
import Colors from '../../constants/Colors';
import { BenchmarkProgressBar } from './BenchmarkBar';
import {
	todayISODateString
} from '../../utils/datetime';
import {
	classifyConsistency, formatTime, interpretConsistency
} from '../../utils/timeUtils';
import {
	average
} from '../../utils/GraphStatsUtils';
import F2Chart from '../F2Chart';
// import { initScript } from './initScript';
import * as attributesApi from '../../data/attributes/api'

const initScript = (
  data,
  bedtimeAverage,
  wakeupTimeAverage,
  targetBedtime,
  targetWakeup,
  dateValues,
  earliestBedtime,
  latestWakeup,
) => `
(function(){
    chart =  new F2.Chart({
      id: 'chart',
      pixelRatio: window.devicePixelRatio,
	});
	let min = 0
		let max = 12
		// set min and max for x axis, if target bedtime is before earliest bedtime, use target as min, if target wakeup is after latest wakeup, use target as max
		if (${JSON.stringify(earliestBedtime)}) {
			min = ${JSON.stringify(earliestBedtime)}
			max = ${JSON.stringify(latestWakeup)} + 1
		}
		if (max < min) {
			max = 12
		}
		if (${JSON.stringify(earliestBedtime)} > ${JSON.stringify(targetBedtime)}) {
			min = ${JSON.stringify(targetBedtime)}
		}
		if (min > 12) {
			min = min - 25;
		} else {
			min = min - 1
		}
		if (${JSON.stringify(latestWakeup)} < ${JSON.stringify(targetWakeup)}) {
			max = ${JSON.stringify(targetWakeup)}
		}
   	chart.source(${JSON.stringify(data)}, {
			date: {
				type: 'cat',
				// mask: 'ddd',
				values: ${JSON.stringify(dateValues)},
				tickCount: 7,
			},
			value: {
				tickCount: 13,
				formatter: function formatTime(time, showMinutes = false) {
					let hour = Math.floor(time);
						if (Number.isNaN(hour)) {
							return time
						}
						let minutes = '';
						let period = 'am';
						if (showMinutes) {
							let minutesVal = Math.floor((time % 1) * 60)
							if (minutesVal < 0) {
								minutesVal *= -1
							}
							if (minutesVal < 10) {
								minutes = ":0" + minutesVal
							} else {
								minutes = ":" + minutesVal
							}
						}
						if (hour == 0) {
							period = 'am'
							hour = '12'
						} else if (hour == 12) {
							period = 'pm'
							hour = '12'
						} else if (hour < 0 || hour > 12) {
							period = 'pm'
							hour = (hour / 1 + 24) % 12
						}
						return hour + minutes + period 
					},
				min,
				max,
			}
		});
		chart.coord({
			transposed: true
		});
		if (${JSON.stringify(targetBedtime)} != null && ${JSON.stringify(
  targetWakeup,
)} != null) {
			chart.guide().rect({
				start: ['min', ${targetBedtime}],
				end: ['max', ${targetWakeup}],
				style: {
					fill: ${JSON.stringify(Colors.darkTintColor)},
					fillOpacity: 0.6
				}
			})
			chart.guide().line({
				start: ['min', ${JSON.stringify(targetBedtime)}],
				end: ['max', ${JSON.stringify(targetBedtime)}],
				style: {
					lineWidth: 4,
					stroke: ${JSON.stringify(Colors.shadowColor)},
					lineDash: [4, 8],
				},
			});
			chart.guide().line({
				start: ['min', ${JSON.stringify(targetWakeup)}],
				end: ['max', ${JSON.stringify(targetWakeup)}],
				style: {
					lineWidth: 4,
					stroke: ${JSON.stringify(Colors.darkAccentColor)},
					lineDash: [4, 8],
				},
			});
		}
		chart.axis('date', {
			grid: {
				lineDash: null,
				lineWidth: 0
			},
			label: function label(text, index) {
				return {
					fontFamily:  index == 0 ? 'SpeziaExtended-Medium': 'SpeziaExtended-Medium',
					fill: index == 0 ? ${JSON.stringify(Colors.lightColor)}: ${JSON.stringify(
  Colors.shadowColor,
)}
				};
			}
		});
		chart.axis('value', {
			line: null,
			tickLine: null,
			grid: {
				stroke: ${JSON.stringify(Colors.darkBlackColor)},
				lineDash: [2, 8],
			    lineWidth: 1
			},
			label: function label(text, index) {
				if (index % 2 === 0) {
					return {
						fontFamily: 'SpeziaExtended-Medium',
						fontWeight: 'bold',
						fill: ${JSON.stringify(Colors.blockBackgroundColor)}
					};
				}
				return null;
			}
		});
		chart.tooltip({
		showItemMarker: false,
		onShow: function onShow(ev) {
			const items = ev.items;
			items[0].name = null;
			items[0].name = items[0].title;
			let start = items[0].value.split(',')[0];
			let end = items[0].value.split(',')[1];
			let hour1 = start.split('.')[0];
			let hour2 = end.split('.')[0];
			var ampm1 = 'am';
			var ampm2 = 'am';
			let min1 = '0.' + start.split('.')[1]
			let min2 = '0.' + end.split('.')[1]
			if (parseFloat(min1)) {
				min1 = Math.round(parseFloat(min1) * 60)
				min1 = min1 < 10 ? '0' + min1: min1
			} else {
				min1 = ''
			}
			if (parseFloat(min2)) {
				min2 = Math.round(parseFloat(min2) * 60)
				min2 = min2 < 10 ? '0' + min2: min2
			} else {
				min2 = ''
			}
			if (hour1[0] == "-" || parseInt(hour1) && parseInt(hour1) < 0) {
				hour1 = 12 + parseInt(hour1)
				if (parseInt(min1) > 0) {
					hour1 -= 1
				}
				ampm1 = 'pm'
				min1 = 60 - parseInt(min1)
			}
			if (hour2[1] == "-" || parseInt(hour2) && parseInt(hour2) < 0) {
				hour2 = 12 + parseInt(hour2)
				if (parseInt(min2) > 0) {
					hour2 -= 1
				}
				ampm2 = 'pm'
				min2 = 60 - parseInt(min2)
			}
			if (hour1 == "0") {
				hour1 = 12
			}
			if (hour2 == "0") {
				hour2 = 12
			}
			
			// var date1 = new Date(parseFloat(start) * 36 * 1000);
			// var date2 = new Date(parseFloat(end) * 36 * 1000);
			// var date1hours = date1.getHours()
			// var date2hours = date2.getHours()
			
			// var date1minutes = date1.getMinutes() < 10 ? '0' + date1.getMinutes(): date1.getMinutes()
			// var date2minutes = date2.getMinutes() < 10 ? '0' + date2.getMinutes(): date2.getMinutes()
			// var time1 = date1hours + ':' + date1minutes + ' ' + ampm1;
			// var time2 = date1hours + ':' + date2minutes + ' ' + ampm2;
			items[0].value = '' + hour1 + ':' + min1 + ampm1 + ' - ' + hour2 + ':' + min2 + ampm2;
		}
		});
		chart.interval()
			.position('date*value').style({
				radius: 4,
			}
			).animate({
			appear: {
				animation: 'shapesScaleInY'
			}
			}).size(10).color('l(0) 0:#545db3 0.66:#7581ae 1:#ffafa2')
			
    chart.render();
	document.body.style.userSelect = 'none';
})();
`;


// require('@antv/f2/lib/interaction/interval-select');
function formatAMPM(date) {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours %= 12;
  hours = hours || 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  const strTime = `${hours}:${minutes} ${ampm}`;
  return strTime;
}

const totalAverage = data => data.reduce((a, b) => a + b, 0) / data.length;


function generateSleepScheduleStats(bedtimes, wakeupTimes, dateString) {
  // console.log("bedtimes", bedtimes)
if (wakeupTimes && wakeupTimes.length > 0 && bedtimes && bedtimes.length > 0) {
		if (dateString != todayISODateString()) {
			bedtimes = bedtimes.filter(x => moment(dateString, "YYYY-MM-DD").diff(moment(x.date), 'days') < 7)
			wakeupTimes = wakeupTimes.filter(x => moment(dateString, "YYYY-MM-DD").diff(moment(x.date), 'days') < 7)
		} else {
			bedtimes = bedtimes.filter(x => moment().diff(moment(x.date), 'days') < 7)
			wakeupTimes = wakeupTimes.filter(x => moment().diff(moment(x.date), 'days') < 7)
		}
		const weekdays = bedtimes.map(x => moment.utc(x.date).format('ddd'))
		if (weekdays.length >= 2 && (weekdays[0] === weekdays[weekdays.length - 1] || weekdays[0] === moment().format('ddd'))) {
			bedtimes.shift()
			wakeupTimes.shift()
		}

		const bedtimeValues = bedtimes.map(x => x.value)
		const wakeupValues = wakeupTimes.map(x => x.value)
		let earliestBedtime = Math.min(...bedtimeValues.map((x) => {
			if (x > 12) {
				return x - 24
			}
			return x
		}))
		// if (earliestBedtime < 0) {
		// 	earliestBedtime += 24
		// }
		const adjustedBedtimes = bedtimeValues.map((x) => {
			if (x < 12) {
				return x + 24
			}
			return x
		})
		const adjustedWakeups = wakeupValues.map((x) => {
			return x
		})
		const latestWakeup = Math.max(...wakeupValues)
		const bedtimeAverage = average(adjustedBedtimes.map(x => x)) - 24
		let bedtimeConsistency;
		if (adjustedBedtimes.length) {
			bedtimeConsistency = Math.sqrt(adjustedBedtimes.map(x => Math.pow(x - 24 - bedtimeAverage, 2)).reduce((a, b) => a + b) / adjustedBedtimes.length)
		}

		let consistency;
		const wakeupTimeAverage = average(wakeupValues)
		let wakeupConsistency;
		if (wakeupValues.length > 0) {
			wakeupConsistency = Math.sqrt(wakeupValues.map(x => Math.pow(x - wakeupTimeAverage, 2)).reduce((a, b) => a + b) / wakeupValues.length)
		}
		consistency = (Math.abs(bedtimeConsistency) + Math.abs(wakeupConsistency)) / 2
		const sleepScheduleData = wakeupTimes.map((d, i) => {
			if (i <= bedtimes.length - 1) {
				return {
					date: moment.utc(bedtimes[i].date).format('ddd'),
					value: [adjustedBedtimes[i] - 24, adjustedWakeups[i]]
				}
			}
			return {

			}
		})
		return {
			consistency,
			sleepScheduleData,
			wakeupTimeAverage,
			bedtimeAverage,
			earliestBedtime,
			latestWakeup
		}
	}
	return {

	}
}

function SleepScheduleGraph(props) {
  const {
    data,
    hasTarget,
    bedtimeAverage,
    wakeupTimeAverage,
    targetWakeup,
    targetBedtime,
    earliestBedtime,
    latestWakeup,
    onChartLoad,
    dateString,
  } = props;
  const showLastNightOnly = false
  const barGraphHeight = 60;
  const endDate = dateString || todayISODateString();
  const dateValues = showLastNightOnly
    ? Array.from({ length: 7 }, (x, i) => i)
        .map(i => moment(endDate).subtract(i, 'days').format('ddd'))
        .splice(0, 1)
    : Array.from({ length: 7 }, (x, i) => i).map(i =>
        moment(endDate).subtract(i, 'days').format('ddd'),
      );
  let formattedBedtimeDeviation;
  let formattedWakeupDeviation;
  let bedtimeDeviation;
  let wakeupDeviation;
  let formattedBedtime;
  let formattedWakeup;
  const formattedAverageBedtime = hasTarget
    ? formatTime(targetBedtime, true)
    : formatTime(targetBedtime, true);
  const formattedAverageWakeup = formatTime(targetWakeup, true);
  if (showLastNightOnly && data && data.length > 0) {
    const lastData = data.slice().pop();
    if (lastData.value && lastData.value.length > 1) {
      const [bedtime, wakeup] = lastData.value;
      formattedBedtime = formatTime(bedtime, true);
      formattedWakeup = formatTime(wakeup, true);
      bedtimeDeviation = moment
        .duration(
          moment(formattedBedtime, 'h:mma').diff(
            moment(formattedAverageBedtime, 'h:mma'),
          ),
        )
        .asMinutes();
      if (bedtime > 20 && bedtimeAverage < 6) {
        bedtimeDeviation = 1440 - bedtimeDeviation;
      }
      if (Math.abs(bedtimeDeviation) > 960) {
        bedtimeDeviation =
          bedtimeDeviation > 0
            ? 1440 - Math.abs(bedtimeDeviation)
            : 1440 + bedtimeDeviation;
      }
      wakeupDeviation = moment
        .duration(
          moment(formattedWakeup, 'h:mma').diff(
            moment(formattedAverageWakeup, 'h:mma'),
          ),
        )
        .asMinutes();
      if (bedtimeDeviation && wakeupDeviation) {
        formattedBedtimeDeviation = interpetDeviation(
          bedtimeDeviation,
          bedtime,
          targetBedtime,
          hasTarget,
        );
        formattedWakeupDeviation = interpetDeviation(
          wakeupDeviation,
          wakeup,
          targetWakeup,
          hasTarget,
        );
      }
    }
  } else {
    formattedBedtime = formatTime(bedtimeAverage, true);
    formattedWakeup = formatTime(wakeupTimeAverage, true);
  }

  console.log("targetBedtime", targetBedtime)
  console.log("targetWakeup", targetWakeup)

  return (
    <Animatable.View animation="fadeIn" style={{}}>
      {/* <Canvas ref={(canvas) => this.handleBarGraph(canvas, data, {})} /> */}
      <View
        style={{ position: 'absolute', top: -8, left: 20, alignItems: 'flex-start' }}>
        <Icon name="moon" size={24} color={Colors.shadowColor} />
        {bedtimeAverage != null ? (
          <Text
            style={{
              marginLeft: -16,
              color: '#fff',
              fontFamily: 'Inter-SemiBold',
              fontSize: 14,
            }}>
            {formattedBedtime}
          </Text>
        ) : null}
        {bedtimeDeviation != null ? (
          <Text
            style={{
              marginLeft: -16,
              color:
                Math.abs(bedtimeDeviation) > 15
                  ? Colors.secondaryColor
                  : Colors.positiveGreenColor,
              fontFamily: 'SpeziaExtended-Regular',
              fontSize: 10,
            }}>
            {formattedBedtimeDeviation}
          </Text>
        ) : null}
      </View>
      <View style={{ position: 'absolute', top: -8, right: 20, alignItems: 'flex-end' }}>
        <Icon name="sunrise" size={24} color={Colors.orangeColor} />
        {wakeupTimeAverage != null ? (
          <Text
            style={{
              marginRight: -16,
              color: '#fff',
              fontFamily: 'Inter-SemiBold',
              fontSize: 14,
            }}>
            {formattedWakeup}
          </Text>
        ) : null}
        {wakeupDeviation != null ? (
          <Text
            style={{
              marginRight: -16,
              color:
                Math.abs(wakeupDeviation) > 15
                  ? Colors.secondaryColor
                  : Colors.positiveGreenColor,
              fontFamily: 'SpeziaExtended-Regular',
              fontSize: 10,
            }}>
            {formattedWakeupDeviation}
          </Text>
        ) : null}
      </View>
      <View style={{ marginTop: 16, height: 240 }}>
        <F2Chart
          initScript={initScript(
            data,
            bedtimeAverage,
            wakeupTimeAverage,
            targetBedtime,
            targetWakeup,
            dateValues,
            earliestBedtime,
            latestWakeup,
          )}
          onChartLoad={onChartLoad}
        />
      </View>
    </Animatable.View>
  );
}


function SleepScheduleView(props) {
  let
    consistency,
    wakeupTimeAverage,
    bedtimeAverage,
    earliestBedtime,
    latestWakeup
  let { sleepScheduleData, userId, targetBedtimeString, targetWakeupString } = props

  const dateString = props.dateString;
  // const user = getUser();
  // const isUser = userId == getUserId();
  // const { } = sleepProfile ?? {};
  const { data } = useSWR(
    ['/api/sleepSchedule', dateString, userId],
    () =>
      attributesApi.adminGetSleepSummary(dateString, userId).then(response => {
        // if (response && response.wakeupTimes && response.bedtimes) {
        //   ({
        //     consistency,
        //     sleepScheduleData,
        //     wakeupTimeAverage,
        //     bedtimeAverage,
        //     earliestBedtime,
        //     latestWakeup,
        //   } = generateSleepScheduleStats(
        //     response.bedtimes,
        //     response.wakeupTimes,
        //     dateString,
        //   ));
        // }
        setDataLoading(false);
        return response.data;
      }),
  );

  if (data && data.wakeupTimes && data.bedtimes) {
    ({
      consistency,
      sleepScheduleData,
      wakeupTimeAverage,
      bedtimeAverage,
      earliestBedtime,
      latestWakeup,
    } = generateSleepScheduleStats(
      data.bedtimes,
      data.wakeupTimes,
      dateString,
    ));
  }
    

  console.log("earliestBedtime", earliestBedtime, latestWakeup)
  let hasTarget = targetBedtimeString && targetWakeupString;
  let targetBedtime = hasTarget ? 
    moment(targetBedtimeString, 'h:mma').hour() +
      moment(targetBedtimeString, 'h:mma').minutes() / 60: bedtimeAverage;
  let targetWakeup = hasTarget ?
    moment(targetWakeupString, 'h:mma').hour() +
      moment(targetWakeupString, 'h:mma').minutes() / 60: wakeupTimeAverage;

  React.useEffect(() => {
    if (dataLoading && sleepScheduleData && sleepScheduleData.length != 0) {
      setDataLoading(false);
    }
  }, [data]);
  const [dataLoading, setDataLoading] = useState(true);
  const hasData = sleepScheduleData && sleepScheduleData.length != 0;

  console.log("sleepScheduleData", sleepScheduleData)

  return (
    <View style={{ flex: 1 }}>
      {!dataLoading && !hasData ? (
        <Text
          style={{
            paddingTop: 16,
            paddingBottom: 16,
            marginLeft: 4,
            height: 300,
            fontFamily: 'Spezia-Regular',
            fontSize: 14,
            color: Colors.lightGrayColor,
            textAlign: 'left',
          }}>
          {`No data available.\nPlease check that your devices are syncing.`}
        </Text>
      ) : dataLoading ? (
        <View style={{
          height: 300
        }} />
      ): null}
      {hasData && (
        <View
          style={{
            margin: 8,
            marginTop: 16,
            padding: 8,
            borderRadius: 8,
            paddingTop: 16,
            height: 300,
            paddingBottom: 32,
          }}>
          {hasData && (
            <SleepScheduleGraph
              hasTarget={hasTarget}
              data={sleepScheduleData}
              wakeupTimeAverage={wakeupTimeAverage}
              bedtimeAverage={bedtimeAverage}
              targetWakeup={targetWakeup}
              targetBedtime={targetBedtime}
              earliestBedtime={earliestBedtime}
              latestWakeup={latestWakeup}
              onChartLoad={props.onChartLoad}
              dateString={dateString}
            />
          )}
        </View>
      )}
    </View>
  );
}

export default SleepScheduleView;
