import React, { useEffect } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import Colors from '../../constants/Colors';
import F2Chart from '../F2Chart'
import Layout, { isWeb } from '../../constants/Layout'
import { hexToRgbA } from '../../utils/ColorsUtils'
import moment from 'moment';

const styles = StyleSheet.create({
	legends: {
		marginTop: 0,
		marginLeft: 0,
		width: '100%',
		textAlign: 'center',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	legend: {
		marginRight: 2,
		flexDirection: 'row',
		textAlign: 'center'
	},
	dot: {
		fontSize: isWeb ? 20: 12,
		borderColor: '#fff',
        fontFamily: 'Spezia-Regular',
		justifyContent: 'flex-start',
		alignItems: 'center'
	},
	label: {
		marginRight: 8,
		marginLeft: 4,
		fontFamily: 'Spezia-Regular',
		color: Colors.darkBlackColor,
		fontSize: isWeb ? 20: 12,
		justifyContent: 'flex-end',
	}
})

function generateExampleData(date) {
    return Array(31).fill().map((_, index) => {
        return {
            dateString: moment("2022-08-01", "YYYY-MM-DD").add(index, 'day').format("YYYY-MM-DD"),
            value: Math.random() * 100 + 60,
        }
    })
}
const exampleData = generateExampleData()



const initScript = (data, dayLabels=[], weekLabels=[]) => `
(function(){
    chart = new F2.Chart({
        id: 'chart',
        pixelRatio: window.devicePixelRatio,
        padding: [24, 0, 'auto', ${isWeb ? 80: 64}],
    });
    let data = ${JSON.stringify(data)};
    chart.source(data, {
        month: {
            type: 'cat',
            values: [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        },
        // date: {
        //     type: 'timeCat',
        //     mask: 'ddd',
        //     range: [0, 1],
        // },
        date: {
            type: 'cat',
            values: Array(31).fill().map((_, i) => i),
        },
        week: {
            type: 'cat',
            // values: ${JSON.stringify(weekLabels)},
            values: ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5'],
        },
        day: {
            type: 'cat',
            // values: ${JSON.stringify(dayLabels)},
            values: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
        }
        // day: {
        //     type: 'timeCat',
        //     mask: 'ddd',
        //     range: [0, 1],
        //     tickCount: 7,
        // }
    });
    chart.legend('value', {
        position: 'bottom',
        align: 'left',
        itemFormatter: function itemFormatter(val) {
            if (!val) {
                return 'No data'
            }
            if (val > 85) {
                return '>85% efficiency'
            }
            return '<85% efficiency'
        },
        nameStyle: {
            fill: '${Colors.darkLightColor}',
            fontFamily: 'Teodor-Light',
            fontSize: '12',
        }, 
    })
    chart.axis('day', {
        line: null,
        grid: null,
        label: function label(text, index) {
            return {
                fill: ${JSON.stringify(Colors.whiteColor)},
                fontFamily: 'SpeziaNarrow-SemiBold',
                fontSize: ${isWeb ? 20: 14}
            }
        }
    })
    chart.axis('week', {
        line: null,
        grid: null,
        label: function label(text, index) {
            return {
                fill: ${JSON.stringify(Colors.whiteColor)},
                fontFamily: 'SpeziaNarrow-SemiBold',
                fontSize: ${isWeb ? 20: 14},
                offsetX: -8
            }
        }
    })
    data.map((item, i) => {
        chart.guide().text({
            position: [item.day, item.week],
            content: i + 1,
			style: {
				fill: ${JSON.stringify(Colors.whiteColor)},
				fontFamily: 'SpeziaExtended-Medium',
				textAlign: 'left',
				textBaseline: 'top',
				fontWeight: 'bold',
				fontSize: 16
			},
            offsetY: -16,
            offsetX: ${isWeb ? -32: -12}
        })
    })
    chart.polygon()
    .position('day*week')
    .color('value*highlight', (value, highlight) => {
        // if (highlight == -1) {
        //     return '${Colors.secondaryColor}'
        // }
        // if (highlight == 1) {
        //     return '${Colors.shadowColor}'
        // }
        if (!value) {
            return '#e1e1fb'
        }
        if (value > 85) {
            return '#66cc91'
        }
        if (value > 80) {
            return '#c8ffba'
        }
        if (value < 80) {
            return '#FEE1C0'
        }
        return ['#e1e1fb', '#FEE1C0', '#79d9d0']
    })
    .style({
        lineWidth: 4,
        stroke: '#fff'
    })
    .animate({
        appear: {
            duration: 800
        }
    });
    chart.render();
})();
`;

export default function HeatmapChart(props) {
    const { data, minDate, maxDate } = props
    const onMessage = event => {
		const {
			nativeEvent: { data }
		} = event;
        if (data === 'ERROR:CHART_RENDER') {
            console.log("ERROR RENDERING CHART")
        }
	};

    let emptyData = exampleData.map((d, i) => {
        return {
            ...d,
            month: moment(d.dateString).month(),
            week: Math.floor(moment(d.dateString).date()/7),
            day: moment(d.dateString).day(),
            date: moment(d.dateString).date(),
            highlight: 0,
            value: null,
            // moment.utc(d.dateString).format("YYYY-MM-DD") == minDate ? -1: moment.utc(d.date).format("YYYY-MM-DD") == maxDate ? 1: 0,
            // value: i*3
        }
    })
    function unique(array, propertyName) {
        return array.filter((e, i) => array.findIndex(a => a[propertyName] === e[propertyName]) === i);
    }
    console.log("longestDurationDateString", maxDate)
    console.log("shortestDurationDateString", minDate)

    // TODO: find a way to flip the data so the chart renders the first date at the top left, instead of bottom left
    let source = data.map((d) => {
        if (moment.utc(d.date).format("YYYY-MM-DD") == minDate) {
            console.log("found min date:", d)
        }
        if (moment.utc(d.date).format("YYYY-MM-DD") == maxDate) {
            console.log("found max date:", d)
        }
        return {
            ...d,
            dateString: moment.utc(d.date).format("YYYY-MM-DD"),
            month: moment.utc(d.date).month(),
            week: Math.floor(moment.utc(d.date).date()/7),
            day: moment.utc(d.date).day(),
            dayOfWeek: moment.utc(d.date).format("ddd"),
            date: moment.utc(d.date).date(),
            highlight: moment.utc(d.date).format("YYYY-MM-DD") == minDate ? -1: moment.utc(d.date).format("YYYY-MM-DD") == maxDate ? 1: 0
        }
    })

    
    let filledData = unique([...source, ...emptyData], 'dateString').sort((a, b) => { return a.date - b.date})
    // console.log("filledData", filledData)
    let legends = [
        {
            legend: 'No data',
            color: "#e1e1fb"

        },
        {
            legend: '<85% efficiency',
            color: "#FEE1C0"
        },
        {
            legend: '>85% efficiency',
            color: "#66cc91"
            
        },
    ]

    console.log("filledData", filledData)

    // let dayLabels = sortedDates.map((d) => { return moment(d.dateString).format("ddd, MMM DD") })
    // let weekLabels = Array(5).fill().map((_, i) => { return `Week ${i+1}` })

    return (
        <View style={{
            width: isWeb ? Layout.window.width/2: Layout.window.width - 80,
            justifyContent: "center",
            alignSelf: "center",
            alignItems: "center",
            height: 320,
        }}>
            <View style={styles.legends}>
                {legends.map((item, i) => {
                    return (<View style={styles.legend} key={i}>
                        <Text style={[styles.dot, { 'color': item.color }]}>■</Text>
                        <Text style={[styles.label, { color: Colors.lightColor }]}>{item.legend}</Text>
                    </View>)
                })}
            </View>
            <F2Chart 
                initScript={initScript(filledData, )} 
                onMessage={onMessage}
            />
        </View>
        
    )
}