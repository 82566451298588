import React, { PureComponent, createRef } from "react";
import { View, StyleSheet, Platform } from "react-native";
import { WebView as RNWebView } from 'react-native-webview';

const changeData = data => `chart.changeData(${JSON.stringify(data)});`;

const source = Platform.select({
	web: {
		html: require("../../assets/f2chart.html")
	},
	ios: require("../../assets/f2chart.html"),
	android: { uri: "file:///android_asset/f2chart.html" }
});

// type Props = {
// 	initScript: string,
// 	data?: Array<Object>,
// 	onChange?: Function,
// 	webView?: any
// };

export default class F2Chart extends PureComponent {
	static defaultProps = {
		onChange: () => { },
		initScript: "",
		data: [],
		webView: RNWebView
	};

	constructor(props) {
		super(props);
		this.chart = createRef();
		this.update = this.update.bind(this);
	}

	componentDidUpdate(nextProps, nextState) {
		const { data } = this.props;
		if (data !== nextProps.data) {
			// we want to update the chart's data and repaint if there's new data available
			this.update(nextProps.data);
			this.repaint(nextProps.initScript)
		}
	}

	update = data => {
		this.chart.current.injectJavaScript(changeData(data));
	};

	repaint = script => this.chart.current.injectJavaScript(script);

	onMessage = event => {

		const {
			nativeEvent: { data }
		} = event;
		const { onChange } = this.props;
		// const tooltip = JSON.parse(data);
		if (onChange) onChange(data);
	};

	render() {
		const {
			webView: WebView,
			data,
			onChange,
			initScript,
			...props
		} = this.props;

		return (
			<RNWebView
				javaScriptEnabled
				ref={this.chart}
				scrollEnabled={false}
				style={styles.webView}
				source={source}
				// newWindow={true}
				injectedJavaScript={initScript}
				originWhitelist={["*"]}
				onMessage={props.onMessage ?? this.onMessage}
				onLoad={props.onChartLoad}
				{...props}
			/>
		);
	}
}

const styles = StyleSheet.create({
	webView: {
		flex: 1,
		backgroundColor: "transparent"
	}
});