import React from 'react';
import {View, Text, StyleSheet} from 'react-native';
import {theme} from '../../styles/theme';

const styles = StyleSheet.create({
  dataValue: {
    fontFamily: 'TitilliumWeb-bold',
    fontSize: 22,
    color: '#FFF',
  },
  absolutelyPositioned: {
    position: 'absolute',
  },
  durationContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  secondsValue: {
    ...theme.typography.h5,
    color: theme.palette.white,
  },
  paddingTopMd: {
    paddingTop: 16,
  },
  paddingTopSm: {
    paddingTop: 8,
  },
});

/*
  Modelled after Whoops duration UI for activities
  Making the seconds portion of the string smaller relative to minutes/hours

*/
export const Duration = props => {
  if (props.duration.endsWith('s')) {
    return (
      <View style={styles.durationContainer}>
        <Text
          style={[
            styles.dataValue,
            styles.paddingTopSm,
            styles.absolutelyPositioned,
          ]}>
          {props.duration.split(':').map((val, i) => {
            if (!i) {
              return val;
            } else {
              return (
                <Text
                  key={i}
                  style={[styles.secondsValue, styles.paddingTopSm]}>
                  :{val}
                </Text>
              );
            }
          })}
        </Text>
      </View>
    );
  }

  return (
    <Text style={[styles.dataValue, styles.paddingTopSm]}>
      {props.duration}
    </Text>
  );
};
