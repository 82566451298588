import moment from 'moment';

/*
  Moment might a better way to do this, but suitable for now
*/

export const getDuration = time => {
  const duration = moment.duration(time);
  if (duration.asHours() >= 1) {
    return moment.utc(duration.asMilliseconds()).format('H:mm');
  } else if (duration.asMinutes() >= 1) {
    return moment.utc(duration.asMilliseconds()).format('mm:ss[s]');
  } else {
    return moment.utc(duration.asMilliseconds()).format('ss[s]');
  }
};

export const getDurationBetweenPoints = (start, end) =>
  getDuration(moment(end).diff(moment(start)));

export const calendarOptions = {
  lastDay: '[Yesterday]',
  sameDay: '[Today]',
  nextDay: 'MMM D',
  lastWeek: 'MMM D',
  nextWeek: 'MMM D',
  sameElse: 'MMM D',
};
