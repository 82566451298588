import React from 'react';
import {View, StyleSheet, Text} from 'react-native';
import MaterialIcon from 'react-native-vector-icons/MaterialIcons';
import {theme} from '../../styles/theme';

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    paddingBottom: 32,
  },
  sleepImpactHeader: {
    ...theme.typography.h5,
    textAlign: 'center',
    color: theme.palette.white,
  },
  sleepImpactText: {
    ...theme.typography.b1,
    color: theme.palette.white,
  },
});

export const SleepImpact = ({pending}) => {
  if (pending) {
    return (
      <View style={styles.container}>
        <MaterialIcon name="equalizer" size={72} color={theme.palette.white} />
        <Text style={styles.sleepImpactHeader}>Sleep Impact Pending</Text>
        <Text style={styles.sleepImpactText}>
          The impact of this activity on your sleep will be calculated
          overnight, check back tomorrow to see how it stacks up!
        </Text>
      </View>
    );
  }

  return null;
};
