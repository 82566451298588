import _ from 'lodash';
const jStat = require('jStat').jStat;

export const sum = numbers => {
  return _.reduce(numbers, (a, b) => a + b, 0);
};

export function unique(array, propertyName) {
  return array?.filter(
    (e, i) => array.findIndex(a => a[propertyName] === e[propertyName]) === i,
  );
}

export const average = numbers => {
  return sum(numbers) / (numbers.length || 1);
};

export const make_window = (before, after) => {
  return (_number, index, array) => {
    const start = Math.max(0, index - before);
    const end = Math.min(array.length, index + after + 1);
    return _.slice(array, start, end);
  };
};

export const moving_average = (before, after, numbers) => {
  return _.chain(numbers).map(make_window(before, after)).map(average).value();
};

export const pValueFromPearsonR = (r, N) => {
  const tScore = (r * Math.sqrt(N - 2)) / Math.sqrt(1 - r ** 2);
  const negative = tScore < 0;
  const twoTailed = false;
  const pVal = negative
    ? jStat.studentt.cdf(tScore, N - 1)
    : jStat.studentt.cdf(-tScore, N - 1);
  const twoTailedPVal = negative ? 2 - (1 - pVal) * 2 : pVal * 2;
  const finalP = twoTailed ? twoTailedPVal : pVal;
  return finalP < 0.05 ? finalP.toPrecision(2) : finalP.toFixed(3);
  //  + 't: ' + tScore + 'N: ' + N
  // return tScore.toFixed(3) + 'N: ' + N
};

export const maxFromData = data => {
  var rawData = data[0].data;
  if (rawData.length == 0 && data.length == 2) {
    rawData = data[1].data;
  }
  const max = Math.max(...rawData.map(data => data.y));
  return max;
};

export const averageFromData = data => {
  var rawData = data[0].data;
  if (rawData.length == 0 && data.length == 2) {
    rawData = data[1].data;
  }
  const totalAverage =
    rawData.map(data => data.y).reduce((a, b) => a + b, 0) / rawData.length;
  return totalAverage;
};

export const computeTrendFromTimeseriesData = data => {
  var diff = 0;
  var percentChange = 0;
  if (data.length >= 2) {
    const rawData = data[1].data;
    if (rawData.length > 2) {
      diff = rawData.slice(-1)[0].y - rawData[0].y;
      percentChange =
        (diff / ((rawData.slice(-1)[0].y + rawData[0].y) / 2)) * 100;
    }
  } else if (data.length == 1) {
    const rawData = data[0].data;
    if (rawData.length > 2) {
      diff = rawData.slice(-1)[0].y - rawData[0].y;
      percentChange =
        (diff / ((rawData.slice(-1)[0].y + rawData[0].y) / 2)) * 100;
    }
  }

  return { diff, percentChange };
};
