import React, {useMemo, useState} from 'react';
import {TouchableOpacity, View, Text, StyleSheet} from 'react-native';
// import {Navigation} from 'react-native-navigation';
import moment from 'moment';
import Icon from 'react-native-vector-icons/Feather';
import LottieView from 'lottie-react-native';

import {Duration} from '../duration';
import {Heartbeat} from '../icons/max-hr';
import {Heart} from '../icons/heart';
import {ECG} from '../icons/ecg';
import {Clock} from '../icons/clock';
import {createInitScript, getChartConfig} from '../intra-day-heart-rate-chart';
import {HeartRateChart} from '../heart-rate-chart';

import {theme} from '../../styles/theme';
import {SleepImpact} from '../sleep-impact';
import {ActivityIcon} from '../activity';
import {
  getDuration,
  getDurationBetweenPoints,
  calendarOptions,
} from '../../utils/time';
import {
  exponentialMovingAverage,
  getStandardDeviation,
} from '../../utils/stats';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: theme.palette.background,
  },
  sleepImpactContainer: {
    minHeight: '20%',
    paddingTop: 40,
  },
  subheadingText: {
    ...theme.typography.h5,
    color: theme.palette.tertiary.main,
  },
  subheadingDate: {
    ...theme.typography.date,
    textAlign: 'center',
  },
  iconContainer: {
    position: 'absolute',
    top: 0,
    right: 32,
    zIndex: 99,
  },
  close: {
    position: 'absolute',
    top: 4,
    right: 0,
  },
  icon: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  chart: {
    marginTop: 16,
    minHeight: '40%',
  },
  informationRow: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    paddingTop: 16,
    paddingHorizontal: 16,
  },
  dataCell: {
    alignItems: 'center',
  },
  dataHeader: {
    fontFamily: 'TitilliumWeb-bold',
    fontSize: 12,
    color: theme.palette.white,
  },
  dataValue: {
    fontFamily: 'TitilliumWeb-bold',
    fontSize: 22,
    color: '#FFF',
  },
  paddingTopSm: {
    paddingTop: 8,
  },
  headerDetail: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 18,
  },
  headerDetailText: {
    paddingLeft: 16,
    alignItems: 'baseline',
  },
  errorContainer: {
    marginTop: 80,
    alignItems: 'center',
  },
  errorComponent: {
    width: '60%',
    alignSelf: 'center',
  },
  errorHeader: {
    ...theme.typography.h5,
    textAlign: 'center',
    color: theme.palette.white,
  },
  errorText: {
    ...theme.typography.b1,
    color: theme.palette.white,
    maxWidth: '80%',
  },
});

const DataCell = props => (
  <View style={styles.dataCell}>
    {props.children}
    <Text style={[styles.dataHeader, styles.paddingTopSm]}>{props.title}</Text>
    {React.isValidElement(props.value) ? (
      props.value
    ) : (
      <Text style={[styles.dataValue, styles.paddingTopSm]}>{props.value}</Text>
    )}
  </View>
);

/*
  Given the two modes this graph is surfaced in, I don't think we need a loading stage
  - when the user opens the app, we do the fetching and triggering of the modal in the background
    so will already have the data
  - launching from the calendar view, data is already presumably in state
*/

export const ErrorState = () => {
  return (
    <View style={styles.errorContainer}>
      <LottieView
        style={styles.errorComponent}
        source={require('../empty-state/empty.json')}
        autoPlay
        loop
      />
      <Text style={styles.errorHeader}>Oh no!</Text>
      <Text style={styles.errorText}>
        Something went wrong loading this chart.
      </Text>
    </View>
  );
};

const WINDOW = 10;

const getFormattedValues = (values, ema, stddev, window) => {
  const slidingWindow = window - 4;
  return values.map((val, i) => {
    if (i < slidingWindow) {
      return {
        ...val,
        averageRangeStart: null,
        averageRangeEnd: null,
      };
    }

    return {
      ...val,
      averageRangeStart: ema[i - slidingWindow] - stddev / 2,
      averageRangeEnd: ema[i - slidingWindow] + stddev / 2,
    };
  });
};

export default function ActivityModal(props) {
  const {activity, componentId} = props;
  const {
    workout: {start, end, activityName},
    heartRate: {average, max, values, timeToBaseline, baseLine},
    sleepAnalysis: {pending},
  } = activity ?? {
    workout: {},
    heartRate: {values: []},
    sleepAnalysis: {pending: true},
  };

  const duration = getDurationBetweenPoints(start, end);
  const timeToBaselineFormatted = getDuration(timeToBaseline);
  const [chartError, setChartError] = useState(false);
  /*
    We could pull all of this out into another hook, the end result of these calculations are memo'd so the chart will only re render when they change
    But we could also memo these calculations to reduce compute time, although its currently negligble
  */
  const rawValues = values.map(val => val.value);
  const ema = exponentialMovingAverage(rawValues, WINDOW);
  const stddev = getStandardDeviation(rawValues);
  const maxValueIndex = rawValues.reduce(
    (iMax, x, i, arr) => (x > arr[iMax] ? i : iMax),
    0,
  );
  const formattedValues = getFormattedValues(values, ema, stddev, WINDOW);
  const avgPercentile = 0.2;

  // TODO: create function for average as a proportion
  // .color('y', (y) => {
  //   if (${isGoodTrend(trend, 1)}) {
  //     return 'l(90) 0:${Colors.brightTintColor} 0.5:${color} 1:${color}';
  //   }
  //   if (${isGoodTrend(trend, -1)}) {
  //     return 'l(90) 0:${color} 0.5:${color} 1:${Colors.brightTintColor}';
  //   }
  //   return ${JSON.stringify(color)};
  // })
  // stabilising this reference so we can control when the chart re-renders
  const chartConfig = React.useMemo(
    () =>
      createInitScript({
        lineColorString: `l(90) 0:#DC8CAE ${avgPercentile}:#C0D6FE 1:#FFF`,
        values: formattedValues,
        config: getChartConfig({
          values: formattedValues,
          options: {
            xKey: 'timestamp',
            yKey: 'value',
            min: baseLine - 15,
            max: 230,
          },
        }),
        position: 'timestamp*value',
        lines: [
          {
            start: [0, average],
            end: ['max', average],
            style: {
              lineWidth: 3,
              stroke: theme.palette.heart.main,
              lineDash: [5, 8],
            },
          },
        ],
        rects: [
          {
            start: ['min', baseLine - 2],
            end: ['max', baseLine + 2],
            style: {
              fill: '#C0D6FE',
              fillOpacity: .5,
            },
          },
          ...formattedValues
            .map((datum, i) => {
              if (i % 2) {
                return null;
              }
              return {
                start: [i, datum.averageRangeStart],
                end: [i + 1, datum.averageRangeEnd],
                style: {
                  fill: theme.palette.tertiary.main,
                  fillOpacity: 0.25,
                },
              };
            })
            .filter(val => !!val),
        ],
        points: [
          {
            position: [maxValueIndex, max],
            style: {
              fill: theme.palette.white,
              r: 3,
              lineWidth: 1,
              stroke: theme.palette.heart.main,
            },
          },
        ],
        text: [
          {
            position: ['min', average],
            content: `${Math.floor(average)}`,
            style: {
              fill: theme.palette.heart.main,
              fontFamily: 'Avenir-Book',
              textAlign: 'center',
              textBaseline: 'top',
              fontWeight: 'bold',
              fontSize: 10,
            },
            offsetX: -16,
            offsetY: -20,
          },
        ],
      }),
    [formattedValues, baseLine, average, maxValueIndex, max],
  );

  return (
    <View style={styles.container}>
      <TouchableOpacity
        style={styles.iconContainer}
        onPress={() => {}}>
        <Icon
          style={styles.close}
          name="chevron-down"
          color={theme.palette.lightColor}
          size={32}
        />
      </TouchableOpacity>
      <View style={styles.headerDetail}>
        <ActivityIcon activityName={activityName} />
        <View style={styles.headerDetailText}>
          <Text style={styles.subheadingText}>{`${activityName}`}</Text>
          <Text style={[styles.subheadingDate]}>
            {`${moment(end).calendar(null, calendarOptions)}`}
          </Text>
        </View>
      </View>
      <View style={styles.sleepImpactContainer}>
        <SleepImpact pending={pending} />
      </View>
      {chartError ? (
        <ErrorState />
      ) : (
        <>
          <HeartRateChart
            chartConfig={chartConfig}
            title="Heart rate"
            subText={`${moment(start).format('H:mm')} - ${moment(end).format(
              'H:mm A',
            )}`}
            duration={duration}
            onError={() => setChartError(true)}
          />
          <View style={styles.informationRow}>
            <DataCell title="AVG HR" value={Number(average).toFixed(0)}>
              <Heart />
            </DataCell>
            <DataCell title="MAX HR" value={max}>
              <Heartbeat />
            </DataCell>
            <DataCell
              title="HR TTB"
              value={<Duration duration={timeToBaselineFormatted} />}>
              <Clock size={32} />
            </DataCell>
            <DataCell title="Baseline" value={'53 - 57'}>
              <ECG />
            </DataCell>
          </View>
        </>
      )}
    </View>
  );
}
