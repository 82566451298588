import React from 'react';

import FA5Icon from 'react-native-vector-icons/FontAwesome5';
import MaterialCommunityIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import Ionicons from 'react-native-vector-icons/Ionicons';
import {theme} from '../../styles/theme';

export const ActivityIcon = props => {
  switch (props.activityName?.toLowerCase() ?? '') {
    case 'sleeping':
      return (
        <MaterialCommunityIcon
          name="sleep"
          color={theme.palette.white}
          size={24}
        />
      );
    case 'running':
      return <FA5Icon name="running" color={theme.palette.white} size={36} />;
    case 'meditation':
      return (
        <MaterialCommunityIcon
          name="meditation"
          color={theme.palette.white}
          size={36}
        />
      );
    default:
      // or other suitable default icon when no activity matches
      return (
        <Ionicons name="man-sharp" color={theme.palette.white} size={36} />
      );
  }
};
