import Colors from '../constants/Colors';

export const palette = {
  background: Colors.darkBlackColor,
  blackColor: Colors.darkBlackColor,
  whiteColor: '#fff',
  lightColor: Colors.lightColor,
  backgroundColor: Colors.darkTintColor,
  primaryColor: Colors.shadowColor,
  secondaryColor: Colors.orangeColor,
  lightBlueColor: Colors.lightBlueColor,
  lavenderColor: Colors.lavenderColor,
  primary: {},
  secondary: {},
  tertiary: {
    main: '#79d8cf',
  },
  heart: {
    main: '#ff9a9a',
    accent: '#ff4d4d',
  },
};

export const colors = {
  ...palette,
  backgroundColor: palette.backgroundColor,
  paragraphTextColor: palette.blackColor,
  headingTextColor: palette.blackColor,
  primaryButtonBackground: palette.primaryColor,
  primaryButtonTextColor: palette.lightColor,
  secondaryButtonTextColor: palette.primaryColor,
};
export const themedColors = {
  default: {
    ...colors,
  },
  light: {
    ...colors,
  },
  dark: {
    ...colors,
    backgroundColor: palette.blackColor,
    headingTextColor: palette.lightColor,
    paragraphTextColor: palette.lightColor,
    primaryButtonTextColor: palette.lightColor,
  },
};

export const typography = {
  fontFamily: {
    bold: 'TitilliumWeb-Bold',
    regular: 'Spezia-Regular',
    light: 'TitilliumWeb-Light',
    thin: 'TitilliumWeb-Thin',
  },
  h1: {
    fontSize: 32,
    lineHeight: 40,
    fontFamily: 'TitilliumWeb-Bold',
  },
  h2: {
    fontSize: 26,
    lineHeight: 33,
    fontFamily: 'TitilliumWeb-Bold',
  },
  h3: {
    fontSize: 22,
    lineHeight: 31,
    fontFamily: 'TitilliumWeb-Bold',
  },
  h4: {
    fontSize: 19,
    lineHeight: 26,
    fontFamily: 'Spezia-Regular',
  },
  h5: {
    fontSize: 16,
    fontFamily: 'SpeziaExtended-SemiBold',
  },
  h6: {
    fontSize: 14,
    lineHeight: 20,
    fontFamily: 'Spezia-Regular',
  },
  h7: {
    fontSize: 14,
    lineHeight: 20,
    fontFamily: 'Spezia-Regular',
  },
  smallTitle: {
    fontSize: 16,
  },
  b1: {
    fontSize: 14,
    lineHeight: 22,
    fontFamily: 'Spezia-Regular',
  },
  b2: {
    fontSize: 12,
    lineHeight: 18,
    fontFamily: 'Spezia-Regular',
  },
  date: {
    fontSize: 12,
    lineHeight: 14,
    color: palette.whiteColor,
    fontFamily: 'TitilliumWeb-Bold',
  },
};

export const fonts = {
  FONT_FAMILY_LIGHT: 'Spezia-Regular',
  FONT_FAMILY_REGULAR: 'Spezia-Regular',
  FONT_FAMILY_HEAVY: 'SpeziaExtended-SemiBold',
  FONT_SIZE_SMALL: 14,
  FONT_SIZE_MEDIUM: 16,
  FONT_SIZE_LARGE: 18,
  FONT_SIZE_XLARGE: 24,
  FONT_SIZE_XXLARGE: 32,
  FONT_WEIGHT_LIGHT: '200',
  FONT_WEIGHT_MEDIUM: '600',
  FONT_WEIGHT_HEAVY: '800',
};
