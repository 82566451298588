export const isTimestampAttribute = (attributeKey) => {
	return attributeKey.indexOf('_time') > -1
}

export const HABIT_ID_TO_NAME = (id, experimentName) => {
  if (!id) {
    console.log('No habit id', id);
  }
  if (experimentName && experimentName.split('(')[0]) {
    return experimentName.split('(')[0];
  } else {
    switch (id) {
      case 'meditation':
        return 'Meditation';
      case 'magnesium-bed':
        return 'Magnesium';
      case 'mct-oil':
        return 'MCT oil';
      case 'blue-blocking-glasses':
        return 'Blue light blocking glasses';
      case 'im-fasting':
        return 'Intermittent fasting';
      case 'pink-noise':
        return 'Pink noise';
      case 'weighted-blanket':
        return 'Weighted blanket ';
      case 'chamomile-tea':
        return 'Chamomile tea';
      case 'sleeping-mask':
        return 'Sleeping mask';
      case 'earplugs':
        return 'Earplugs';
      case 'no-electronics-bed':
        return 'No electronics before bed';
      case 'consistent-schedule':
        return 'Consistent sleep schedule';
      case 'no-alcohol':
        return 'Alcohol before bed';
      case 'melatonin':
        return 'Melatonin';
      case 'coffee':
        return 'Coffee timing';
      case 'core-temperature':
        return 'Body temperature before bed';
      case 'reishi-mushroom':
        return 'Reishi mushroom';
      case 'lavender-oil':
        return 'Lavender oil';
      case 'valerian-root':
        return 'Valerian root';
      case 'nootropic':
        return 'Nootropic';
      case 'exercise':
        return 'Increased exercise';
      case 'consistent-sleep-schedule':
        return 'Consistent sleep schedule';
      case 'bedtime-guidance':
        return 'Bedtime guidance';
      case 'yoga':
        return 'Yoga routine';
      case 'journal':
        return 'Journaling';
      case 'custom-supplement':
        if (experimentName && experimentName.split('(')[0]) {
          return experimentName.split('(')[0];
        }
        return 'Custom habit';
      default:
        return id?.charAt(0)?.toUpperCase() + id?.slice(1);
    }
  }
};


export const metricToUnits = (metric) => {
	switch (metric) {
		case 'deep':
		case 'duration':
		case 'rem':
		case 'awake':
		case 'productive_time':
		case 'unproductive_time':
		case 'light':
			return "hrs"
		case 'productive_percentage':
		case 'unproductive_percentage':
		case 'efficiency':
			return "%"
		case 'onset_latency':
			return "minutes"
		case 'restless':
			return "%"
		case 'breathing_rate':
		case 'breath_average':
			return " / min"
		case 'breath_average':
			return "/ min"
		case 'temperature_delta':
			return "°C"
		case 'resting_heart_rate':
			return " bpm"
		case 'heart_rate_variability_RMSSD':
			return " ms"
		default:
			return ""
	}
}

export const attributeToExperiment = (attributeKey) => {
	switch (attributeKey) {
		case "yoga_minutes":
		case "yoga_duration":
			return 'yoga'
		case "activity_duration":
			return 'exercise'
		case "supplement_usage":
			return 'custom-supplement'
		case "neurofeedback_duration":
			return 'neurofeedback'
		case "cbd_usage_time":
		case "cbd_amount":
			return 'cbd-bed'
		case "MCT_oil_amount":
		case "MCT_oil_usage_time":
			return 'mct-oil'
		case "coffee_amount":
			return 'coffee'
		case "last_alcohol_time":
		case "alcohol_amount":
			return 'no-alcohol'
		case "chamomile_tea_amount":
			return 'chamomile-tea'
		case "reishi_amount":
		case "reishi_usage_time":
			return 'reishi-mushroom'
		case "last_coffee_time":
			return 'coffee'
		case "chamomile_tea_usage_time":
			return 'chamomile-tea'
		case "melatonin_amount":
			return 'melatonin'
		case "valerian_root_amount":
		case "valerian_root_usage_time":
			return 'valerian-root'
		case "magnesium_amount":
			return 'magnesium-bed'
		case "meditation_minutes":
			return 'meditation'
		case "fasting_completion":
		case "fasting_start_time":
		case "fasting_end_time":
			return 'im-fasting'
		case "lavender_oil_usage":
		case "lavender_oil_usage_time":
			return 'lavender-oil'
		case "pink_noise_usage":
			return 'pink-noise'
		case "sleeping_mask_usage":
			return 'sleeping-mask'
		case "weighted_blanket_usage":
			return 'weighted-blanket'
		case "earplugs_usage":
			return 'earplugs'
		case "blue_blocking_glasses_usage":
		case "blue_blocking_glasses_usage_time":
			return 'blue-blocking-glasses'
		case "no_electronics_usage":
		case "electronics_last_usage_time":
			return 'no-electronics-bed'
		case "journaling_duration":
			return 'journaling'
		case "warm_bath_duration":
		case "cooling_pad_usage":
		case "cooling_pad_temperature":
		case "room_temperature":
			return 'core-temperature'
		case "breathwork_minutes":
		case "breathwork":
			return 'breathwork'
		default:
			console.log("NO MAPPING", attributeKey)
			return attributeKey
	}
}
