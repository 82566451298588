import React from "react";
import { useAuth0, Auth0Provider } from "@auth0/auth0-react";
import { TouchableOpacity, View, Text } from 'react-native';

export const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  return <View style={{ width: "100%", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
    <TouchableOpacity type="primary" onPress={() => loginWithRedirect({ redirectUri:window.location.origin.replace("login", "") })} style={{
          width: "60%", 
          backgroundColor: "#768fff",
      		borderRadius: 4,
          padding: 8,
          paddingLeft: 16,
          paddingRight: 16
    }}>
        <Text style={{
          fontFamily: 'SpeziaExtended-SemiBold',
          color: "#fff",
          fontSize: 20,
          textAlign: 'center',
        }}>
          Log in to view report
        </Text>
    </TouchableOpacity>
  </View>
};

export const LogoutButton = () => {
  const { logout } = useAuth0();

  return <div style={{ position: "absolute", right: '2rem', bottom: '2rem' }}>
    <Button type="dashed" onClick={() => logout({ returnTo: window.location.origin })}>Log out</Button>
  </div>
};

function redirectUrl(authUser) {

}

const AUTH0_DOMAIN = "auth.bioloopsleep.com"
const AUTH0_CLIENTID = "Nq8x1PGf1sI4lhAShexmBHqXfyxrWhMk"

// const providerConfig = {
//   domain: config.domain,
//   clientId: config.clientId,
//   ...(config.audience ? { audience: config.audience } : null),
//   redirectUri: window.location.origin,
//   onRedirectCallback,
// };

export function AuthLoading(props) {
    return <div style={{ display: "flex", height: "100vh", width: "100%", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
      <Spin size="large" />
        {`Logging you in...`}
    </div>
}

export default function AuthProvider({ children }) {
    return <Auth0Provider
    domain={AUTH0_DOMAIN}
    clientId={AUTH0_CLIENTID}
    redirectUri={window.location.origin.replace("login", "")}
  >
    {children}
  </Auth0Provider>
}
