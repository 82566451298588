import React, { PureComponent } from 'react';
import { Text, View } from 'react-native';
import Colors from '../constants/Colors'
import LinearGradient from 'react-native-linear-gradient'
import Layout from '../constants/Layout'

export const GradientText = props => (
    <View maskElement={<Text {...props}/>} style={{ }}>
        <View>
            <Text {...props} style={[props.style, { opacity: 1, width: "100%" }]} />
            <LinearGradient colors={props.colors ?? [Colors.orangeColor, Colors.shadowColor, Colors.violetColor]} start={{ x: 0, y: 0 }} end={{ x: 1, y: 0 }} style={{
                marginTop: -8,
                height: 1, 
            }}>
            </LinearGradient>
        </View>
    </View>
);
