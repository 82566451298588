import React, { useEffect } from 'react';
import { View } from 'react-native';
import Colors from '../../constants/Colors';
import F2Chart from '../F2Chart'
import Layout, { isWeb } from '../../constants/Layout'
import { hexToRgbA } from '../../utils/ColorsUtils'
import moment from 'moment';

const initScript = (data, score=326) => `
const Shape = F2.Shape;
const G = F2.G;
const Util = F2.Util;
const Global = F2.Global;
const Vector2 = G.Vector2;

Shape.registerShape('interval', 'tick', {
  draw: function draw(cfg, container) {
    const points = this.parsePoints(cfg.points);
    const style = Util.mix({
      stroke: cfg.color
    }, Global.shape.interval, cfg.style);
    if (cfg.isInCircle) {
      let newPoints = points.slice(0);
      if (this._coord.transposed) {
        newPoints = [ points[0], points[3], points[2], points[1] ];
      }

      const x = cfg.center.x;
      const y = cfg.center.y;

      const v = [ 1, 0 ];
      const v0 = [ newPoints[0].x - x, newPoints[0].y - y ];
      const v1 = [ newPoints[1].x - x, newPoints[1].y - y ];
      const v2 = [ newPoints[2].x - x, newPoints[2].y - y ];

      let startAngle = Vector2.angleTo(v, v1);
      let endAngle = Vector2.angleTo(v, v2);
      const r0 = Vector2.length(v0);
      const r = Vector2.length(v1);

      if (startAngle >= 1.5 * Math.PI) {
        startAngle = startAngle - 2 * Math.PI;
      }

      if (endAngle >= 1.5 * Math.PI) {
        endAngle = endAngle - 2 * Math.PI;
      }

      const lineWidth = r - r0;
      const newRadius = r - lineWidth / 2;

      return container.addShape('Arc', {
        className: 'interval',
        attrs: Util.mix({
          x,
          y,
          startAngle,
          endAngle,
          r: newRadius,
          lineWidth,
          lineCap: 'round'
        }, style)
      });
    }
  }
});
(function(){
    chart = new F2.Chart({
        id: 'chart',
        pixelRatio: window.devicePixelRatio,
        padding: [32, 48, 'auto'],
    });
    chart.source(${JSON.stringify(data)}, {
  y: {
    max: 100,
    min: 0
  }
});
chart.axis(false); // hide all axis
chart.tooltip(false); // hide tooltip
chart.coord('polar', {
  transposed: true,
  innerRadius: 0.8,
  radius: 0.85
}); // set polar coordinate

chart.guide().arc({
start: [ 0, 0 ],
end: [ 1, 99.98 ],
top: false,
style: {
    lineWidth: 16,
    stroke: ${JSON.stringify(Colors.shadowColor)}
}
});
chart.guide().html({
    position: [ '50%', '50%' ],
    html: '<div><p id="number" style="font-family: Teodor-Light;font-size: 48px;margin: 0;color: #fff; margin-bottom: -4px; text-align: center;">${score}</p><p style="font-family: Optima-Regular;font-size: 16px;margin: 0;color: ${Colors.lightBlueColor}; text-align: center;">nights tracked</p></div>'
}); // draw a text
chart.interval().position('x*y').size(8)
  .shape('tick').color('l(90) 0:${Colors.shadowColor} 1:${Colors.orangeColor}')
  .animate({
    appear: {
      duration: 1200,
      easing: 'cubicIn',
      animation: function animation(shape, animateCfg) {
        const startAngle = shape.attr('startAngle');
        const endAngle = shape.attr('endAngle');
        shape.attr('endAngle', startAngle);
        shape.animate().to(Util.mix({
          attrs: {
            endAngle
          }
        }, animateCfg)).onUpdate(function(frame) {
        //   ('#number').text(parseInt(frame * 85) + '%');
        });
      }
    }
  });
    chart.render();
})();
`;

export default function HeatmapChart(props) {
    const onMessage = event => {
		const {
			nativeEvent: { data }
		} = event;
        if (data === 'ERROR:CHART_RENDER') {
            console.log("ERROR RENDERING CHART")
        }
	};

    let source = [{
        x: '1',
        y: props.score ? (props.score/props.totalScore)*100 : 50
    }];


    return (
        <View style={{
            width: isWeb ? Layout.window.width/2: Layout.window.width,
            justifyContent: "center",
            alignSelf: "center",
            alignItems: "center",
            height: 240,
        }}>
            <F2Chart 
                initScript={initScript(source, props.score)} 
                onMessage={onMessage}
            />
        </View>
        
    )
}