export const getChartConfig = ({options, values}) => {
  // Could do this in a single pass with .reduce() if we thought if the time complexity was significant
  const max = options.max ?? Math.max(values.map(val => val[options.yKey]));
  const min = options.min ?? Math.min(values.map(val => val[options.yKey]));
  return {
    [options.yKey]: {
      tickCount: 4,
      min,
      max,
    },
    [options.xKey]: {
      type: 'timeCat',
      mask: 'hh:mm:ss',
      tickCount: 3,
      range: [0, 1],
    },
  };
};

export const createInitScript = ({
  values,
  config,
  position,
  shape = 'smooth',
  lines,
  rects,
  points,
  text,
  lineColorString = '#DC8CAE',
}) => `
    function render(){
        const chart = new F2.Chart({
          id: 'chart',
          pixelRatio: window.devicePixelRatio
        });

        chart.axis('value', {
          grid: null
        })

        chart.source(${JSON.stringify(values)}, ${JSON.stringify(config)});
        chart.line().position('${position}').color('${lineColorString}').shape('${shape}');

        ${lines
          .map(
            lineConfig => `chart.guide().line(${JSON.stringify(lineConfig)});`,
          )
          .join('\n\n')}
        
        ${rects
          .map(
            rectConfig => `chart.guide().rect(${JSON.stringify(rectConfig)});`,
          )
          .join('\n\n')}
          

        ${points
          .map(point => `chart.guide().point(${JSON.stringify(point)});`)
          .join('\n\n')}

        ${text
          .map(t => `chart.guide().text(${JSON.stringify(t)});`)
          .join('\n\n')}
  

          chart.render();
    }

    try {
        render();
    } catch (e) {
        window.ReactNativeWebView.postMessage('ERROR:CHART_RENDER')
    } finally {
        true;
    }
    true;
  `;
