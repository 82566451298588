import API from './api';
import moment from 'moment';

export const API_ISO_DATE_FORMAT = 'YYYY-MM-DD';

const later = (delay, value) =>
  new Promise(resolve => setTimeout(resolve, delay, value));

/* General comments
- Our member based routes don't require userId in the API endpoint
- In the backend we just scope the request to currently logged in user
- You'd need to grab this any way if you did accept a userId as you'd need
  to make sure someone wasn't making a request for data they didn't own
*/

export const getTodaysWorkoutReport = async () => {
  const startDate = moment().format(API_ISO_DATE_FORMAT);
  const endDate = moment().format(API_ISO_DATE_FORMAT);
  const userId = '5c5616128b926624fe44191b'; // would be supplied from state
  const bufferTime = 30;
  //   const response = await API.get(`/recipes/getSampleWorkouts?userId=${userId}&bufferTime=${bufferTime}&startDate=2021-08-16&endDate=2021-10-20`)
  //   return response.data
  return later(300, mockPayload);
};

const mockPayload = {
  workouts: [
    {
      date: moment().format(API_ISO_DATE_FORMAT),
      sleepAnalysis: {
        pending: true,
      },
      workout: {
        start: moment().subtract(3000, 'seconds').toISOString(),
        end: moment().subtract(1524, 'seconds').toISOString(),
        calories: 455,
        activityId: 37, // could use for icon reconcilliation?
        activityName: 'Sleeping',
        distance: 2.49,
      },
      heartRate: {
        average: 182.3684210526316,
        min: 70,
        max: 204,
        baseLine: 70,
        timeToBaseline: 154500,
        values: [
          {
            dateString: '2021-08-17',
            time: '07:07:40',
            value: 84,
            sourceId: 'com.apple.health.4846429F-5BCE-42A8-9B59-AAA23BC9F1D7',
            id: '6D17F9D0-D3BD-4CDD-BE56-23CBAEC1527C',
            sourceName: 'Jason’s Apple Watch',
            startDate: '2021-08-17T12:07:40.000-0700',
            endDate: '2021-08-17T12:07:40.000-0700',
            timestamp: 1629227260000,
          },
          {
            dateString: '2021-08-17',
            time: '07:12:09',
            value: 84,
            sourceId: 'com.apple.health.4846429F-5BCE-42A8-9B59-AAA23BC9F1D7',
            id: '63248D84-90E7-4051-AF62-7B75533EF2E3',
            sourceName: 'Jason’s Apple Watch',
            startDate: '2021-08-17T12:12:09.000-0700',
            endDate: '2021-08-17T12:12:09.000-0700',
            timestamp: 1629227529000,
          },
          {
            dateString: '2021-08-17',
            time: '07:21:02',
            value: 74,
            sourceId: 'com.apple.health.4846429F-5BCE-42A8-9B59-AAA23BC9F1D7',
            id: '1C0AD3DD-7687-424A-B58B-6CD1562E9242',
            sourceName: 'Jason’s Apple Watch',
            startDate: '2021-08-17T12:21:02.999-0700',
            endDate: '2021-08-17T12:21:02.999-0700',
            timestamp: 1629228062999,
          },
          {
            dateString: '2021-08-17',
            time: '07:24:40',
            value: 74,
            sourceId: 'com.apple.health.4846429F-5BCE-42A8-9B59-AAA23BC9F1D7',
            id: '39374F5D-99F2-47CE-9571-724466634C77',
            sourceName: 'Jason’s Apple Watch',
            startDate: '2021-08-17T12:24:40.999-0700',
            endDate: '2021-08-17T12:24:40.999-0700',
            timestamp: 1629228280999,
          },
          {
            dateString: '2021-08-17',
            time: '07:30:37',
            value: 100,
            sourceId: 'com.apple.health.4846429F-5BCE-42A8-9B59-AAA23BC9F1D7',
            id: 'A1171DF2-F1F4-43D6-81E5-CC6E437949CE',
            sourceName: 'Jason’s Apple Watch',
            startDate: '2021-08-17T12:30:37.101-0700',
            endDate: '2021-08-17T12:30:37.101-0700',
            timestamp: 1629228637101,
          },
          {
            dateString: '2021-08-17',
            time: '07:35:10',
            value: 102,
            sourceId: 'com.apple.health.4846429F-5BCE-42A8-9B59-AAA23BC9F1D7',
            id: '8B7CE697-1F59-40D2-9B0C-C1F7DFF66E28',
            sourceName: 'Jason’s Apple Watch',
            startDate: '2021-08-17T12:35:10.886-0700',
            endDate: '2021-08-17T12:35:10.886-0700',
            timestamp: 1629228910886,
          },
          {
            dateString: '2021-08-17',
            time: '07:35:13',
            value: 104,
            sourceId: 'com.apple.health.4846429F-5BCE-42A8-9B59-AAA23BC9F1D7',
            id: '2C68FC20-BF8D-4EEC-ABCB-799FC2BA6F1B',
            sourceName: 'Jason’s Apple Watch',
            startDate: '2021-08-17T12:35:13.886-0700',
            endDate: '2021-08-17T12:35:13.886-0700',
            timestamp: 1629228913886,
          },
          {
            dateString: '2021-08-17',
            time: '07:35:18',
            value: 99,
            sourceId: 'com.apple.health.4846429F-5BCE-42A8-9B59-AAA23BC9F1D7',
            id: '02EF45B1-D21B-4B0A-A6E3-015B2FD32BC9',
            sourceName: 'Jason’s Apple Watch',
            startDate: '2021-08-17T12:35:18.886-0700',
            endDate: '2021-08-17T12:35:18.886-0700',
            timestamp: 1629228918886,
          },
          {
            dateString: '2021-08-17',
            time: '07:36:07',
            value: 201,
            sourceId: 'com.apple.health.4846429F-5BCE-42A8-9B59-AAA23BC9F1D7',
            id: '91FEB226-B284-4B60-930A-A85005C19B0D',
            sourceName: 'Jason’s Apple Watch',
            startDate: '2021-08-17T12:36:07.886-0700',
            endDate: '2021-08-17T12:36:07.886-0700',
            timestamp: 1629228967886,
          },
          {
            dateString: '2021-08-17',
            time: '07:36:39',
            value: 202,
            sourceId: 'com.apple.health.4846429F-5BCE-42A8-9B59-AAA23BC9F1D7',
            id: '082EBD9F-2BD4-421B-BAE4-6CE04BDB85A0',
            sourceName: 'Jason’s Apple Watch',
            startDate: '2021-08-17T12:36:39.886-0700',
            endDate: '2021-08-17T12:36:39.886-0700',
            timestamp: 1629228999886,
          },
          {
            dateString: '2021-08-17',
            time: '07:36:43',
            value: 202,
            sourceId: 'com.apple.health.4846429F-5BCE-42A8-9B59-AAA23BC9F1D7',
            id: 'B5DF4589-6837-409F-9B3B-CFFFE9262344',
            sourceName: 'Jason’s Apple Watch',
            startDate: '2021-08-17T12:36:43.886-0700',
            endDate: '2021-08-17T12:36:43.886-0700',
            timestamp: 1629229003886,
          },
          {
            dateString: '2021-08-17',
            time: '07:37:23',
            value: 187,
            sourceId: 'com.apple.health.4846429F-5BCE-42A8-9B59-AAA23BC9F1D7',
            id: '0074C144-9D78-475C-97A0-5BC59EBD95AA',
            sourceName: 'Jason’s Apple Watch',
            startDate: '2021-08-17T12:37:23.511-0700',
            endDate: '2021-08-17T12:37:23.511-0700',
            timestamp: 1629229043511,
          },
          {
            dateString: '2021-08-17',
            time: '07:37:26',
            value: 187,
            sourceId: 'com.apple.health.4846429F-5BCE-42A8-9B59-AAA23BC9F1D7',
            id: '2FA0C6BE-A6D4-49C8-AC28-3EA19B78A851',
            sourceName: 'Jason’s Apple Watch',
            startDate: '2021-08-17T12:37:26.511-0700',
            endDate: '2021-08-17T12:37:26.511-0700',
            timestamp: 1629229046511,
          },
          {
            dateString: '2021-08-17',
            time: '07:37:53',
            value: 191,
            sourceId: 'com.apple.health.4846429F-5BCE-42A8-9B59-AAA23BC9F1D7',
            id: '6F8E1941-4F5F-4B6B-962C-785CA95D801C',
            sourceName: 'Jason’s Apple Watch',
            startDate: '2021-08-17T12:37:53.511-0700',
            endDate: '2021-08-17T12:37:53.511-0700',
            timestamp: 1629229073511,
          },
          {
            dateString: '2021-08-17',
            time: '07:37:54',
            value: 191,
            sourceId: 'com.apple.health.4846429F-5BCE-42A8-9B59-AAA23BC9F1D7',
            id: '848C8EB9-BD6B-4059-A11C-63A83C8CD73F',
            sourceName: 'Jason’s Apple Watch',
            startDate: '2021-08-17T12:37:54.511-0700',
            endDate: '2021-08-17T12:37:54.511-0700',
            timestamp: 1629229074511,
          },
          {
            dateString: '2021-08-17',
            time: '07:38:00',
            value: 194,
            sourceId: 'com.apple.health.4846429F-5BCE-42A8-9B59-AAA23BC9F1D7',
            id: 'F974D8FE-F59B-4205-A803-3D0291A0E48F',
            sourceName: 'Jason’s Apple Watch',
            startDate: '2021-08-17T12:38:00.511-0700',
            endDate: '2021-08-17T12:38:00.511-0700',
            timestamp: 1629229080511,
          },
          {
            dateString: '2021-08-17',
            time: '07:38:08',
            value: 197,
            sourceId: 'com.apple.health.4846429F-5BCE-42A8-9B59-AAA23BC9F1D7',
            id: '427567B1-4DE5-4EA7-AF96-FB44D65FC7A8',
            sourceName: 'Jason’s Apple Watch',
            startDate: '2021-08-17T12:38:08.511-0700',
            endDate: '2021-08-17T12:38:08.511-0700',
            timestamp: 1629229088511,
          },
          {
            dateString: '2021-08-17',
            time: '07:38:11',
            value: 198,
            sourceId: 'com.apple.health.4846429F-5BCE-42A8-9B59-AAA23BC9F1D7',
            id: '8EE65D05-2889-4ABE-822C-2BFB146F85CA',
            sourceName: 'Jason’s Apple Watch',
            startDate: '2021-08-17T12:38:11.511-0700',
            endDate: '2021-08-17T12:38:11.511-0700',
            timestamp: 1629229091511,
          },
          {
            dateString: '2021-08-17',
            time: '07:38:14',
            value: 199,
            sourceId: 'com.apple.health.4846429F-5BCE-42A8-9B59-AAA23BC9F1D7',
            id: 'C93E2A7F-24A5-4D03-8D39-98A7C21AB018',
            sourceName: 'Jason’s Apple Watch',
            startDate: '2021-08-17T12:38:14.511-0700',
            endDate: '2021-08-17T12:38:14.511-0700',
            timestamp: 1629229094511,
          },
          {
            dateString: '2021-08-17',
            time: '07:38:22',
            value: 200,
            sourceId: 'com.apple.health.4846429F-5BCE-42A8-9B59-AAA23BC9F1D7',
            id: '526DAD25-3CC5-427B-B3F5-36678941EC98',
            sourceName: 'Jason’s Apple Watch',
            startDate: '2021-08-17T12:38:22.511-0700',
            endDate: '2021-08-17T12:38:22.511-0700',
            timestamp: 1629229102511,
          },
          {
            dateString: '2021-08-17',
            time: '07:38:24',
            value: 200,
            sourceId: 'com.apple.health.4846429F-5BCE-42A8-9B59-AAA23BC9F1D7',
            id: 'C51F3F49-0B4F-4F13-A28D-9E3CAFE2CDE5',
            sourceName: 'Jason’s Apple Watch',
            startDate: '2021-08-17T12:38:24.511-0700',
            endDate: '2021-08-17T12:38:24.511-0700',
            timestamp: 1629229104511,
          },
          {
            dateString: '2021-08-17',
            time: '07:38:30',
            value: 203,
            sourceId: 'com.apple.health.4846429F-5BCE-42A8-9B59-AAA23BC9F1D7',
            id: 'C7171089-DC89-4BD1-9A39-5DCEBD49F628',
            sourceName: 'Jason’s Apple Watch',
            startDate: '2021-08-17T12:38:30.511-0700',
            endDate: '2021-08-17T12:38:30.511-0700',
            timestamp: 1629229110511,
          },
          {
            dateString: '2021-08-17',
            time: '07:38:35',
            value: 204,
            sourceId: 'com.apple.health.4846429F-5BCE-42A8-9B59-AAA23BC9F1D7',
            id: 'CD887811-E458-4E38-A4B1-D6BD61B38329',
            sourceName: 'Jason’s Apple Watch',
            startDate: '2021-08-17T12:38:35.511-0700',
            endDate: '2021-08-17T12:38:35.511-0700',
            timestamp: 1629229115511,
          },
          {
            dateString: '2021-08-17',
            time: '07:38:41',
            value: 204,
            sourceId: 'com.apple.health.4846429F-5BCE-42A8-9B59-AAA23BC9F1D7',
            id: '7EC74A28-7644-48D4-B770-D6435ABF67D0',
            sourceName: 'Jason’s Apple Watch',
            startDate: '2021-08-17T12:38:41.511-0700',
            endDate: '2021-08-17T12:38:41.511-0700',
            timestamp: 1629229121511,
          },
          {
            dateString: '2021-08-17',
            time: '07:38:52',
            value: 197,
            sourceId: 'com.apple.health.4846429F-5BCE-42A8-9B59-AAA23BC9F1D7',
            id: '29E28FFE-17FF-4D41-9D98-5526533C9D9A',
            sourceName: 'Jason’s Apple Watch',
            startDate: '2021-08-17T12:38:52.511-0700',
            endDate: '2021-08-17T12:38:52.511-0700',
            timestamp: 1629229132511,
          },
          {
            dateString: '2021-08-17',
            time: '07:38:56',
            value: 198,
            sourceId: 'com.apple.health.4846429F-5BCE-42A8-9B59-AAA23BC9F1D7',
            id: 'B99F149E-992A-418F-B8FA-48131F629228',
            sourceName: 'Jason’s Apple Watch',
            startDate: '2021-08-17T12:38:56.511-0700',
            endDate: '2021-08-17T12:38:56.511-0700',
            timestamp: 1629229136511,
          },
          {
            dateString: '2021-08-17',
            time: '07:39:03',
            value: 170,
            sourceId: 'com.apple.health.4846429F-5BCE-42A8-9B59-AAA23BC9F1D7',
            id: '0EAFAB70-5FA5-47BF-A986-073D55B04909',
            sourceName: 'Jason’s Apple Watch',
            startDate: '2021-08-17T12:39:03.511-0700',
            endDate: '2021-08-17T12:39:03.511-0700',
            timestamp: 1629229143511,
          },
          {
            dateString: '2021-08-17',
            time: '07:39:07',
            value: 170,
            sourceId: 'com.apple.health.4846429F-5BCE-42A8-9B59-AAA23BC9F1D7',
            id: 'D40C94C4-C29E-40D9-9215-5524D762B20B',
            sourceName: 'Jason’s Apple Watch',
            startDate: '2021-08-17T12:39:07.511-0700',
            endDate: '2021-08-17T12:39:07.511-0700',
            timestamp: 1629229147511,
          },
          {
            dateString: '2021-08-17',
            time: '07:39:12',
            value: 169,
            sourceId: 'com.apple.health.4846429F-5BCE-42A8-9B59-AAA23BC9F1D7',
            id: '84F8B932-E31B-4D38-91E9-A5161C481E15',
            sourceName: 'Jason’s Apple Watch',
            startDate: '2021-08-17T12:39:12.511-0700',
            endDate: '2021-08-17T12:39:12.511-0700',
            timestamp: 1629229152511,
          },
          {
            dateString: '2021-08-17',
            time: '07:39:14',
            value: 168,
            sourceId: 'com.apple.health.4846429F-5BCE-42A8-9B59-AAA23BC9F1D7',
            id: '86737941-8000-42F0-80D9-8230A9777883',
            sourceName: 'Jason’s Apple Watch',
            startDate: '2021-08-17T12:39:14.511-0700',
            endDate: '2021-08-17T12:39:14.511-0700',
            timestamp: 1629229154511,
          },
          {
            dateString: '2021-08-17',
            time: '07:39:19',
            value: 168,
            sourceId: 'com.apple.health.4846429F-5BCE-42A8-9B59-AAA23BC9F1D7',
            id: '58644B24-B87C-4A72-B076-6CDDB20D19DF',
            sourceName: 'Jason’s Apple Watch',
            startDate: '2021-08-17T12:39:19.511-0700',
            endDate: '2021-08-17T12:39:19.511-0700',
            timestamp: 1629229159511,
          },
          {
            dateString: '2021-08-17',
            time: '07:39:28',
            value: 167,
            sourceId: 'com.apple.health.4846429F-5BCE-42A8-9B59-AAA23BC9F1D7',
            id: '31EB883A-DE34-46E9-8994-661B88F4D68C',
            sourceName: 'Jason’s Apple Watch',
            startDate: '2021-08-17T12:39:28.511-0700',
            endDate: '2021-08-17T12:39:28.511-0700',
            timestamp: 1629229168511,
          },
          {
            dateString: '2021-08-17',
            time: '07:39:32',
            value: 165,
            sourceId: 'com.apple.health.4846429F-5BCE-42A8-9B59-AAA23BC9F1D7',
            id: 'E50F3BD6-646E-4C33-BA4D-B6E34AEE047D',
            sourceName: 'Jason’s Apple Watch',
            startDate: '2021-08-17T12:39:32.511-0700',
            endDate: '2021-08-17T12:39:32.511-0700',
            timestamp: 1629229172511,
          },
          {
            dateString: '2021-08-17',
            time: '07:39:36',
            value: 164,
            sourceId: 'com.apple.health.4846429F-5BCE-42A8-9B59-AAA23BC9F1D7',
            id: '77ED705B-BC17-461D-8DDF-453FBB19C522',
            sourceName: 'Jason’s Apple Watch',
            startDate: '2021-08-17T12:39:36.511-0700',
            endDate: '2021-08-17T12:39:36.511-0700',
            timestamp: 1629229176511,
          },
          {
            dateString: '2021-08-17',
            time: '07:39:39',
            value: 163,
            sourceId: 'com.apple.health.4846429F-5BCE-42A8-9B59-AAA23BC9F1D7',
            id: 'CEA7347F-5702-4214-9FD1-EAE83BA14896',
            sourceName: 'Jason’s Apple Watch',
            startDate: '2021-08-17T12:39:39.511-0700',
            endDate: '2021-08-17T12:39:39.511-0700',
            timestamp: 1629229179511,
          },
          {
            dateString: '2021-08-17',
            time: '07:39:45',
            value: 163,
            sourceId: 'com.apple.health.4846429F-5BCE-42A8-9B59-AAA23BC9F1D7',
            id: '6E46605B-E71E-4B76-ABEE-A4B61D4C31A6',
            sourceName: 'Jason’s Apple Watch',
            startDate: '2021-08-17T12:39:45.511-0700',
            endDate: '2021-08-17T12:39:45.511-0700',
            timestamp: 1629229185511,
          },
          {
            dateString: '2021-08-17',
            time: '07:39:49',
            value: 162,
            sourceId: 'com.apple.health.4846429F-5BCE-42A8-9B59-AAA23BC9F1D7',
            id: 'C550B10A-2138-45E2-B854-52ABC523D0EF',
            sourceName: 'Jason’s Apple Watch',
            startDate: '2021-08-17T12:39:49.511-0700',
            endDate: '2021-08-17T12:39:49.511-0700',
            timestamp: 1629229189511,
          },
          {
            dateString: '2021-08-17',
            time: '07:39:58',
            value: 159,
            sourceId: 'com.apple.health.4846429F-5BCE-42A8-9B59-AAA23BC9F1D7',
            id: '7A002612-8392-419D-BD8F-294745F4744B',
            sourceName: 'Jason’s Apple Watch',
            startDate: '2021-08-17T12:39:58.511-0700',
            endDate: '2021-08-17T12:39:58.511-0700',
            timestamp: 1629229198511,
          },
          {
            dateString: '2021-08-17',
            time: '07:39:59',
            value: 159,
            sourceId: 'com.apple.health.4846429F-5BCE-42A8-9B59-AAA23BC9F1D7',
            id: '63D1F08B-7159-4D92-BABF-7736C79B861F',
            sourceName: 'Jason’s Apple Watch',
            startDate: '2021-08-17T12:39:59.511-0700',
            endDate: '2021-08-17T12:39:59.511-0700',
            timestamp: 1629229199511,
          },
          {
            dateString: '2021-08-17',
            time: '07:40:08',
            value: 152,
            sourceId: 'com.apple.health.4846429F-5BCE-42A8-9B59-AAA23BC9F1D7',
            id: '0B69127B-3F76-4880-84E1-666590C20646',
            sourceName: 'Jason’s Apple Watch',
            startDate: '2021-08-17T12:40:08.511-0700',
            endDate: '2021-08-17T12:40:08.511-0700',
            timestamp: 1629229208511,
          },
          {
            dateString: '2021-08-17',
            time: '07:40:13',
            value: 150,
            sourceId: 'com.apple.health.4846429F-5BCE-42A8-9B59-AAA23BC9F1D7',
            id: 'B3667335-4678-4A95-93B1-6BECE1EE530C',
            sourceName: 'Jason’s Apple Watch',
            startDate: '2021-08-17T12:40:13.511-0700',
            endDate: '2021-08-17T12:40:13.511-0700',
            timestamp: 1629229213511,
          },
          {
            dateString: '2021-08-17',
            time: '07:40:16',
            value: 151,
            sourceId: 'com.apple.health.4846429F-5BCE-42A8-9B59-AAA23BC9F1D7',
            id: 'F56E3B93-1E93-45EB-A7DA-7F148BDE0604',
            sourceName: 'Jason’s Apple Watch',
            startDate: '2021-08-17T12:40:16.511-0700',
            endDate: '2021-08-17T12:40:16.511-0700',
            timestamp: 1629229216511,
          },
          {
            dateString: '2021-08-17',
            time: '07:40:19',
            value: 149,
            sourceId: 'com.apple.health.4846429F-5BCE-42A8-9B59-AAA23BC9F1D7',
            id: '6197F0FE-CC3A-4BA8-A6E6-AC2D3FC04914',
            sourceName: 'Jason’s Apple Watch',
            startDate: '2021-08-17T12:40:19.511-0700',
            endDate: '2021-08-17T12:40:19.511-0700',
            timestamp: 1629229219511,
          },
          {
            dateString: '2021-08-17',
            time: '07:40:28',
            value: 154,
            sourceId: 'com.apple.health.4846429F-5BCE-42A8-9B59-AAA23BC9F1D7',
            id: 'C9A2650B-EB7B-4FC4-873F-B94EA9E50D1E',
            sourceName: 'Jason’s Apple Watch',
            startDate: '2021-08-17T12:40:28.511-0700',
            endDate: '2021-08-17T12:40:28.511-0700',
            timestamp: 1629229228511,
          },
          {
            dateString: '2021-08-17',
            time: '07:40:33',
            value: 154,
            sourceId: 'com.apple.health.4846429F-5BCE-42A8-9B59-AAA23BC9F1D7',
            id: 'A86C4E30-B07D-4BE8-8350-721060F233B6',
            sourceName: 'Jason’s Apple Watch',
            startDate: '2021-08-17T12:40:33.511-0700',
            endDate: '2021-08-17T12:40:33.511-0700',
            timestamp: 1629229233511,
          },
          {
            dateString: '2021-08-17',
            time: '07:40:34',
            value: 153,
            sourceId: 'com.apple.health.4846429F-5BCE-42A8-9B59-AAA23BC9F1D7',
            id: 'F0674E8D-FB3F-4783-9BF7-ECEB74D47E3C',
            sourceName: 'Jason’s Apple Watch',
            startDate: '2021-08-17T12:40:34.511-0700',
            endDate: '2021-08-17T12:40:34.511-0700',
            timestamp: 1629229234511,
          },
          {
            dateString: '2021-08-17',
            time: '07:40:41',
            value: 149,
            sourceId: 'com.apple.health.4846429F-5BCE-42A8-9B59-AAA23BC9F1D7',
            id: '5FF6ECE6-5E12-4FD6-B461-C67B0EF3555A',
            sourceName: 'Jason’s Apple Watch',
            startDate: '2021-08-17T12:40:41.511-0700',
            endDate: '2021-08-17T12:40:41.511-0700',
            timestamp: 1629229241511,
          },
          {
            dateString: '2021-08-17',
            time: '07:40:46',
            value: 150,
            sourceId: 'com.apple.health.4846429F-5BCE-42A8-9B59-AAA23BC9F1D7',
            id: 'FADB658E-7E9C-4B7D-A459-59927AA1781B',
            sourceName: 'Jason’s Apple Watch',
            startDate: '2021-08-17T12:40:46.511-0700',
            endDate: '2021-08-17T12:40:46.511-0700',
            timestamp: 1629229246511,
          },
          {
            dateString: '2021-08-17',
            time: '07:40:49',
            value: 148,
            sourceId: 'com.apple.health.4846429F-5BCE-42A8-9B59-AAA23BC9F1D7',
            id: 'EA1D3EFB-B340-4EC4-BD61-9B184C95C877',
            sourceName: 'Jason’s Apple Watch',
            startDate: '2021-08-17T12:40:49.511-0700',
            endDate: '2021-08-17T12:40:49.511-0700',
            timestamp: 1629229249511,
          },
          {
            dateString: '2021-08-17',
            time: '07:40:54',
            value: 149,
            sourceId: 'com.apple.health.4846429F-5BCE-42A8-9B59-AAA23BC9F1D7',
            id: '7C9AFD03-96E7-470E-8337-3A145A2F09A5',
            sourceName: 'Jason’s Apple Watch',
            startDate: '2021-08-17T12:40:54.511-0700',
            endDate: '2021-08-17T12:40:54.511-0700',
            timestamp: 1629229254511,
          },
          {
            dateString: '2021-08-17',
            time: '07:41:03',
            value: 152,
            sourceId: 'com.apple.health.4846429F-5BCE-42A8-9B59-AAA23BC9F1D7',
            id: 'AAD48C50-988C-4BFA-BA01-C63E5CD2C7C9',
            sourceName: 'Jason’s Apple Watch',
            startDate: '2021-08-17T12:41:03.511-0700',
            endDate: '2021-08-17T12:41:03.511-0700',
            timestamp: 1629229263511,
          },
          {
            dateString: '2021-08-17',
            time: '07:41:08',
            value: 150,
            sourceId: 'com.apple.health.4846429F-5BCE-42A8-9B59-AAA23BC9F1D7',
            id: '55DF49E4-F21B-48B3-A795-3D9B3684E20E',
            sourceName: 'Jason’s Apple Watch',
            startDate: '2021-08-17T12:41:08.511-0700',
            endDate: '2021-08-17T12:41:08.511-0700',
            timestamp: 1629229268511,
          },
          {
            dateString: '2021-08-17',
            time: '07:41:13',
            value: 148,
            sourceId: 'com.apple.health.4846429F-5BCE-42A8-9B59-AAA23BC9F1D7',
            id: 'CCD1AEBD-86B2-4B61-BFDB-647AE732E1C5',
            sourceName: 'Jason’s Apple Watch',
            startDate: '2021-08-17T12:41:13.511-0700',
            endDate: '2021-08-17T12:41:13.511-0700',
            timestamp: 1629229273511,
          },
          {
            dateString: '2021-08-17',
            time: '07:41:14',
            value: 147,
            sourceId: 'com.apple.health.4846429F-5BCE-42A8-9B59-AAA23BC9F1D7',
            id: 'DD3540A6-FCCB-475C-8B58-5DF0F4520508',
            sourceName: 'Jason’s Apple Watch',
            startDate: '2021-08-17T12:41:14.511-0700',
            endDate: '2021-08-17T12:41:14.511-0700',
            timestamp: 1629229274511,
          },
          {
            dateString: '2021-08-17',
            time: '07:41:20',
            value: 146,
            sourceId: 'com.apple.health.4846429F-5BCE-42A8-9B59-AAA23BC9F1D7',
            id: '3026359A-5C9E-4741-B4DE-5082FB356173',
            sourceName: 'Jason’s Apple Watch',
            startDate: '2021-08-17T12:41:20.511-0700',
            endDate: '2021-08-17T12:41:20.511-0700',
            timestamp: 1629229280511,
          },
          {
            dateString: '2021-08-17',
            time: '07:41:27',
            value: 147,
            sourceId: 'com.apple.health.4846429F-5BCE-42A8-9B59-AAA23BC9F1D7',
            id: '55EC7176-D32E-48B8-B111-F14BA0F6AD59',
            sourceName: 'Jason’s Apple Watch',
            startDate: '2021-08-17T12:41:27.511-0700',
            endDate: '2021-08-17T12:41:27.511-0700',
            timestamp: 1629229287511,
          },
          {
            dateString: '2021-08-17',
            time: '07:41:29',
            value: 148,
            sourceId: 'com.apple.health.4846429F-5BCE-42A8-9B59-AAA23BC9F1D7',
            id: 'AB8BA5EA-2FBA-478D-A1EA-1668DBD6DB38',
            sourceName: 'Jason’s Apple Watch',
            startDate: '2021-08-17T12:41:29.511-0700',
            endDate: '2021-08-17T12:41:29.511-0700',
            timestamp: 1629229289511,
          },
          {
            dateString: '2021-08-17',
            time: '07:41:34',
            value: 149,
            sourceId: 'com.apple.health.4846429F-5BCE-42A8-9B59-AAA23BC9F1D7',
            id: '71C09756-AC7B-4BD5-9C06-B98FD6DF0081',
            sourceName: 'Jason’s Apple Watch',
            startDate: '2021-08-17T12:41:34.511-0700',
            endDate: '2021-08-17T12:41:34.511-0700',
            timestamp: 1629229294511,
          },
          {
            dateString: '2021-08-17',
            time: '07:41:43',
            value: 151,
            sourceId: 'com.apple.health.4846429F-5BCE-42A8-9B59-AAA23BC9F1D7',
            id: '7AD5BB3E-D079-4D27-A30E-876F08A72825',
            sourceName: 'Jason’s Apple Watch',
            startDate: '2021-08-17T12:41:43.511-0700',
            endDate: '2021-08-17T12:41:43.511-0700',
            timestamp: 1629229303511,
          },
          {
            dateString: '2021-08-17',
            time: '07:41:48',
            value: 150,
            sourceId: 'com.apple.health.4846429F-5BCE-42A8-9B59-AAA23BC9F1D7',
            id: '5907CD86-F01E-4D40-939B-31203D7B9C3A',
            sourceName: 'Jason’s Apple Watch',
            startDate: '2021-08-17T12:41:48.511-0700',
            endDate: '2021-08-17T12:41:48.511-0700',
            timestamp: 1629229308511,
          },
          {
            dateString: '2021-08-17',
            time: '07:41:53',
            value: 149,
            sourceId: 'com.apple.health.4846429F-5BCE-42A8-9B59-AAA23BC9F1D7',
            id: '6CCFE64C-73DE-4BF5-A7EB-A54DE5C63B99',
            sourceName: 'Jason’s Apple Watch',
            startDate: '2021-08-17T12:41:53.511-0700',
            endDate: '2021-08-17T12:41:53.511-0700',
            timestamp: 1629229313511,
          },
          {
            dateString: '2021-08-17',
            time: '07:41:54',
            value: 150,
            sourceId: 'com.apple.health.4846429F-5BCE-42A8-9B59-AAA23BC9F1D7',
            id: 'AD170927-8B42-4DD0-8A84-319D41FC0749',
            sourceName: 'Jason’s Apple Watch',
            startDate: '2021-08-17T12:41:54.511-0700',
            endDate: '2021-08-17T12:41:54.511-0700',
            timestamp: 1629229314511,
          },
          {
            dateString: '2021-08-17',
            time: '07:42:00',
            value: 147,
            sourceId: 'com.apple.health.4846429F-5BCE-42A8-9B59-AAA23BC9F1D7',
            id: 'EC6C37F6-6D7B-4409-9431-71053CF71D98',
            sourceName: 'Jason’s Apple Watch',
            startDate: '2021-08-17T12:42:00.511-0700',
            endDate: '2021-08-17T12:42:00.511-0700',
            timestamp: 1629229320511,
          },
          {
            dateString: '2021-08-17',
            time: '07:42:05',
            value: 147,
            sourceId: 'com.apple.health.4846429F-5BCE-42A8-9B59-AAA23BC9F1D7',
            id: '49FA9870-4992-49C6-8A1C-7A1C3E793CBF',
            sourceName: 'Jason’s Apple Watch',
            startDate: '2021-08-17T12:42:05.511-0700',
            endDate: '2021-08-17T12:42:05.511-0700',
            timestamp: 1629229325511,
          },
        ],
      },
    },
  ],
}
